export * from './restClient.js';
export * from './apiParam.js';
export * from './setting.js';

import apiParam from './apiParam.js';
import restClient from './restClient.js';
import setting from './setting.js';

export {
  restClient,
  apiParam,
  setting,
}
export default {
  ...restClient,
  apiParam,
  setting,
}
