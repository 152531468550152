<template>
  <v-dialog v-model="show" fullscreen>

    <!-- type が linear の場合は線表示 -->
    <template v-if="type === 'linear'">
      <v-progress-linear
        :color="color" :height="scale[textSize].height" indeterminate
        style="margin: 0px;">
      </v-progress-linear>
      <v-container
        fluid class="fill-height" :style="{'background-color': backgroundColor}"
        style="padding: 0px; position: absolute;">
      </v-container>
    </template>

    <!-- type が linear-center の場合は中央の線で表示 -->
    <template v-if="type === 'linear-center'">
      <v-container fluid class="fill-height" :style="{'background-color': backgroundColor}">
        <v-row justify="center" align="center">
          <v-card dark color="indigo-darken-1" style="width: 300px; padding: 10px 10px 10px 10px;" elevation="10">
            <div class="text-xs-center">{{ message }}</div>
            <v-progress-linear :color="color" :height="scale[textSize].height" indeterminate></v-progress-linear>
          </v-card>
        </v-row>
      </v-container>
    </template>

    <!-- type が circular の場合はスピナー表示 -->
    <template v-if="type === 'circular'">
      <v-container fluid class="fill-height" :style="{'background-color': backgroundColor}">
        <v-row justify="center" align="center">
          <v-progress-circular
            indeterminate :color="color" :size="scale[textSize].size"
            :width="scale[textSize].width">
          </v-progress-circular>
        </v-row>
      </v-container>
    </template>

  </v-dialog>
</template>

<script>

/**
 * 処理中ダイアログ
 *   [プロパティ]
 *     value:            trueの場合はダイアログ表示、falseの場合は非表示。
 *     type:             linear/linear-center/circularのいずれかを指定。
 *     background-color: 背景色。
 *     color:            線またはスピナの色。
 *     dialog-extend:    Dialogインスタンスにalertメソッドを追加する。利用方法の説明を参照。
 *     size:             線またはスピナの大きさ。（1～10）
 *     message:          表示メッセージ。typeが「linear-center」の場合に有効。
 *
 *   [メソッド]
 *     showDialog(isVisible)
 *       isVisible:   trueの場合は表示、falseの場合は非表示。
 *
 *   [利用方法]
 *      以下の2つの利用方法をサポートしています。
 *         1. 普通のコンポーネントとして
 *            <template>
 *              <loading-dialog ref="refLoading" v-model="isShow"></loading-dialog>
 *              <button @click="onclick">ダイアログ表示<button>
 *            </template>
 *            <script>
 *              export default {
 *                data () {
 *                  return {isShow: false};
 *                },
 *                methods: {
 *                  onclick () {
 *                    const that = this;
 *                    this.isShow = true;
 *                    setTimeout(() => self.isShow = false, 5000);
 *                    // this.$refs.refLoading.showDialog(true);
 *                    // setTimeout(() => self.$refs.refLoading.showDialog(false), 5000);
 *                  }
 *                }
 *              }
 *            ＜/script>
 *         2. Dialog拡張用として
 *           [親画面]
 *             <template>
 *               <loading-dialog dialog-extend></loading-dialog>
 *               <router-view></router-view>
 *             </template>
 *           [子画面]
 *             <script>
 *               import dialog from '@/scripts/dialog.js'
 *               export default {
 *                 methods: {
 *                   onclick () {
 *                     // 任意のソースからダイアログを呼び出せる
 *                     dialog.loading(true)
 *                   }
 *                 }
 *               }
 *             ＜/script>
 *
 */
import {dialogs} from '@/scripts/utils/dialog.js';

export default {
  // eslint-disable-next-line max-lines-per-function
  data() {
    return {
      "show": false,
      "scale": {
        '1': {
          "height": 1,
          "size": 30,
          "width": 5
        },
        '2': {
          "height": 4,
          "size": 60,
          "width": 10
        },
        '3': {
          "height": 7,
          "size": 90,
          "width": 15
        },
        '4': {
          "height": 10,
          "size": 120,
          "width": 20
        },
        '5': {
          "height": 13,
          "size": 150,
          "width": 25
        },
        '6': {
          "height": 16,
          "size": 180,
          "width": 30
        },
        '7': {
          "height": 19,
          "size": 210,
          "width": 35
        },
        '8': {
          "height": 22,
          "size": 240,
          "width": 40
        },
        '9': {
          "height": 25,
          "size": 270,
          "width": 45
        },
        '10': {
          "height": 28,
          "size": 300,
          "width": 50
        },
      }
    }
  },
  "props": {
    "value": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "type": {
      "type": String,
      "required": false,
      "default": () => 'circular'
    },
    'background-color': {
      "type": String,
      "required": false,
      "default": () => 'rgba(150, 150, 150, 0.5)'
    },
    "color": {
      "type": String,
      "required": false,
      "default": () => 'primary'
    },
    "size": {
      "type": [Number, Boolean],
      "required": false,
      "default": () => 5
    },
    "message": {
      "type": String,
      "required": false,
      "default": () => 'お待ちください'
    },
  },
  "computed": {
    textSize() {
      return String(this.size);
    }
  },
  "watch": {
    "value"(newVal) {
      this.show = newVal;
    },
  },
  "methods": {
    // utilsから呼び出すときの関数名
    getFuncName () {
      return 'loading';
    },
    open(isVisible) {
      this.show = isVisible;
    }
  }
}
</script>
