<template>
  <v-list
    nav
    density="compact"
    mandatory
    :selected="activeItem"
    :width="width"
    :shaped="shaped"
    :rounded="rounded"
    :color="color">
    
    <!-- 選択可能な項目を1項目とする -->
      <template v-for="(item, index) in items">

        <!-- item.type === 'category' の場合はカテゴリのテキストを表示 -->
        <template v-if="item.type === 'category'">
          <v-list-subheader :class="item.class || ''" :key="index" :value="item" :color="itemColor">
            <v-icon v-if="item.icon" :icon="item.icon" />{{ item.name }}
          </v-list-subheader>
        </template>
        
        <!-- item.type === 'singleItem' の場合は1階層のみ表示 -->
        <template v-else-if="item.type === 'singleItem'">
          <v-list-item @click="moveTo(item.link)" ripple :class="item.class || ''" :key="index" :value="item" :color="itemColor">
            <v-icon v-if="item.icon" :icon="item.icon" class="mr-4" style="vertical-align: bottom;"/>{{ item.name }}
          </v-list-item>
        </template>

        <!-- item.type === 'list' の場合は階層構造を表示 -->
        <template v-else-if="item.type === 'list'">
          <v-list-group :append-icon="item.expandIcon ? 'keyboard_arrow_down' : ''" :ref="item.name" @click="onClickGroup(item.name)" :key="index">
            <template v-slot:activator>
              <v-list-item-title>
                <v-icon v-if="item.icon" :icon="item.icon" class="pr-2" />{{ item.name }}
              </v-list-item-title>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" :value="child" @click="moveTo(child.link)" ripple :prepend-icon="child.icon" :title="item.text">
            </v-list-item>
          </v-list-group>
        </template>

        <!-- item.type === 'divider' の場合は分割線を表示 -->
        <template v-else-if="item.type === 'divider'">
          <v-divider :key="index" />
        </template>

      </template>
    
  </v-list>
</template>

<script>
  /**
   * リスト形式のナビゲーションメニュー。
   * 
   * [props]
   *
   *   items: 
   *     メニュー一覧のオブジェクト。以下のような形式で指定する。
   *       items: [
   *         {type: 'category',   name: 'ダミーカテゴリ1', icon: null, class: 'red--text'},
   *         {type: 'singleItem', name: 'ダミー項目1',     icon: '3d_rotation',     link: '/frame1/display/text', class: null},
   *         {type: 'singleItem', name: 'ダミー項目2',     icon: 'accessibility',   link: '/frame1/display/select', class: null},
   *         {type: 'divider'},
   *         {type: 'category',   name: 'ダミーカテゴリ2', icon: null, class: null},
   *         {type: 'list',       name: 'ダミーリスト1',   icon: null, expandIcon: false, class: null, children: [
   *             {name: 'ダミー項目1-1', icon: 'account_box', link: '/frame1/display/file', class: null},
   *             {name: 'ダミー項目1-2', icon: 'alarm',       link: '/frame1/display/table', class: null},
   *         ]}
   *       ]
   *     ※ type=category：ラベルを表示。
   *     ※ type=singleItem：別ページへのリンク項目
   *     ※ type=list：アコーディオン形式のリンク項目。
   *     ※ icon：material icon、font awesome iconの名前を指定可能。
   * 
   *   color:
   *     リスト領域の色を指定する。
   *
   *   item-color:
   *     選択項目の色を指定する。
   *
   *   shaped:
   *     選択中の表示の右側の角を丸くする
   *
   *   rounded:
   *     選択中の表示の両側の角を丸くする
   *
   * [methods]
   *
   *   expand(name):
   *      type=listで指定したグループを開いた状態にする。
   *
   */
  import vuexUtils from '@/scripts/utils/vuexUtils.js';
  export default {
    data () {
      return {
        expandGroupName: null,
      }
    },
    props: {
      items:      {type: Array,   required: true,  default: () => []},
      color:      {type: String,  required: false, default: () => undefined},
      itemColor:  {type: String,  required: false, default: () => "blue-darken-2"},
      shaped:     {type: Boolean, required: false, default: () => false},
      rounded:    {type: Boolean, required: false, default: () => false},
      width:      {type: String,  required: false, default: () => undefined},
    },
    computed: {
      activeItem: {
        get() {
          const path = this.$route.path.split('/').slice(0, 4).join('/');
          return this.items.filter(item => item.link.startsWith(path));
        },
        set(value) {
        }
      }
    },
    methods: {
      onClickGroup (name) {
        if (this.expandGroupName === name) {
          this.expandGroupName = null;
        } else {
          this.expandGroupName = name;
        }
      },
      expand (name) {
        if (this.expandGroupName === name) {
          return;
        }
        if (this.$refs[name]) {
          const ref = Array.isArray(this.$refs[name]) ? this.$refs[name][0] : this.$refs[name];
          ref.click();
        }
      },
      moveTo (path) {
        if (path) {
          vuexUtils.moveTo(path);
        }
      },
    },
    mounted () {
      window.x = this;
    }
  }
</script>
