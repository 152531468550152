/**
 * 型を文字列で取得する。
 * 例
 *   getType(1)               // 'Number'
 *   getType([])              // 'Array'      <---- typeof [] だと'object'になる
 *   getType(new Date())      // 'Date'       <---- typeof new Date() だと'object'になる
 *   getType(new HogeClass()) // 'HogeClass'  <---- typeof new HogeClass() だと'object'になる
 */
const getType = something => {
    const type = Object.prototype.toString.call(something).match(/^\[object (.*)\]$/);
    if (type.length !== 2) {
        throw new TypeError(`不明な型が指定されました。${Object.prototype.toString.call(something)}`)
    }
    const typeName = type[1];
    if (typeName === 'Object' && something.constructor) {
        return something.constructor.name;
    }
    return typeName;
}

/**
 * Objectでswitch構文の代わりを記述する
 * 例
 * const key = 'abc';
 * objSwitch(key, {
 *   key1 () {
 *     console.log('key1');
 *   },
 *   key2 () {
 *     console.log('key2');
 *   },
 *   default () {
 *     console.log('default');
 *   }
 * });
 */
const objSwitch = (key, obj) => {
    if (getType(key) !== 'String') {
        key = String(key);
    }
    if (Object.keys(obj).includes(key)) {
        return obj[key]();
    }
    if (Object.keys(obj).includes('default')) {
        throw Error(`objSwitch で default が指定されていないため ${key} の処理が実施できませんでした。`);
    }
    return obj.default();
};

/**
 * オブジェクトから指定キーを持つ別のオブジェクトを作成する
 * 例
 *   filterKey({a: 1, b: 2, c: 3, d: 4}, 'b', 'd'); // {b: 2, d: 4}
 */
const filterKey = (obj, ...keys) => {
    const entries = Object.entries(obj)
        .filter(([key]) => keys.includes(key));
    return Object.fromEntries(entries);
}

/**
 * オブジェクトから指定キーを除外したのオブジェクトを作成する
 * 例
 *   excludeKey({a: 1, b: 2, c: 3, d: 4}, 'b', 'd'); // {a: 2, c: 4}
 */
const excludeKey = (obj, ...keys) => {
    const entries = Object.entries(obj)
        .filter(([key]) => !keys.includes(key));
    return Object.fromEntries(entries);
}

/**
 * 同一キーを持ち、すべて指定の値となるオブジェクトを作成して返す
 * 例
 *   objFromKeys(['a', 'b', 'c'], 'abc') // {a: 'abc', b: 'abc', c: 'abc'}
 */
const objFromKeys = (keys, value = null) => Object.fromEntries(keys.map(key => [
    key,
    value
]))

/**
 * 指定オブジェクトを変更不可にする
 * 例
 *   data () {
 *     return {
 *       item1: {a: 1, b: 2}              // 変更可能
 *       item2: immutable({a: 1, b: 2})   // 変更不可
 *     }
 *   }
 */
const immutable = (obj, retObj = null) => {

    const proxyHandler = {
        "set": (object, prop, value) => {
            throw new Error(`イミュータブルオブジェクトの変更操作を検出しました。obj=${JSON.stringify(object)}、obj[${prop}]=${value}`);
        }
    }

    if (retObj == null) {
        retObj = Array.isArray(obj) ? [] : {};
    }
    for (let [
        key,
        val
    ] of Object.entries(obj)) {
        if (Array.isArray(retObj)) {
            key *= 1;
        }
        let immutableVal = null;
        if (getType(val) === 'Object') {
            immutableVal = immutable(val, {});
        } else if (getType(val) === 'Array') {
            immutableVal = immutable(val, []);
        } else {
            immutableVal = val;
        }
        retObj[key] = immutableVal;
    }
    return new Proxy(retObj, proxyHandler);
}

export {
    getType,
    objSwitch,
    filterKey,
    excludeKey,
    objFromKeys,
    immutable,
}


export default {
    getType,
    objSwitch,
    filterKey,
    excludeKey,
    objFromKeys,
    immutable,
}
