/**
 * 環境依存する設定情報の読み込みはここで行う
 * ここ以外の場所で process.env.VUE_APP_XXX というソースが出てこないようにすること
 */


// プロファイルを取得
function getProfile() {
    return process.env.VUE_APP_PROFILE;
}

// 開発環境か否かを取得
function isDevelopEnv() {
    return getProfile() === 'development';
}

// ベースパスを取得
function getBasePath() {
    return process.env.VUE_APP_BASE_PATH;
}

// ホストを取得
function getHost() {
    return process.env.VUE_APP_FRONTEND_URL;
}

// アプリの配置パスを取得
function getPublicPath() {
    return process.env.VUE_APP_PUBLIC_PATH;
}

// REST APIのベースURLを取得
function getApiBaseUrl() {
    if (window.$$env$$ && window.$$env$$.apiBaseUrl) {
        return window.$$env$$.apiBaseUrl;
    }
    const baseUrl = process.env.VUE_APP_API_BASE_URL;
    if (process.env.VUE_APP_USE_STUB_PROXY === 'true') {
        return new URL(baseUrl).pathname;
    }
    return baseUrl;
}

// APIキーを取得
function getApiKey() {
    if (window.$$env$$ && window.$$env$$.apiKey) {
        return window.$$env$$.apiKey;
    }
    return process.env.VUE_APP_API_KEY;
}

// axiosのプロキシを取得
function getAxiosProxy() {
    const axiosProxy = process.env.VUE_APP_AXIOS_PROXY;
    if (!axiosProxy) {
        return null;
    }
    const url = new URL(axiosProxy);
    return {
        "host": `${url.hostname}`,
        "port": parseInt(url.port),
        "auth": {
            "username": `${url.username}`,
            "password": `${url.password}`,
        }
    };
}

// 利用会社(east/west)
function getUsingCompany() {
    return process.env.VUE_APP_USING_COMPANY;
}
// 東日本か否かを取得
function isEast() {
    return getUsingCompany() === 'east'
}
// 西日本か否かを取得
function isWest() {
    return getUsingCompany() === 'west'
}
// 会社ロゴ画像
function getCompanyLogoFile() {
    return process.env.VUE_APP_COMPANY_LOGO_FILE;
}
// コピーライト表記
function getCopyrightHtml() {
    return process.env.VUE_APP_COPYRIGHT_HTML;
}
// 会社名プレースホルダー
function getCompanyPlaceholder() {
    return process.env.VUE_APP_COMPANY_PLACEHOLDER;
}
// 会社名カナプレースホルダー
function getCompanyKanaPlaceholder() {
    return process.env.VUE_APP_COMPANY_KANA_PLACEHOLDER;
}
// 住所プレースホルダー
function getAddressPlaceholder() {
    return process.env.VUE_APP_ADDRESS_PLACEHOLDER;
}
// 会社名略称
function getAbbreviatedCompanyName() {
    return process.env.VUE_APP_ABBREVIATED_COMPANY_NAME;
}
// 116フリーダイヤル
function get116FreeDial() {
    return process.env.VUE_APP_116_FREEDIAL;
}
// 不安全設備連絡ページ
function getSafetyReportUrl() {
    return process.env.VUE_APP_SAFETY_REPORT_URL;
}
// 推奨環境ページ
function getRecommendedEnvUrl() {
    return process.env.VUE_APP_RECOMMENDED_ENV_URL;
}
// プライバシーポリシーページURL
function getPrivacyPolicyUrl() {
    return process.env.VUE_APP_PRIVACY_POLICY_URL;
}
// 他会社トップページURL
function getOtherCompanyTopUrl() {
    return process.env.VUE_APP_OTHER_COMPANY_TOP_URL;
}

export {
    getProfile,
    isDevelopEnv,
    getBasePath,
    getPublicPath,
    getApiBaseUrl,
    getAxiosProxy,
    getApiKey,
    getHost,
    getUsingCompany,
    isEast,
    isWest,
    getCompanyLogoFile,
    getCopyrightHtml,
    getCompanyPlaceholder,
    getCompanyKanaPlaceholder,
    getAddressPlaceholder,
    getAbbreviatedCompanyName,
    get116FreeDial,
    getSafetyReportUrl,
    getRecommendedEnvUrl,
    getPrivacyPolicyUrl,
    getOtherCompanyTopUrl,
}

export default {
    getProfile,
    isDevelopEnv,
    getBasePath,
    getPublicPath,
    getApiBaseUrl,
    getAxiosProxy,
    getApiKey,
    getHost,
    getUsingCompany,
    isEast,
    isWest,
    getCompanyLogoFile,
    getCopyrightHtml,
    getCompanyPlaceholder,
    getCompanyKanaPlaceholder,
    getAddressPlaceholder,
    getAbbreviatedCompanyName,
    get116FreeDial,
    getSafetyReportUrl,
    getRecommendedEnvUrl,
    getPrivacyPolicyUrl,
    getOtherCompanyTopUrl,
}
