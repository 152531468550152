import utils from '@/scripts/utils';

/**
 * 利用可能なプロパティ
 * v-checkbox        : append-icon、background-color、color、dark、dense、disabled、error、error-count、error-messages、false-value、hide-details、hint、id、indeterminate、indeterminate-icon、input-value、label、light、loading、messages、multiple、off-icon、on-icon、persistent-hint、prepend-icon、readonly、ripple、rules、success、success-messages、true-value、validate-on-blur、value、value-comparator
 * v-combobox        : allow-overflow、append-icon、append-outer-icon、attach、auto-select-first、autofocus、background-color、cache-items、chips、clear-icon、clearable、color、counter、dark、deletable-chips、delimiters、dense、disabled、eager、error、error-count、error-messages、filled、filter、flat、full-width、height、hide-details、hide-no-data、hide-selected、hint、id、item-color、item-disabled、item-text、item-value、items、label、light、loader-height、loading、menu-props、messages、multiple、no-data-text、no-filter、open-on-clear、outlined、persistent-hint、placeholder、prefix、prepend-icon、prepend-inner-icon、readonly、return-object、reverse、rounded、rules、search-input、shaped、single-line、small-chips、solo、solo-inverted、success、success-messages、suffix、type、validate-on-blur、value、value-comparator
 * v-file-input      : append-icon、append-outer-icon、autofocus、background-color、chips、clear-icon、clearable、color、counter、counter-size-string、counter-string、dark、dense、disabled、error、error-count、error-messages、filled、flat、full-width、height、hide-details、hint、id、label、light、loader-height、loading、messages、multiple、outlined、persistent-hint、placeholder、prefix、prepend-icon、prepend-inner-icon、reverse、rounded、rules、shaped、show-size、single-line、small-chips、solo、solo-inverted、success、success-messages、suffix、truncate-length、type、validate-on-blur、value、
 * v-select          : append-icon、append-outer-icon、attach、autofocus、background-color、cache-items、chips、clear-icon、clearable、color、counter、dark、deletable-chips、dense、disabled、eager、error、error-count、error-messages、filled、filter、flat、full-width、height、hide-details、hide-selected、hint、id、item-color、item-disabled、item-text、item-value、items、label、light、loader-height、loading、menu-props、messages、multiple、no-data-text、open-on-clear、outlined、persistent-hint、placeholder、prefix、prepend-icon、prepend-inner-icon、readonly、return-object、reverse、rounded、rules、shaped、single-line、small-chips、solo、solo-inverted、success、success-messages、suffix、type、validate-on-blur、value、value-comparator
 * v-textarea        : append-icon、append-outer-icon、auto-grow、autofocus、background-color、clear-icon、clearable、color、counter、dark、dense、disabled、error、error-count、error-messages、filled、flat、full-width、height、hide-details、hint、id、label、light、loader-height、loading、messages、no-resize、outlined、persistent-hint、placeholder、prefix、prepend-icon、prepend-inner-icon、readonly、reverse、rounded、row-height、rows、rules、shaped、single-line、solo、solo-inverted、success、success-messages、suffix、type、validate-on-blur、value
 * v-text-field      : append-icon、append-outer-icon、autofocus、background-color、clear-icon、clearable、color、counter、dark、dense、disabled、error、error-count、error-messages、filled、flat、full-width、height、hide-details、hint、id、label、light、loader-height、loading、messages、outlined、persistent-hint、placeholder、prefix、prepend-icon、prepend-inner-icon、readonly、reverse、rounded、rules、shaped、single-line、solo、solo-inverted、success、success-messages、suffix、type、validate-on-blur、value
 * date-picker-field : allowedDates、clearable、dense、disabled、filled、flat、fromIsoDate、hint、icon、label、max、min、multiple、outlined、persistentHint、placeholder、prefix、range、rounded、rules、shaped、singleLine、solo、soloInverted、suffix、toIsoDate、value
 * time-picker-field : allowedHours、allowedMinutes、allowedSeconds、clearable、dense、disabled、filled、flat、hint、icon、label、max、min、outlined、persistentHint、placeholder、prefix、rounded、rules、shaped、singleLine、solo、soloInverted、suffix、useSeconds、useSeconds、value
 * radio-field       : dense、disabled、hint、items、label、mandatory、persistentHint、readonly、row、rules、value
 */

/**
 * 引数のオブジェクトを直接書き換えてください
 *   props.attr1 = 'abc';                 // OK
 *   props = {...props, attr1: 'abc'};    // NG(引数のオブジェクト自体は変わっていない)
 *   Object.assign(props, {attr1: 'abc'}; // OK
 */

// 現在の値が設定されていない場合に指定値を返す
const defaultVal = (currentValue, defaultValue) => (currentValue === undefined ? defaultValue : currentValue);

// v-text-field に対する変換処理
const wrapTextField = (props) => {

    const isRequired = props.rules && props.rules.some(f => f.name === 'isNotEmpty' || f.name === 'bound isNotEmpty');

    props.disabled = props.disabled === '' ? true : props.disabled;
    props.readonly = props.readonly === '' ? true : props.readonly;

    // 基本設定
    props.label = undefined;
    props.counter = defaultVal(props.counter, false);
    props.persistentCounter = defaultVal(props.persistentCounter, false);
    props.class = utils.extendClass('px-2', props.class);

    if (props.disabled) {
        props.rounded = defaultVal(props.rounded, true);
        props.variant = defaultVal(props.variant, 'filled');
        props.clearable = defaultVal(props.clearable, false);
        props.hint = undefined;
        props.persistentHint = defaultVal(props.persistentHint, false);

    } else if (props.readonly) {
        props.variant = defaultVal(props.variant, 'underlined');
        props.placeholder = undefined;
        props.singleLine = defaultVal(props.singleLine, true);
        props.rounded = defaultVal(props.rounded, false);
        props.clearable = defaultVal(props.clearable, false);
        props.hint = undefined;
        props.persistentHint = defaultVal(props.persistentHint, false);

    } else {
        props.rounded = defaultVal(props.rounded, true);
        props.variant = defaultVal(props.variant, 'outlined');
        props.clearable = defaultVal(props.clearable, !isRequired);
        props.persistentHint = defaultVal(props.persistentHint, Boolean(props.hint));

    }

    props.rules = props.disabled || props.readonly ? [] : props.rules || [];

}

// v-file-input に対する変換処理
const wrapFileInput = (props) => {
    wrapTextField(props);
    props.counter = defaultVal(props.counter, false);
}

// v-checkbox に対する変換処理
const wrapCheckbox = (props) => {

}

// v-select に対する変換処理
const wrapSelect = (props) => {
    wrapTextField(props);
    props.counter = defaultVal(props.counter, false);
}

// radio に対する変換処理
const wrapRadio = (props) => {
    props.label = undefined;
    props.column = defaultVal(props.column, false);
    props.row = defaultVal(props.row, true);
}

// v-text-area に対する変換処理
const wrapTextArea = (props) => {
    if (!props.readonly) {
        props.rounded = defaultVal(props.rounded, 'xl');
    }
    wrapTextField(props);
}

// time-picker に対する変換処理
const wrapTimePicker = (props) => {
    wrapTextField(props);
    props.counter = defaultVal(props.counter, false);
    props.appendInnerIcon = defaultVal(props.appendInnerIcon, 'access_time');
    props.format = defaultVal(props.format, '24hr');
    props.scrollable = defaultVal(props.scrollable, true);
    props.useSeconds = defaultVal(props.useSeconds, true);
}

// date-picker に対する変換処理
const wrapDatePicker = (props) => {
    wrapTextField(props);
    props.counter = defaultVal(props.counter, false);
    props.appendInnerIcon = defaultVal(props.appendInnerIcon, props.readonly ? undefined : 'mdi-calendar');
    props.min = defaultVal(props.min, utils.formatDate(new Date(), 'uuuu-MM-dd'));
    props.max = defaultVal(props.max, utils.formatDate(utils.addYears(new Date(),  5), 'uuuu-MM-dd'));
}

// file-drop に対する変換処理
const wrapFileDrop = (props) => {
    props.rounded = defaultVal(props.rounded, true)
}

// v-btn に対する変換処理
const wrapButton = (props) => {

    if ([
'',
'true'
].includes(props.link)) {
        props.link = true;
    }
    if ([
'',
'true'
].includes(props.disabled)) {
        props.disabled = true;
    }
    const secondary = [
'',
'true'
].includes(props.secondary);
    delete props.secondary;

    if (props.link) {
        delete props.link;
        props.variant = defaultVal(props.variant, 'text');
        props.color = defaultVal(props.color, 'blue');
        props.height = 25;
        props.class = utils.extendClass('px-0 mw-0 pt-1 pb-1 font-size-inherit text-none', props.class);
        return;
    }

    props.rounded = true;
    props.variant = defaultVal(props.variant, secondary ? 'outlined': 'elevated');
    props.color = defaultVal(props.color, secondary ? 'normal' : 'primary');
    props.height = defaultVal(props.height, 50);
    props.style = `${defaultVal(props.style, '')};font-size: medium;${secondary ? 'color: #1976d2;' : ''}`;
}

export {
    wrapTextField,
    wrapFileInput,
    wrapCheckbox,
    wrapSelect,
    wrapRadio,
    wrapTextArea,
    wrapTimePicker,
    wrapDatePicker,
    wrapFileDrop,
    wrapButton,
};

export default {
    wrapTextField,
    wrapFileInput,
    wrapCheckbox,
    wrapSelect,
    wrapRadio,
    wrapTextArea,
    wrapTimePicker,
    wrapDatePicker,
    wrapFileDrop,
    wrapButton,
}
