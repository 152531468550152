<template>
  <v-menu v-model="show" ref="menu" open-on-hover absolute offset-overflow>
    <template v-slot:activator="{on}">
      <span v-on="convertOn(on)">
        <slot></slot>
      </span>
    </template>
    <div class="white">
      <v-img
          ref="img"
          contain
          :src="src"
          :width="imageWidth"
          :height="imageHeight"
      />
    </div>
  </v-menu>
</template>

<script>

/**
 * マウスオーバー時に画像を表示するコンポーネント
 * 以下のプロパティを持っている。
 *   value  : 画像表示中はtrue、それ以外はfalse
 *   src    : 画像のsrcプロパティ(必須)
 *   width  : 画像の横幅
 *   height : 画像の縦幅
 *
 * 使用例
 *   <popup-image :src="require('@/assets/name.png')">
 *     <a href="javascript:void(0)">ここ</a>
 *   </popup-image>
 */
export default {
  data() {
    return {
      "actualWidth": 200,
      "actualHeight": 200,
    }
  },
  "props": {
    "value": {
      "type": String,
      "required": false,
      "defalut": () => false
    },
    "src": {
      "type": String,
      "required": true
    },
    "width": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    "height": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
  },
  emits: ['update:modelValue'],
  "computed": {
    imageWidth() {
      return this.width || this.actualWidth;
    },
    imageHeight() {
      return this.height || this.actualHeight;
    },
    "show": {
      "get"() {
        return this.value;
      },
      "set"(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  mounted() {
    // 幅と高さが指定されていない場合は実際のイメージから取得する
    if (this.width && this.height) {
      return;
    }
    const that = this;
    const img = new Image();
    img.addEventListener("load", () => {
      that.actualWidth = img.width;
      that.actualHeight = img.height;
    });
    img.src = this.src;
  },
  "methods": {
    convertOn(arg) {
      const that = this;
      const orgFunc = arg.mouseenter;
      arg.mouseenter = element => {
        that.$refs.menu.absoluteX = element.x;
        that.$refs.menu.absoluteY = element.y - 10;
        orgFunc(element);
      }
      return arg;
    }
  }
}
</script>
