<template>
  <v-menu
      v-model="show"
      ref="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      :return-value.sync="time"
      transition="scale-transition"
      offset-x
      max-width="290px"
      min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
          v-model="time"
          :class="classList"
          readonly
          ref="textRef"
          :disabled="disabled"
          :clearable="clearable"
          :dense="dense"
          :filled="filled"
          :flat="flat"
          :hint="hint"
          :label="label"
          :outlined="outlined"
          :persistent-hint="persistentHint"
          :placeholder="placeholder"
          :prefix="prefix"
          :rounded="rounded"
          :shaped="shaped"
          :single-line="singleLine"
          :solo="solo"
          :solo-inverted="soloInverted"
          :suffix="suffix"
          :rules="rules"
          :append-inner-icon="appendInnerIcon"
          :prepend-inner-icon="prependInnerIcon"
          @click:append="show = true"
          @click:prepend-inner="show = true"
          v-on="on"
      >
      </v-text-field>
    </template>

    <v-time-picker
        v-model="time"
        :scrollable="scrollable"
        :format="format"
        :allowed-hours="allowedHours"
        :allowed-minutes="allowedMinutes"
        :allowed-seconds="allowedSeconds"
        :use-seconds="useSeconds"
        :max="max"
        :min="min"
        @click:minute="!useSeconds && $refs.menu.save(time)"
        @click:second="useSeconds && $refs.menu.save(time)"
    >
    </v-time-picker>

  </v-menu>
</template>

<script>

/**
 * 時刻選択フィールド。
 *   [プロパティ]
 *     modelValue:     選択された時刻。
 *     rules:          入力値のバリデーションチェックのルールを指定する。
 *     useSeconds:     秒まで表示する。
 *     allowedHours:   選択可能な時を指定する。配列の場合は[0,1,2]、関数の場合は h => (h > 5) のように指定。
 *     allowedMinutes: 選択可能な分を指定する。配列の場合は[0,1,2]、関数の場合は m => (m > 5) のように指定。
 *     allowedSeconds: 選択可能な秒を指定する。配列の場合は[0,1,2]、関数の場合は s => (s > 5) のように指定。
 *     max:            選択可能な時刻の上限をHH:mm、HH:mm:ss形式の文字列で設定する。
 *     min:            選択可能な時刻の下限をHH:mm、HH:mm:ss形式の文字列で設定する。
 *     scrollable:     スクロールで時刻を変更可能とするか否か。
 *     format:         "ampm" または "24hr"
 *
 *     テキストボックスに対する次のプロパティも利用可能
 *     append-icon、clearable、dense、filled、flat、hint、label、outlined、prepend-inner-icon、persistent-hint、placeholder
 *     prefix、rounded、shaped、single-line、solo、solo-inverted、suffix
 *
 *   [その他]
 *     一般的に使われそうなプロパティのみサポートしています。
 *     vuetifyのv-time-pickerはイベント日の設定や色の変更などもサポートしているので
 *     必要に応じてプロパティを追加してください。
 *
 */
import vueUtils from '@/scripts/utils/vueUtils';

export default {
  data() {
    return {
      "name": 'TimePicker',
      "time": null,
      "show": false,
      "disconnect": null,
      "classList": [],
    }
  },
  "props": {
    "modelValue": {
      "type": String,
      "required": false,
      "default": () => ''
    },
    "useSeconds": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "disabled": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "rules": {
      "type": Array,
      "required": false,
      "default": () => []
    },
    "allowedHours": {
      "type": [Function, Array],
      "required": false,
      "default": () => () => true
    },
    "allowedMinutes": {
      "type": [Function, Array],
      "required": false,
      "default": () => () => true
    },
    "allowedSeconds": {
      "type": [Function, Array],
      "required": false,
      "default": () => () => true
    },
    "max": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "min": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "scrollable": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "format": {
      "type": String,
      "required": false,
      "default": () => 'ampm'
    },
    "appendInnerIcon": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "prependInnerIcon": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "clearable": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "dense": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "filled": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "flat": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "hint": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "label": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "outlined": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "persistentHint": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "placeholder": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "prefix": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "rounded": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "shaped": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "singleLine": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "solo": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "soloInverted": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "suffix": {
      "type": String,
      "required": false,
      "default": () => null
    },
  },
  emits: ['update:modelValue'],
  "computed": {
    errorBucket() {
      return this.$refs.textRef.errorBucket;
    },
    valid() {
      return this.$refs.textRef.valid;
    },
    error() {
      return this.$refs.textRef.error;
    },
    errorCount() {
      return this.$refs.textRef.errorCount;
    },
    errorMessages() {
      return this.$refs.textRef.errorMessages;
    },
  },
  "watch": {
    modelValue(newVal) {
      this.time = newVal;
    },
    time(newVal) {
      this.$emit('update:modelValue', newVal);
    },
  },
  "methods": {
    reset(...args) {
      return this.$refs.textRef.reest(...args);
    },
    resetValidation(...args) {
      return this.$refs.textRef.resetValidation(...args);
    },
    validate(...args) {
      return this.$refs.textRef.validate(...args);
    },
  },
  mounted() {
    this.time = this.modelValue;
    this.disconnect = vueUtils.watchDomAttribute(this.$refs.menu.$el, 'class', newVal => {
      this.classList = newVal.split(' ').filter(clazz => clazz !== 'v-menu')
    });
  },
  beforeUnmount() {
    this.disconnect && this.disconnect();
  }
}
</script>
