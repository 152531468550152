<template>
  <v-app>

    <router-view v-slot="{ Component }" v-if="mounted">
      <component ref="view" :is="Component" />
    </router-view>

    <!-- ダイアログなど各画面共通で使う要素はここで定義する -->
    <dialog-setup />

  </v-app>
</template>

<script>
import {onMounted, provide, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import analytics from '@/scripts/common/analytics.js';

function onUnhandledRejection (event) {
  // エラー処理済みの場合に、開発者ツールのコンソールにUncaughtログを出さないようにする
  if (event.reason && event.reason.errorHandled) {
    event.preventDefault();
  }
}

export default {
  setup (props, ctx) {
    window.addEventListener("unhandledrejection", onUnhandledRejection, {passive: false});

    const store = useStore();
    const route = useRoute();
    const view = ref(null);
    const mounted = ref(false);
    const analyticsViewRef = ref(view.value);

    // リファラを保存
    store.commit('setReferrer', document.referrer);

    // 閉じた場合の解析情報登録送信処理を追加
    analytics.addOnCloseAnalyticsSend(analyticsViewRef, route);
    // 配下のrouter-viewを持つコンポーネントに参照情報更新用のrefを渡す
    provide('analytics-view-ref', analyticsViewRef)

    // 初期処理
    onMounted(async () => {
      mounted.value = true;
    });

    // 画面遷移の監視
    watch(view, (current, prev) => {
      if (current) {
        // 閉じた場合の解析情報登録送信処理の参照情報を更新
        analyticsViewRef.value = current;
      }
      if (prev) {
        // 遷移時の解析情報登録送信処理を呼び出す
        analytics.onPageMoveAnalyticsSend(prev, route, route.meta.previousRoute);
      }
    });

    // template内で使用する変数を返却
    return {
      view,
      mounted,
    };
  },
}
</script>
