/**
 * クリップボードに指定文字列をコピーする
 * 参照：https://qiita.com/simiraaaa/items/2e7478d72f365aa48356
 */
const setClipBoard = string => {

  // 空div 生成
  let tmp = document.createElement("div");
  // 選択用のタグ生成
  let pre = document.createElement('pre');

  // 親要素のCSSで user-select: none だとコピーできないので書き換える
  pre.style.webkitUserSelect = 'auto';
  pre.style.userSelect = 'auto';

  tmp.appendChild(pre).textContent = string;

  // 要素を画面外へ
  let style = tmp.style;
  style.position = 'fixed';
  style.right = '200%';

  // body に追加
  document.body.appendChild(tmp);
  // 要素を選択
  document.getSelection().selectAllChildren(tmp);

  // クリップボードにコピー
  let result = document.execCommand("copy");

  // 要素削除
  document.body.removeChild(tmp);

  return result;
};


/**
 * 指定HTMLの幅と高さを取得する。
 * [引数]
 *   html : HTML文字列
 *   elm  : HTMLを生成する親要素。指定がない場合はdocument.body。
 */
const getElementSize = (html, elm = window.document.body) => {
  const span = window.document.createElement('span');
  span.style.visibility = 'hidden';
  span.innerHTML = html;
  elm.appendChild(span);
  const ret = {"width": span.offsetWidth,
"height": span.offsetHeight};
  span.remove();
  return ret;
};

/**
 * ファイルをBase64形式に変換する
 * 例：
 *   toBase64File(document.getElementById('file').files[0]);
 */
const toBase64File = file => new Promise(resolve => {
  const reader = new FileReader();
  reader.onload = elem => {
    resolve(elem.target.result);
  };
  reader.readAsDataURL(file)
});

/**
 * 画像ファイルの幅と高さを取得する
 */
const getImageRect = src => new Promise(resolve => {
  const img = new Image();
  img.onload = () => {
    resolve({
      "width": img.naturalWidth || img.width,
      "height": img.naturalHeight || img.height,
    });
  };
  img.src = src;
});

export {
  setClipBoard,
  getElementSize,
  toBase64File,
  getImageRect,
};

export default {
  setClipBoard,
  getElementSize,
  toBase64File,
  getImageRect,
}

