import AlertDialog from './AlertDialog.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import LoadingDialog from './LoadingDialog.vue';
import DialogSetup from './DialogSetup.vue';

export {
  AlertDialog,
  ConfirmDialog,
  LoadingDialog,
  DialogSetup,
};

export default {
  AlertDialog,
  ConfirmDialog,
  LoadingDialog,
  DialogSetup,
};
