import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate'

/**
 * vuexの使用例
 * [store.js]
 *   state: {                                   // state配下に任意の階層のオブジェクトを記載する
 *     account: {
 *       userName: 'サンプル 太郎',
 *       role: 1,
 *     }
 *   },
 *   mutations: {                               // mutations配下に関数を登録
 *     setRole (state, role) {
 *       state.account.role = role;
 *     },
 *   },
 *   getters: {                                // getters配下にロジックを含む取得処理を登録
 *     userInfo (state) {
 *       const roleName = (state.account.role === 0) ? '管理者' : '一般ユーザ';
 *       return `ロール：${roleName}\n名前：${state.account.userName}`;
 *     }
 *   },
 *   actions: {                                // actions配下に非同期処理を登録
 *     sleep ({state}, cfg) {
 *       return new Promise(resolve => {
 *         setTimeout(() => resolve(state.account.role), cfg.time);
 *       });
 *     }
 *   }
 *
 * [利用する側]
 *   onClick () {
 *     //this.$store.commit(関数名, 引数) で登録/更新処理を実行
 *    this.$store.commit('setRole', 1);
 *
 *     // 参照するときはthis.$store.state から直接アクセスできる
 *     console.log(this.$store.state.acount.userName);
 *
 *     //this.$store.getters.関数名 でロジックを含む取得処理を実行可能(キャッシュされる)
 *     console.log(this.$store.getters.userInfo);
 *
 *     //this.$store.dispatch(関数名, 引数) で非同期処理を実行可能
 *    this.$store.dispatch('sleep', {time: 1000}).then(role => console.log(role));
 *   }
 */

export default createStore({
    "plugins": [
        createPersistedState({
            "key": 'sishoitenwebpoc',
            "paths": ['state'],
            "storage": window.sessionStorage
        })
    ],
    "state": {
        "holidays": [],
        "isValidHolidays": null,
        "userId": null,
        "account": null,
        "token": null,
        "isAdministrative": null,
        "selectedFacility": null,
        "referrer": '',
        "codes": null,
    },
    "mutations": {
        setUserId(state, userId) {
            if (!state.userId) {
                state.userId = userId;
            }
        },
        setHolidays(state, holidays) {
            state.holidays = holidays;
        },
        setIsValidHolidays(state, valid) {
            state.isValidHolidays = valid;
        },
        setRole(state, role) {
            state.account.role = role;
        },
        setAccount(state, account) {
            state.account = account;
        },
        setAccountType(state, isAdministrative) {
            state.isAdministrative = isAdministrative;
        },
        setToken(state, token) {
            state.token = token;
        },
        setSelectedFacility(state, selectedFacility) {
            state.selectedFacility = selectedFacility;
        },
        setReferrer(state, referrer) {
            state.referrer = referrer;
        },
        setCodes(state, codes) {
            state.codes = codes;
        },
    },
    "getters": {},
    "actions": {}
})
