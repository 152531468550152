import {nextTick} from 'vue';
import {msg} from '@/scripts/consts';
import settings from '@/scripts/restClient/setting.js';
import utils from '@/scripts/utils';

const axios = require('axios');

/**
 * 以下の設定を行ってRESTクライアントの関数を作成する
 *
 *   1. APIの情報を settings に定義する (自動生成)
 *   2. トークンやAPIキーなどの設定処理を customizeRequest() に定義する
 *   3. 関数の戻り値にヘッダ情報を含めるなどのカスタマイズ処理を customizeResponse() に定義する (オプション)
 *   4. 送信方式を追加したい場合は apiType にAPI種別の定義を追加する (オプション)
 *   5. レスポンス情報をエラーとして扱う判断条件を記載する (オプション)
 *   6. commonErrorHandler() の共通エラー処理を変更する (オプション)
 *
 * 定義を設定することで以下のエクスポート関数が設定される。
 *
 *   methodName(arg, showLoading = true, errorHandler = commonErrorHandler)
 *     arg:          クエリパラメータ情報やbody部の情報などを指定する。
 *     showLoading:  オプション。trueの場合はレスポンスが返却されるまでローディングダイアログを表示する
 *     errorHandler: オプション。エラー処理を指定する。
 */

/**
 *  1.API定義
 *
 *  キー値：
 *    特に処理に影響は与えないので任意のキーを指定可能。
 *
 *  methodMame：
 *    関数名を記載する。
 *
 *  type：
 *    以下のいずれかを指定。必要に応じて apiType の定義を追加することで追加できる。
 *      get           GETメソッドでJSONを取得する場合に指定。
 *      getFileMemory GETメソッドでファイルをダウンロードする場合に指定。一旦メモリに格納後、ダウンロードダイアログを表示する。
 *      getFileStream GETメソッドでファイルをダウンロードする場合に指定。ブラウザのダウンロード機能を利用。
 *      post          POSTメソッドでJSONを取得する場合に指定。
 *      postForm      POSTメソッドでmultipart/form-dataを送信し、JSONを取得する場合に指定。
 *      put:          PUTメソッドでJSONを取得する場合に指定。
 *      delete:       DELETEメソッドでJSONを取得する場合に指定。
 *      beacon:       POSTメソッドでmultipart/form-dataを送信する場合に指定(window.unload時の利用を想定)。
 *
 *  url：
 *    URLはベースURLを開発環境/商用環境で切り替えて設定すること。
 *    切り替えはpackage.jsonのscriptsのオプションで指定しており参照箇所は以下となる。。
 *      開発環境では「.env.development」ファイルからBASE_URLを取得。
 *      商用環境では「.env.production」ファイルからBASE_URLを取得。
 */

/**
 * 2.リクエストカスタマイズ
 *   axiosの設定情報が引数に渡されるのでトークンやAPIキーを設定する。
 *   その他、動的なパラメータ変更やURL変更などのカスタマイズも可能。
 */
const customizeRequest = (config, param) => {

    // トークンが設定されていない場合はヘッダ付与しない
    const token = utils.getToken();
    if (token == null) {
        return config;
    }

    // APIキーを付与する
    config.headers = config.headers || {};
    config.headers['Ocp-Apim-Subscription-Key'] = utils.getApiKey();

    config.headers = config.headers || {};
    config.headers['X-AUTH-TOKEN'] = token;

    return config;

}

/**
 * 3.レスポンスのカスタマイズ
 * デフォルトの設定では関数の戻り値はレスポンスデータになる。
 * レスポンスヘッダの値を画面で利用したい場合ではこの関数でカスタマイズが可能。
 * この関数の戻り値が関数の戻り値となる。
 */
const customizeResponse = (reqCfg, response) => response.data


/**
 * 4.API種別
 *   基本的なリクエストの種類は定義済みだがpostメソッドでファイルをダウンロードしたいなど、
 *   定義を増やしたい場合は行を追加して以下の設定を反映する。
 *
 *  キー値：
 *    「1.API定義」のtypeに指定する値
 *
 *  processor：
 *    以下のいずれかを設定する
 *      commonRequest   ダウンロード以外のリクエスト
 *      memoryDownload  ダウンロードリクエスト。エラーハンドリング可能だがファイルをメモリに保持するため巨大ファイルに対して使用すると性能問題になる。
 *      streamDownload  ダウンロードリクエスト。アンカーリンクでダウンロードする場合に使用。巨大ファイルでも処理に影響が出ないがエラーハンドリング不可。
 *
 *  method：
 *    HTTPのメソッド
 *
 *  responseType：
 *    レスポンスの型に応じて以下の値を設定する。
 *      jsonオブジェクト      ：json
 *      画像、バイナリファイル：arraybuffer、blob、stream
 *      テキスト情報          ：document、text
 *
 *  params/data：
 *    クエリパラメータとしてリクエストを投げる場合はparamsに値を設定する。
 *    body部にJSON文字列を格納する場合はdataに値を設定する。
 *    toForm()でラップした場合、JSONオブジェクトをFormDataに変換する。
 *
 *  headers:
 *    ヘッダ情報を設定する。
 */
const getApiType = () => {
    // 基本設定
    const baseApiType = {
        "get": {
            "processor": commonRequest,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'get',
                "responseType": 'json',
                "params": arg
            })
        },
        "getFileMemory": {
            "processor": memoryDownload,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'get',
                "responseType": 'blob',
                "params": arg
            })
        },
        "getFileStream": {
            "processor": streamDownload,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'get',
                "responseType": 'blob',
                "params": arg
            })
        },
        "post": {
            "processor": commonRequest,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'post',
                "responseType": 'json',
                "data": arg
            })
        },
        "postForm": {
            "processor": commonRequest,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'post',
                "responseType": 'json',
                "data": toForm(arg),
                "headers": {'content-type': 'multipart/form-data'}
            })
        },
        "put": {
            "processor": commonRequest,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'put',
                "responseType": 'json',
                "data": arg
            })
        },
        "delete": {
            "processor": commonRequest,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'delete',
                "responseType": 'json',
                "data": arg
            })
        },
        "beacon": {
            "processor": beaconRequest,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'post',
                "responseType": 'json',
                "data": toBlob(arg)
            })
        },
        "beaconForm": {
            "processor": beaconRequest,
            "axiosConfigMaker": (url, arg) => ({
                url,
                "method": 'post',
                "responseType": 'json',
                "data": toForm(arg)
            })
        },
    };

    // 必要に応じて定義を追加する
    // const addApiType = {
    //  path1:         {processor: commonRequest,  axiosConfigMaker: (url, arg) => ({url: `${url}/${arg.group}/${arg.item}`, method: 'get', responseType: 'json', params: arg.params})},
    //  path2:         {processor: commonRequest,  axiosConfigMaker: (url, arg) => ({url: `${url}/${arg.paths.join('/')}`, method: 'post', responseType: 'json', data: arg.data})},
    //  special1:      {processor: commonRequest,  axiosConfigMaker: (url, arg) => ({url: url, method: arg.method, responseType: 'json', data: arg.data, params: arg.params})},
    // };
    const addApiType = {};

    return {
        ...baseApiType,
        ...addApiType
    };
}

const apiType = getApiType();

/**
 * 5.エラー判定処理
 * ステータスコードが200以外の場合はエラーとする。
 * また、ステータスコードが200であってもresultCodeが0000ではない場合はエラーとする。
 */
const isError = response => {
    if (response.status !== 200) {
        return true;
    }
    if (!response.data) {
        return true;
    }
    if (response.data instanceof Blob) {
        return false;
    }
    if (response.data.resultCode !== '0000') {
        return true;
    }
    return false;
}

/**
 * 6.共通エラー処理
 * ステータスコードまたは処理結果コードをキーとした関数を定義する。
 * 処理継続可能なエラーであればエラーダイアログを表示し、
 * 処理継続が不可能であればエラーダイアログ表示後に別ページに強制遷移させる方式とする。
 *
 * サーバとクライアントで同じバリデーションチェックを実施する前提とし、
 * サーバ側でバリデーションチェックエラーを検出した場合は
 * 準正常系として200(正常)で返して画面にエラー情報を表示するのではなく、
 * リクエスト改ざんを検出した異常系として400(リクエスト不正)を返す方針とする。
 *
 * 画面ごとにエラー処理を変更する必要がある場合は以下のように共通処理をコピー後カスタマイズして設定すること。
 * <v-text-field :error-messages="textErrorMessages" />
 * const errorHandler = {
 *    ...restClient.errorHandler,
 *    '400': () => {this.textErrorMessages = '入力値が不正です';}
 * }
 * restClient.login(param, true, errorHandler);
 */
const commonErrorHandler = {

    // バリデーションチェックNG
    '1000'(response) {
        utils.alert(msg.INVALID_REQUEST);
    },

    // トークン不正
    '1001'(response) {
        utils.alert(msg.INVALID_TOKEN)
            .then(() => utils.moveTo('/'));
    },

    // その他エラー
    '2002'(response) {
        utils.alert(msg.SYSTEM);
    },

    // レスポンスが取得できない
    other(response) {
        nextTick(() => {
            utils.alert(msg.WAIT_RECOVER);
        });
    }
}

/** ********* ここから下は基本的に編集不要です *****************/

// 環境変数に「VUE_APP_AXIOS_PROXY」が設定されている場合はプロキシの設定を追加
if (utils && utils.getAxiosProxy()) {
    axios.defaults.proxy = utils.getAxiosProxy();
}

// ファイル読み込みクラス
class FileReaderEx extends FileReader {
    constructor() {
        super();
    }
    readAs(blob, ctx) {
        return new Promise((resolve, reject) => {
            super.addEventListener('load', ({target}) => resolve(target.result));
            super.addEventListener('error', ({target}) => reject(target.error));
            super[ctx](blob);
        })
    }
    readAsText(blob) {
        return this.readAs(blob, 'readAsText');
    }
}

// BlobでJSONを返却された場合にJSONオブジェクトに変換する
async function convertBlobToJson(response) {
    if (response.data instanceof Blob) {
        const fileReader = new FileReaderEx();
        const text = await fileReader.readAsText(response.data);
        response.data = JSON.parse(text);
    }
}

// レスポンス情報からcommonErrorHandlerのキー値を取得する
const getErrorHandlerKey = response => {

    // 通信エラーなどでレスポンス情報が取得できない場合は'other'を返す
    if (!response) {
        return 'other';
    }

    // レスポンスデータがない、またはレスポンスデータにresultCodeがない場合は'other'を返す
    if (!response.data || !response.data.resultCode) {
        return 'other';
    }

    // ステータスコードが400(かつレスポンスデータにresultCodeあり)の場合は1000を返す
    if (response.status === 400) {
        return '1000';
    }

    // 上記以外の場合はresultCodeを返す
    return String(response.data.resultCode);
}

// 共通リクエスト処理
function commonRequest (configMaker, param = null, showLoading = true, errorHandler = commonErrorHandler) {
    let res = null;
    const cfg = customizeRequest(configMaker(param), param);
    showLoading && utils.loading(true);
    return new Promise((resolve, reject) => {
        axios(cfg)
            .then(response => {
                res = response;
                if (isError(response)) {
                    let key = getErrorHandlerKey(response);
                    if (!errorHandler[key]) {
                        console.error(`ステータスコードまたはresultCodeが${key}の場合の処理が定義されていません。`, response);
                        key = 'other';
                    }
                    try {
                        errorHandler[key](response);
                        response.errorHandled = true;
                    } catch (err) {
                        console.error('エラー処理中に例外が発生しました。', cfg, err);
                    }
                    //console.log(`★commonRequest then reject`, response)
                    reject(response);
                } else {
                    //console.log(`★commonRequest then resolve`, response)
                    resolve(customizeResponse(cfg, response));
                }
            })
            .catch(error => {
                //console.log(`★commonRequest catch`, error)
                res = res || error.response || error;
                showLoading && utils.loading(false);
                let key = getErrorHandlerKey(error.response);
                key = Object.keys(errorHandler).includes(key) ? key : 'other';
                errorHandler[key](error.response);
                res.errorHandled = true;
                reject(res);
            })
            .finally(() => {
                showLoading && utils.loading(false);
                addDebugHistory({
                    ...cfg,
                    "rawParam": param
                }, res);
            });
    });
}

// メモリ保持後にファイルダウンロード
function memoryDownload (configMaker, param = null, showLoading = true, errorHandler = commonErrorHandler) {
    let res = null;
    const cfg = customizeRequest(configMaker(param), param);
    showLoading && utils.loading(true);
    return new Promise((resolve, reject) => {
        axios(cfg)
            .then(response => {
                res = response;
                if (isError(response) || response.headers['content-type'] !== 'application/octet-stream') {
                    convertBlobToJson(response)
                        .then(() => {
                            let key = getErrorHandlerKey(response);
                            if (!errorHandler[key]) {
                                console.error(`ステータスコードまたはresultCodeが${key}の場合の処理が定義されていません。`, response);
                                key = 'other';
                            }
                            try {
                                errorHandler[key](response);
                                response.errorHandled = true;
                            } catch (err) {
                                console.error('エラー処理中に例外が発生しました。', cfg, err);
                            }
                        })
                        .finally(() => {
                            reject(response);
                        });
                    return;
                }
                const contentDisposition = response.headers['content-disposition'];
                const fileName = getFileNameFromContentDisposition(contentDisposition);
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                URL.revokeObjectURL(url);
                link.remove();
                resolve();
            })
            .catch(error => {
                res = res || error.response || error;
                showLoading && utils.loading(false);
                let key = getErrorHandlerKey(error.response);
                key = Object.keys(errorHandler).includes(key) ? key : 'other';
                errorHandler[key](error.response);
                res.errorHandled = true;
                reject(res);
            })
            .finally(() => {
                showLoading && utils.loading(false);
                addDebugHistory({
                    ...cfg,
                    "rawParam": param
                }, res);
            });
    });
}

// 共通ストリームダウンロードリクエスト処理
// アンカーリンクを使用してgetリクエストを投げるためローディングダイアログの制御は行わない。
// ほかのメソッドと同じようにPromiseを返すがすぐに次の処理が実行される。
function streamDownload (configMaker, param = {}) {

    const cfg = customizeRequest(configMaker(param), param);
    addDebugHistory({
        ...cfg,
        "rawParam": param
    }, null);
    const a = document.createElement('a');
    a.download = '';
    const cfgParams = cfg.params || {};
    a.href = cfg.url + getQueryParameter({
        ...param,
        ...cfgParams
    });
    document.body.appendChild(a);
    a.onclick = function () {
        a.parentNode.removeChild(a);
    };
    a.click();
    return Promise.resolve();
}

// navigator.sendBeacon()で送信する
function beaconRequest (configMaker, param = null) {
    const cfg = customizeRequest(configMaker(param), param);
    addDebugHistory({
        ...cfg,
        "rawParam": param
    }, null);
    window.navigator.sendBeacon(`${cfg.url}`, cfg.data);
}

// オブジェクトからクエリパラメータを作成
const getQueryParameter = param => {
    const qParam = Object.entries(param)
        .map(([
                  key,
                  val
              ]) => `${key}=${encodeURIComponent(val)}`)
        .join('&');
    return qParam ? `?${qParam}` : '';
}


// Content-Dispositionからファイル名を取得
// https://tkkm.tokyo/post-243/
const getFileNameFromContentDisposition = disposition => {
    // 正規表現でfilenameを抜き出す
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
        const fileName = matches[1].replace(/['"]/g, '');
        // 日本語対応
        return decodeURIComponent(fileName).replace(/\+/g, ' ');
    }
    return null;

}

/**
 * JSONオブジェクトまたはArrayをFormDataオブジェクトに変換する
 *
 * JSONオブジェクトの場合はkey、valueをそのままFormDataに格納する。
 *   {key1: val1, key2: val2}
 *      formData.append(key1, val1);
 *      formData.append(key2, val2);
 *
 * 同一キーに複数データがある場合は該当のキーに対するvalueをリスト形式で格納する。
 *   {key1: [val1, val2]}
 *      formData.append(key1, val1);
 *      formData.append(key1, val2);
 *
 * データの格納順番が決められている場合はオブジェクトのArrayを引数に渡せば順番に格納される。
 *  [{key1: val1}, {key2: val2}, {key1: val3}]
 *      formData.append(key1, val1);
 *      formData.append(key2, val2);
 *      formData.append(key1, val3);
 *
 */
const toForm = param => {
    if (param instanceof FormData) {
        return param;
    }
    const formData = new FormData();
    param = Array.isArray(param) ? param : [param];
    param.forEach(obj => {
        for (let [
            key,
            val
        ] of Object.entries(obj)) {
            if (!Array.isArray(val)) {
                val = [val];
            }
            for (let v of val) {
                formData.append(key, v);
            }
        }
    });
    return formData;
}

/**
 * JSONオブジェクトをBlobオブジェクトに変換する
 */
const toBlob = param => {
    const data = new Blob([JSON.stringify(param)], {
        type: "application/json",
    });
    return data;
}

// 定義情報から関数を作成
const cfgFuncs = Object.fromEntries(Object.values(settings)
    .map(setting => {
        const processor = apiType[setting.type].processor;
        const configMaker = apiType[setting.type].axiosConfigMaker.bind(null, setting.url);
        return [
            setting.methodName,
            processor.bind(null, configMaker)
        ];
    }));

// デバッグ用情報の登録
function addDebugHistory(request, response) {
    if (utils.isDevelopEnv()) {
        window.debug = window.debug || {};
        window.debug.rest = window.debug.rest || [];
        window.debug.rest.push({
            request,
            response
        });
    }
}

export default {
    "errorHandler": commonErrorHandler,
    ...cfgFuncs,
}
