<template>
  <div class="d-flex">

    <template v-if="type==='chip'">
      <v-chip style="height: 40px; border-radius: 16px;" :variant="dark ? 'tonal' : 'outlined'" :class="`text-h6 mt-5 ${dark ? 'bg-' : ''}${color}`"><span class="mx-3">{{ label }}</span>
      </v-chip>
    </template>

    <template v-else-if="type==='card'">
      <v-card class="py-2 px-3 text-h6" variant="outlined" :class="`${dark ? 'bg-' : ''}${color}`">{{ label }}</v-card>
    </template>

    <template v-else-if="type==='underline'">
      <div class="mx-2 px-1 text-h6" :style="undelineStyle">{{ label }}</div>
    </template>

    <template v-else>
      <v-sheet :color="color" width="13" height="30"></v-sheet>
      <div class="pl-2 text-h6">{{ label }}</div>
    </template>

  </div>
</template>

<script>

/**
 * 入力フォームのサブタイトル表示用タグ
 *   [プロパティ]
 *     label: サブタイトルのラベル。
 *     type:  chip/card/underline/sheetのいずれか。
 *     color: 色を指定。
 *            underlineの場合は#FFFFFF形式で指定する必要がある。
 *     dark:  ダークモードを有効にする。cardとchipの場合に有効。
 *
 *   [利用方法]
 *      スタイルを途中で変えることはないと思うので、
 *      プロジェクトごとにこのコンポーネントを書き換えて
 *        <subtitle label="タイトル" />
 *      だけで利用できるようにするのがいいと思います。
 */
export default {
  data() {
    return {}
  },
  "computed": {
    undelineStyle() {
      const underLineColor = this.color.startsWith('#') ? this.color : '#1976d2';
      return {"background": `linear-gradient(transparent 70%, ${underLineColor} 100%)`};
    }
  },
  "props": {
    "label": {
      "type": String,
      "required": false,
      "default": () => ''
    },
    "type": {
      "type": String,
      "required": false,
      "default": () => 'chip'
    },
    "color": {
      "type": String,
      "required": false,
      "default": () => 'primary'
    },
    "dark": {
      "type": Boolean,
      "required": false,
      "default": () => true
    },
  },
}
</script>
