export * from './dialog';
export * from './form';
export * from './image';
export * from './frame';
export * from './other';
export * from './common';

import common from './common';
import dialog from './dialog';
import form from './form';
import frame from './frame';
import image from './image';
import other from './other';

export default {
  ...dialog,
  ...form,
  ...image,
  ...frame,
  ...other,
  ...common,
}
