<script>

/**
 * transitionを行うコンポーネント。
 * 使用例
 *   [1要素の例]
 *   ＜v-btn @click="show = !show"＞btn＜/v-btn＞
 *   ＜transition :x="[-100, 100]"＞
 *     ＜v-card v-show="show" color="success" width="30" height="30" /＞
 *   ＜/transition＞
 *
 *   [複数要素の例]
 *   ＜v-btn @click="cnt++"＞add＜/v-btn＞
 *   ＜v-btn @click="cnt--"＞del＜/v-btn＞
 *   ＜transition group :scale="[0, 0]" :x="[-200, 0]" :y="[0, 200]" tag="div" class="d-flex"＞
 *     ＜v-card v-for="i in cnt" color="primary" width="100" height="100" v-show="show" class="ml-12" :key="i" ＞{{i}}＜/v-card＞
 *   ＜/transition＞
 *
 *
 * 以下のプロパティを持つ。
 *   group     複数の要素に対するtransitionを行う。groupを指定する場合、直下のタグはkey属性が必須。
 *   mode      非表示操作と表示操作がある場合の表示の指定。groupを指定している場合は利用不可。
 *                 modeの指定なし：表示操作と非表示操作を同時に実施
 *                 mode="in-out" ：表示操作後に非表示操作を実施
 *                 mode="out-in" ：非表示操作後に表示操作を実施
 *   tag       groupを指定している場合はtransitionはspanタグで生成される。
 *             生成されるタグを変更したい場合はtag="div"などで指定する。
 *   x         表示前のX座標と非表示後のX座標を相対位置の配列で指定(px単位)
 *               例：
 *                 x=[-20, 20]：表示時は左から現れ、非表示時は右に消えていく表現になる
 *                 x=[0, 50]  ：表示時はX座標の動きはなく、非表示時は右に消えていく
 *   y         表示前のY座標と非表示後のY座標を相対位置の配列で指定(px単位)
 *               例：
 *                 y=[-20, 20]：表示時は上から現れ、非表示時は下に消えていく表現になる
 *                 y=[0, 50]  ：表示時はY座標の動きはなく、非表示時は下に消えていく
 *   rotate    表示前の回転角度と非表示後の回転角度を配列で指定（degree単位)
 *               例：
 *                 rotate=[-45, 45]：表示時は-45°から0°になり、非表示時は0°から45°になる
 *   scale     表示前の大きさの割合と非表示後の大きさの割合を配列で指定
 *               例：
 *                 scale=[0, 2]：表示時は徐々に大きくなって表示される。非表示時も徐々に大きくなって非表示になる。
 *   skew      表示前のゆがみの角度と非表示後のゆがみの角度を配列で指定（degree単位）
 *               例：
 *                 skew=[-45, 45]：表示時はゆがみが是正されながら表示される。非表示時はゆがみながら非表示になる。
 *   duration  変化が完了するまでの時間を配列で指定(ミリ秒、デフォルトは[500, 500])
 *               例：
 *                 duration=[3000, 1000]：表示にかかる時間ははゆっくりだが非表示時は早く消える。
 *   easing    時間的な変化の割合を配列で指定する。
 *             以下の正規表現を満たす値を指定可能。
 *             ease[(In)|(Out)|(InOut)][(Quad)|(Cubic)|(Quart)|(Quint)|(Sine)|(Expo)|(Circ)|(Back)]
 *             デフォルト値は['easeOutCubic', 'easeInCubic']
 *             参考 https://easings.net/ja
 */
import anime from 'animejs';
import vueUtils from '@/scripts/utils/vueUtils.js';

export default {
  "functional": true,
  "props": {
    "group": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "mode": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "tag": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "x": {
      "type": Array,
      "required": false,
      "default": () => null
    },
    "y": {
      "type": Array,
      "required": false,
      "default": () => null
    },
    "rotate": {
      "type": Array,
      "required": false,
      "default": () => null
    },
    "scale": {
      "type": Array,
      "required": false,
      "default": () => null
    },
    "skew": {
      "type": Array,
      "required": false,
      "default": () => null
    },
    "duration": {
      "type": Array,
      "required": false,
      "default": () => [
        500,
        500
      ]
    },
    "easing": {
      "type": Array,
      "required": false,
      "default": () => [
        'easeOutCubic',
        'easeInCubic'
      ]
    },
  },
  "render"(createElement, context) {

    const tag = context.props.group ? 'transition-group' : 'transition';

    // class、styleが指定されていればそれもマージする
    const elmClass = vueUtils.extendClass(context.data.staticClass, context.data.class);
    const elmStyle = vueUtils.extendStyle(context.data.staticStyle, context.data.style);

    // 何も設定が指定されていない場合は x = [-50, 50] をデフォルト値として設定する
    const isEmptySetting = [
      'x',
      'y',
      'rotate',
      'scale',
      'skew'
    ].every(prop => !context.props[prop]);
    const props = isEmptySetting ? {
      ...context.props,
      "x": [
        -50,
        50
      ]
    } : context.props;

    const enterCfg = {
      ...props.x ? {
        "translateX": [
          props.x[0],
          0
        ]
      } : {},
      ...props.y ? {
        "translateY": [
          props.y[0],
          0
        ]
      } : {},
      ...props.rotate ? {
        "rotate": [
          props.rotate[0],
          0
        ]
      } : {},
      ...props.scale ? {
        "scale": [
          props.scale[0],
          1
        ]
      } : {},
      ...props.skew ? {
        "skew": [
          props.skew[0],
          0
        ]
      } : {},
      "duration": props.duration[0],
      "easing": props.easing[0],
      "opacity": 1,
    };

    const leaveCfg = {
      ...props.x ? {
        "translateX": [
          0,
          props.x[1]
        ]
      } : {},
      ...props.y ? {
        "translateY": [
          0,
          props.y[1]
        ]
      } : {},
      ...props.rotate ? {
        "rotate": [
          0,
          props.rotate[1]
        ]
      } : {},
      ...props.scale ? {
        "scale": [
          1,
          props.scale[1]
        ]
      } : {},
      ...props.skew ? {
        "skew": [
          0,
          props.skew[1]
        ]
      } : {},
      "duration": props.duration[1],
      "easing": props.easing[1],
      "opacity": 0,
    };

    const data = {
      ...Object.keys(elmClass).length === 0 ? {} : {"class": elmClass},
      ...Object.keys(elmStyle).length === 0 ? {} : {"style": elmStyle},
      "props": {
        ...context.props.mode ? {"mode": context.props.mode} : {},
        ...context.props.tag ? {"tag": context.props.tag} : {},
      },
      "on": {
        beforeEnter(el) {
          el.style.opacity = 0;
        },
        enter(el, done) {
          anime({
            "targets": el,
            ...enterCfg,
            "complete": done
          });
        },
        afterEnter(el) {
          // 理由は不明だが表示後にshowを外さないと最後の要素がleaveに失敗する
          const childVNode = context.children.find(child => child.elm === el);
          if (childVNode) {
            childVNode.data.show = null;
          }
        },
        leave(el, done) {
          anime({
            "targets": el,
            ...leaveCfg,
            "complete": done
          });
        }
      }
    };

    return createElement(tag, data, context.children);
  },
}
</script>
