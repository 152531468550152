<template>
  <div>
    <template v-for="component of components">
      <component :is="component.name" :ref="component.name" />
    </template>
  </div>
</template>

<script>
  import AlertDialog from './AlertDialog';
  import ConfirmDialog from './ConfirmDialog';
  import LoadingDialog from './LoadingDialog';
  import {dialogs} from '@/scripts/utils/dialog.js';
  
  // 標準ダイアログ
  const allDialogs = {
    AlertDialog,
    ConfirmDialog,
    LoadingDialog,
  };
  
  // コンポーネント名、関数名を取得
  const components = Object.entries(allDialogs)
                           .map(([name, component]) => ({name, funcName: component.methods?.getFuncName()}));
  
  export default {
    components: allDialogs,
    data () {
      return {
        components,
      };
    },
    
    /**
     * dialogsオブジェクトに以下のプロパティを登録する
     *   dialogs.<関数名> = this.$refs.<コンポーネント名>.open
     *
     * dialogsはutilsから参照できるのでutils経由で以下のようにダイアログ表示処理を呼び出せる。
     *   utils.dialogs.<関数名>(引数)
     */
    mounted () {
      this.components.forEach(({name, funcName}) => {
        const ref = Array.isArray(this.$refs[name]) ? this.$refs[name][0] : this.$refs[name];
        const key = funcName || ref.name;
        if (key && ref.open) {
          dialogs[key] = ref.open;
        }
      });
    }
  }
</script>
