/**
 * 以下のアクセス方法を提供する
 *
 * [生成時]
 *   const roleType = new EnumHandler([
 *     'USER:一般ユーザ:0',  // swaggerの定義部分をコピー
 *     'ADMIN:管理者:1',
 *   ]);
 *
 * [利用時]
 *   export {roleType} from '@/scripts/consts/enum.js';
 *   roleType.USER === 0
 *   roleType.label(0) === '一般ユーザ'
 *   roleType.value('一般ユーザ') === 0
 *   roleType.items === [{text: '一般ユーザ', value: 0}, {text: '管理者', value: 1}]
 */
class EnumHandler {

    // eslint-disable-next-line max-lines-per-function
    constructor(enumList) {

        // 値が全部数値変換可能であれば数値として扱う
        let enumObjects = enumList.map(item => item.split(':'))
            .map(([
                      varName,
                      text,
                      value
                  ]) => ({
                varName,
                text,
                value
            }));
        const isAllIntVal = enumObjects.every(({value}) => value === '0' || value.match(/^[1-9][0-9]*$/));
        if (isAllIntVal) {
            enumObjects = enumObjects.map(({varName, text, value}) => ({
                varName,
                text,
                "value": parseInt(value, 10)
            }));
        }

        // 値が 'true'、'false' であればtrue,falseとして扱う
        const isAllBool = enumObjects.every(({value}) => [
            'true',
            'false'
        ].includes(value));
        if (isAllBool) {
            enumObjects = enumObjects.map(({varName, text, value}) => ({
                varName,
                text,
                "value": 'true' === value
            }));
        }

        // 変数名を指定してvalueを受け取る
        const that = this;
        enumObjects.forEach(({varName, value}) => that[varName] = value);

        // 値を指定してラベルを受け取る
        this._labels = Object.fromEntries(enumObjects.map(({text, value}) => [
            value,
            text
        ]));

        // ラベルを指定して値を受け取る
        this._values = Object.fromEntries(enumObjects.map(({text, value}) => [
            text,
            value
        ]));

        // itemsを受け取る
        this._items = enumObjects.map(({text, value}) => ({
            text,
            value
        }));
    }

    get items() {
        return [...this._items];
    }

    label(value) {
        return this._labels[value];
    }

    value(label) {
        return this._values[label];
    }
}

const equipType = new EnumHandler([
    'POLE:電柱:0',
    'BRANCH_LINE:支線:1',
    'BRANCH_POLE:支柱:2',
    'CABLE:ケーブル:3',
    'SERVICE_LINE:引込線:4',
    'MANHOLE:マンホール:5',
    'OTHER:その他:6',
]);

const ampm = new EnumHandler([
    'AM:AM:0',
    'PM:PM:1',
]);

const contactType = new EnumHandler([
    'MAIL:メール:0',
    'TEL:電話:1',
]);

const reasonType = new EnumHandler([
    'BUILD_INTERRUPT:建物建築支障:0',
    'TRAFFIC_INTERRUPT:交通（出入り）支障:1',
    'DESTROY_INTERRUPT:建物解体支障:2',
    'PASS_ABOVE_CABLE:敷地内ケーブル上空通過:3',
    'OTHER:その他理由で支障:4',
]);

const loginAuthMethod = new EnumHandler([
    'MULTI_FACTOR_OTP_MAIL:ワンタイムパスワードあり（多要素認証）:1',
    'SINGLE_FACTOR:ワンタイムパスワードなし（単要素認証）:0',
]);

const analyticsNotifyCategory = new EnumHandler([
    'TOP:トップ:0',
    'ACCOUNT:アカウント作成:1',
    'APPLY:申請:2',
]);

const addUserErrorCode = new EnumHandler(['DUPLICATE_USER_ID:ユーザID重複:0']);

const resultCode = new EnumHandler([
    'SUCCESS:正常終了:0000',
    'VALIDATION_CHECK_NG:バリデーションチェックNG:1000',
    'INVALID_TOKEN:トークン不正:1001',
    'CONFLICT_ERROR:排他チェックNG:1002',
    'FILE_NOT_FOUND:ファイルが存在しない:1003',
    'INVALID_ACCOUNT:アカウント不正:1004',
    'INTERNAL_ERROR:サーバ内部エラー:2000',
    'IW3_ERROR:IW3(郵便番号検索サービス)エラー:2001',
    'HOLIDAY_API_ERROR:祝日一覧APIエラー:2002',
    'UNKNOWN_ERROR:不明なエラー:2002',
]);


export {
    equipType,
    ampm,
    contactType,
    reasonType,
    loginAuthMethod,
    analyticsNotifyCategory,
    addUserErrorCode,
    resultCode,
};

export default {
    equipType,
    ampm,
    contactType,
    reasonType,
    loginAuthMethod,
    analyticsNotifyCategory,
    addUserErrorCode,
    resultCode,
    EnumHandler,
};
