<template>
  <div>

    <!-- 画面サイズが小さい時はタイトルとフィールドは別の行で表示 -->
    <template v-if="showTwoLines">
      <v-row class="pt-2 pb-1">
        <span :class="labelClass">{{ label }}</span>
        <template v-if="required">
          <v-tooltip bottom>
            <template v-slot:activator="{ props: on }">
              <v-chip size="small" theme="dark" v-bind="on" class="ml-2 bg-red">
                <div class="text-center" style="width: 3em;">必須</div>
              </v-chip>
            </template>
            必須入力です。
          </v-tooltip>
        </template>
        <span v-if="label" :class="{'pl-2': true, ...labelClass}">：</span>
        <div class="my-1" style="width: 100%;"></div>
        <slot></slot>
      </v-row>
    </template>

    <!-- 画面サイズが大きい時はタイトルとフィールドは同じ行で表示 -->
    <template v-else>
      <v-row class="flex-nowrap">
        <div v-if="label" class="pt-4 text-right" :style="labelStyle">
          <span :class="labelClass">{{ label }}</span>
          <template v-if="required">
            <v-tooltip bottom>
              <template v-slot:activator="{ props: on }">
                <v-chip class="ml-2 mt-n1 bg-red" dark v-bind="on">
                  <div class="text-center" style="width: 3em;">必須</div>
                </v-chip>
              </template>
              必須入力です。
            </v-tooltip>
          </template>
          <span :class="{'pl-2': true, ...labelClass}">：</span>
        </div>
        <div v-else-if="!label && labelWidth" :style="labelStyle"/>
        <slot></slot>
      </v-row>
    </template>

  </div>
</template>

<script>

/**
 * 入力フォームの1行データ配置タグ
 *   [プロパティ]
 *     label:          行の左にタイトルラベルを表示する。
 *                     md以下の画面サイズの場合は行の上にタイトルラベルを表示する。
 *                     指定がない場合はラベルを表示しない。
 *     required:       "(必須)"をタイトルの右に表示する。
 *                     タイトルの指定がない場合は無効。
 *     label-width:    タイトルの幅。
 *                     タイトルの指定がない場合は無効。
 *     breakpoint:     タイトルラベルの配置を左から上に変更するサイズ。
 *                     xs/sm/md/lgのいずれか
 */
export default {
  data() {
    return {}
  },
  "computed": {
    labelStyle() {
      const pxWidth = String(this.labelWidth) + 'px';
      return {
        "width": pxWidth,
        'min-width': pxWidth
      };
    },
    showTwoLines() {
      return this.breakpoint === 'xs' ? this.$vuetify.display.xs
          : this.breakpoint === 'sm' ? this.$vuetify.display.smAndDown
              : this.breakpoint === 'lg' ? this.$vuetify.display.lgAndDown
                  : this.$vuetify.display.mdAndDown;
    },
  },
  "props": {
    "label": {
      "type": String,
      "required": false,
      "default": () => ''
    },
    "labelClass": {
      "type": [String, Array, Object],
      "required": false,
      "default": () => 'text-indigo-darken-4'
    },
    "required": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "requiredClass": {
      "type": [String, Array, Object],
      "required": false,
      "default": () => 'text-red-darken-1'
    },
    "labelWidth": {
      "type": [String, Number],
      "required": false,
      "default": () => '300'
    },
    "breakpoint": {
      "type": String,
      "required": false,
      "default": () => 'sm'
    },
  },
}
</script>
