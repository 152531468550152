<template>
  <div>
    <v-menu absolute offset-y v-model="isOpenMenu" :disabled="disableMenu">
      <template #activator="{ props: on1 }">
        <v-tooltip bottom :disabled="disableTooltip || isOpenMenu">
          <template v-slot:activator="{ props: on2 }">

            <!-- ボタン -->
            <v-btn v-bind="mergeEvent(on1, on2)" ref="bindOn" class="px-2">
              <template v-if="prependIcon">
                <v-icon :color="textColor" :size="iconSize" class="pr-1" :icon="prependIcon"></v-icon>
              </template>
              <span :class="fontColor">{{ label }}</span>
              <template v-if="!disableMenu && !noMenuIcon">
                <v-icon :color="textColor" right icon="mdi-chevron-down"></v-icon>
              </template>
            </v-btn>

          </template>
          <span v-text="tooltip"></span>
        </v-tooltip>
      </template>

      <!-- メニュー表示 -->
      <template v-if="!disableMenu">
        <v-list density="compact" class="custom-toolbutton">
          <v-list-item v-for="(item, i) in menuItems" :key="i" @click="item.onclick" exact-active-class="primary">
            <template v-slot:prepend v-if="item.icon">
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-title v-text="item.text"/>
          </v-list-item>
        </v-list>
      </template>
    </v-menu>
  </div>
</template>

<script>

/**
 * ツールボタン表示用のボタンタグ。
 * ボタンにツールチップとポップアップメニューを追加できる。
 * 使用できるプロパティはv-btnタグと同じものに以下の値も指定できる。
 *
 *   label       : ボタンのラベル
 *   prependIcon : ボタンの左側に表示するアイコン
 *   textColor   : ボタンのラベルとアイコンの色
 *   iconSize    : ボタンの左側に表示するアイコンの大きさ
 *   tooltip     : マウスオーバー時に表示されるツールチップ
 *   menus       : クリック時に表示するポップアップメニュー。以下の形式で指定する。
 *     menus = [
 *       {icon: 'xxx', text: 'xxx', onclick: func},
 *       {icon: 'xxx', text: 'xxx', onclick: func},
 *       {icon: 'xxx', text: 'xxx', onclick: func},
 *     ]
 *     icon : ポップアップメニューの項目に表示するアイコン(省略可)
 *     text : ポップアップメニューの項目名
 *     onclick : ポップアップメニュー選択時の処理
 */
import {VBtn} from 'vuetify/components';
import {helper} from '@/scripts/framework';
import vueUtils from '@/scripts/utils/vueUtils.js';

const events = [];
const extendGen = helper.componentExtendGenerator(VBtn, 'fieldRef', events);
export default {
  data() {
    return {
      "isOpenMenu": false,
      "on1": null,
      "on2": null,
    };
  },
  "props": {
    ...extendGen.props(),
    "label": {
      "type": String,
      "required": false,
      "default": () => ''
    },
    "prependIcon": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "textColor": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "iconSize": {
      "type": [Number, String],
      "required": false,
      "default": () => null
    },
    "tooltip": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "menus": {
      "type": Array,
      "required": false,
      "default": () => []
    },
    "noMenuIcon": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
  },
  emits: ['click'],
  "computed": {
    bindValue() {
      // eslint-disable-next-line no-unused-vars
      const {label, prependIcon, tooltips, menus, ...btnProps} = this.$props;
      return btnProps;
    },
    disableTooltip() {
      return this.tooltip == null;
    },
    disableMenu() {
      return this.menus.length === 0;
    },
    menuItems() {
      return this.menus.map(menu => {
        if (typeof menu === 'string') {
          return {"text": menu};
        }
        return menu;

      });
    },
    fontColor() {
      return this.textColor ? vueUtils.toTextColor(this.textColor) : null;
    },
  },
  "methods": {
    storeOn1On2(on1, on2) {
      this.on1 = on1;
      this.on2 = on2;
    },
    mergeEvent(props1, props2) {
      const on1 = vueUtils.getHandler(props1);
      const on2 = vueUtils.getHandler(props2);
      const keys = Array.from(new Set([
        ...Object.keys(on1),
        ...Object.keys(on2)
      ])).filter(key => key.startsWith('on'));
      const entries = keys.map(key => {
        const noOpFn = () => true;
        const onclickFn = key === 'onClick' ? args => this.$emit('click', args) || true : noOpFn;
        const fn1 = on1[key] ? args => on1[key](args) || true : noOpFn;
        const fn2 = on2[key] ? args => on2[key](args) || true : noOpFn;
        return [
          key,
          args => onclickFn(args) && fn1(args) && fn2(args)
        ];
      });
      return Object.fromEntries(Object.entries(this.bindValue).concat(entries));
    },
  },
}
</script>
