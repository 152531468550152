<template>
    <!-- ヘッダ -->
    <template v-if="!headerless">
      <v-app-bar
          app
          :color="headerColor"
          :hide-on-scroll="hideOnScroll"
          :scroll-threshold="scrollThreshold"
          :density="density"
          :theme="headerTheme"
          :collapse="collapseHeader"
          :collapse-on-scroll="collapseHeader"
          :extension-height="extensionHeight"
          fixed
          :clipped-left="!naviAboveHeader">

        <template v-if="!permanentNavi && !disableToggleNavi">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"/>
        </template>

        <!-- 具体的なヘッダの中身はheaderスロットで記述 -->
        <slot name="header"></slot>

        <!-- ヘッダ拡張 -->
        <template v-if="showHeaderExtension" v-slot:extension>
          <slot name="header-extension"></slot>
        </template>

      </v-app-bar>
    </template>

    <!-- ナビゲーション -->
    <template v-if="!naviless">
      <v-navigation-drawer
          app
          :theme="naviTheme" 
          :width="naviWidth"
          :rail-width="naviWidth"
          :permanent="permanentNavi"
          :clipped="!naviAboveHeader"
          :color="naviColor"
          v-model="drawer"
          v-model:rail="rail"
          @update:rail="drawer = $event">

        <!-- 具体的なナビの中身はnaviスロットで記述 -->
        <slot name="navi"></slot>

      </v-navigation-drawer>
    </template>

    <!-- 具体的なコンテンツはcontentスロットで記述 -->
    <v-main :class="contentClass">
      <slot name="content">
        <router-view/>
      </slot>
    </v-main>

    <!-- フッタ -->
    <template v-if="!footerless">
      <v-footer
          app
          :theme="footerTheme"
          :color="footerColor"
          :inset="naviAboveFooter"
          :height="footerHeight">

        <!-- 具体的なフッタの中身はfooterスロットで記述 -->
        <slot name="footer"></slot>

      </v-footer>
    </template>
</template>

<script>
import Wrap from '../common/Wrap.vue';

/**
 * 基本的なフレームの構造を提供する。
 * サポートしていないフレーム構造は自前で作ってください。
 *
 *   naviless:
 *     ナビゲーションが不要な場合はtrue
 *
 *   headerless:
 *     ヘッダが不要な場合はtrue
 *
 *   footerless:
 *     フッタが不要な場合はtrue
 *
 *   navi-above-header:
 *     ナビゲーションをヘッダよりも上に位置する場合はtrue
 *
 *   navi-above-footer:
 *     ナビゲーションをヘッダよりも上に位置する場合はtrue
 *
 *   navi-theme:
 *     ナビゲーションをdarkモードにする場合はdark
 *
 *   header-theme:
 *     ヘッダをdarkモードにする場合はdark
 *
 *   footer-theme:
 *     フッタをdarkモードにする場合はdark
 *
 *   header-color:
 *     ヘッダの色
 *
 *   navi-color:
 *     ナビゲーションの色
 *
 *   footer-color:
 *     フッタの色
 *
 *   content-class:
 *     v-contentタグに指定するクラス
 *
 *   permanent-navi:
 *     小さい画面サイズでもナビゲーションを非表示にしない場合はtrue
 *
 *   disable-toggle-navi:
 *     ナビゲーションの表示・非表示をトグルするボタンを表示しない場合はtrue
 *
 *   collapse-header:
 *     スクロール時にヘッダを縮小表示する場合はtrue
 *
 *   hide-on-scroll:
 *     スクロール時にヘッダを非表示にする場合はtrue
 *
 *   density:
 *     ヘッダの高さを小さくする場合はcomfortable、全体的に小さくする場合はcompact
 *
 *   footer-height:
 *     フッタの高さを指定
 *
 *   extension-height:
 *     ヘッダ拡張部の高さを指定
 */
export default {
  "components": {Wrap},
  data() {
    return {
      "drawer": true,
      "showHeaderExtension": false,
      "rail": false,
    }
  },
  "props": {
    "naviless": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "headerless": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "footerless": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "naviAboveHeader": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "naviAboveFooter": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "naviTheme": {
      "type": String,
      "required": false,
      "default": () => undefined
    },
    "headerTheme": {
      "type": String,
      "required": false,
      "default": () => undefined
    },
    "footerTheme": {
      "type": String,
      "required": false,
      "default": () => undefined
    },
    "headerColor": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "naviColor": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "footerColor": {
      "type": String,
      "required": false,
      "default": () => null
    },
    "contentClass": {
      "type": [String, Array, Object],
      "required": false,
      "default": () => 'bg-grey-lighten-4 mb-12'
    },
    "naviWidth": {
      "type": [Number, String],
      "required": false,
      "default": () => 256
    },
    "permanentNavi": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "disableToggleNavi": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "collapseHeader": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "hideOnScroll": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "scrollThreshold": {
      "type": [Number, String],
      "required": false,
      "default": () => 250
    },
    "density": {
      "type": String,
      "required": false,
      "default": () => 'default'
    },
    "footerHeight": {
      "type": [Number, String],
      "required": false,
      "default": () => 'auto'
    },
    "extensionHeight": {
      "type": [Number, String],
      "required": false,
      "default": () => '48'
    },
  },
  mounted() {
    if (this.$slots['header-extension'] != null) {
      this.showHeaderExtension = true;
    }
  }

}
</script>
