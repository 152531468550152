<template>
  <v-menu
    v-model="show"
    ref="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    min-width="290px"
  >
    <template v-slot:activator="{ props }">
      <v-text-field
        v-model="formattedDate"
        :class="classList"
        readonly
        ref="textRef"
        :disabled="disabled"
        :clearable="clearable"
        :density="density"
        :flat="flat"
        :hide-details="hideDetails"
        :hint="hint"
        :label="label"
        :persistent-hint="persistentHint"
        :placeholder="placeholder"
        :prefix="prefix"
        :rounded="rounded"
        :single-line="singleLine"
        :suffix="suffix"
        :rules="textRules"
        :append-inner-icon="appendInnerIcon"
        :prepend-inner-icon="prependInnerIcon"
        :variant="variant"
        v-bind="convertEvent(props)"
        @click:clear="onClickClear"
        @click:appendInner="!readonly && props.onClick($event)"
        @click:prependInner="!readonly && props.onClick($event)"
      >
      </v-text-field>
    </template>
    <div>
    <!-- 1つの日を選択 -->
    <template v-if="!range && !multiple">
      <v-locale-provider :locale="locale">
      <v-date-picker
        ref="datePicker"
        v-model="pickerValue"
        v-model:year="pickerYear"
        v-model:month="pickerMonth"
        :allowed-dates="allowedDates"
        :max="max"
        :min="min"
        @update:modelValue="show = false"
        @click:cancel="onCancel"
        :day-format="date => new Date(date).getDate()"
        :readonly="readonly"
        hide-actions
      >
        <template #title></template>
      </v-date-picker>
      </v-locale-provider>
    </template>
    
    <!-- 日の範囲指定 -->
    <template v-else-if="range">
      <v-locale-provider :locale="locale">
      <v-date-picker
        ref="datePicker"
        v-model="pickerValue"
        v-model:year="pickerYear"
        v-model:month="pickerMonth"
        range
        :allowed-dates="allowedDates"
        :max="max"
        :min="min"
        :day-format="date => new Date(date).getDate()"
        :readonly="readonly"
        show-adjacent-months
      >
        <template #title></template>
        <template #actions>
        <wrap of="v-btn" class="ml-auto" @click="show = false; menu.save(pickerValue);">OK</wrap>
        <wrap of="v-btn" secondary  @click="onCancel">Cancel</wrap>
        </template>
      </v-date-picker>
      </v-locale-provider>
    </template>
    
    <!-- 複数選択 -->
    <template v-else-if="multiple">
      <v-locale-provider :locale="locale">
      <v-date-picker
        ref="datePicker"
        v-model="pickerValue"
        v-model:year="pickerYear"
        v-model:month="pickerMonth"
        multiple
        :allowed-dates="allowedDates"
        :max="max"
        :min="min"
        :day-format="date => new Date(date).getDate()"
        :readonly="readonly"
        show-adjacent-months
      >
        <template #title></template>
        <template #actions>
        <wrap of="v-btn" class="ml-auto" @click="show = false; menu.save(pickerValue);">OK</wrap>
        <wrap of="v-btn" secondary @click="onCancel">Cancel</wrap>
        </template>
      </v-date-picker>
      </v-locale-provider>
    </template>
    </div>
  </v-menu>
</template>

<script>
  /**
   * 日付選択フィールド。
   *   [プロパティ]
   *     modelValue:    選択された日付。
   *                    単一日選択時は文字列、複数日選択時は配列を返す。
   *                    日付の書式はfromIsoDateで指定する(デフォルトはyyyy/MM/dd)。
   *     disabled:      無効化する。
   *     rules:         入力値のバリデーションチェックのルールを指定する。
   *     allowedDates:  引数文字(yyyy-MM-dd)が選択可能かどうか(boolean)を返す関数を指定する。
   *     max:           選択可能な日の上限をyyyy-MM-dd形式の文字列で設定する。
   *     min:           選択可能な日の下限をyyyy-MM-dd形式の文字列で設定する。
   *     locale:        表示ロケールを設定。
   *     range:         範囲指定可能にする。
   *     multiple:      複数選択可能にする。
   *     toIsoDate:     テキストボックスに表示する日付の書式をiso8601書式に変換する関数を指定する。
   *     fromIsoDate:   iso8601書式の日付をテキストボックスに表示する日付に変換する関数を指定する。
   *
   *     テキストボックスに対する次のプロパティも利用可能
   *     append-inner-icon、clearable、density、hide-details、hint、label、prepend-inner-icon、persistent-hint、placeholder
   *     prefix、rounded、single-line、suffix、variant
   *
   *   [利用方法]
   *     以下のように利用します。
   *       <date-picker-field v-model="date1"          :rules="xxxx"></date-picker-field>
   *       <date-picker-field v-model="date2" range    :rules="xxxx"></date-picker-field>
   *       <date-picker-field v-model="date3" multiple :rules="xxxx"></date-picker-field>
   *
   *     バインドされる値とテキストボックスに表示される値は以下のようになります。
   *
   *       種類      バインドされる値の例           テキストボックスに表示される値の例
   *      ---------- -----------------------------  ------------------------------------
   *       なし       "2019/01/01"                   "2019/01/01"
   *       range      ["2019/01/01", "2019/02/01"]   "2019/01/01～2019/02/01"
   *       multiple   ["2019/01/01", "2019/02/01"]   "2019/01/01、2019/02/01"
   *
   *     rulesプロパティはテキストボックスに設定されるので配列に対するチェック処理ではなく
   *     "～"区切りまたは"、"区切りの文字列に対するチェック処理を設定してください。
   *
   *   [その他]
   *     一般的に使われそうなプロパティのみサポートしています。
   *     vuetifyのv-date-pickerはイベント日の設定や色の変更などもサポートしているので
   *     必要に応じてプロパティを追加してください。
   * 
   */
  import { computed, watch, watchEffect, ref, nextTick, onMounted } from 'vue';
  import dateUtils from '@/scripts/utils/dateUtils.js';
  import vueUtils from '@/scripts/utils/vueUtils.js';
  
  // propsからthisを参照できないので外でデフォルト関数を定義
  const toIsoDateDefault = (dateStr) => {
    if (!dateStr) {
      return null;
    }
    return dateUtils.parseDate(dateStr, 'uuuu/MM/dd');
  };
  
  const fromIsoDateDefault = (dateStr) => {
    if(!dateStr) {
      return null;
    }
    return dateUtils.formatDate(dateStr, 'uuuu/MM/dd');
  };

  export default {
    props: {
      modelValue:       {type: [String, Array], required: false, default: () => null},
      disabled:         {type: Boolean, required: false, default: () => false},
      readonly:         {type: Boolean, required: false, default: () => false},
      rules:            {type: Array, required: false, default: () => []},
      allowedDates:     {type: [Function, Array], required: false, default: () => () => true},
      max:              {type: String, required: false, default: () => undefined},
      min:              {type: String, required: false, default: () => undefined},
      locale:           {type: String, required: false, default: () => 'ja'},
      appendInnerIcon:  {type: String, required: false, default: () => undefined},
      prependInnerIcon: {type: String, required: false, default: () => undefined},
      range:            {type: Boolean, required: false, default: () => false},
      multiple:         {type: Boolean, required: false, default: () => false},
      toIsoDate:        {type: Function, required: false, default: toIsoDateDefault},
      fromIsoDate:      {type: Function, required: false, default: fromIsoDateDefault},
      clearable:        {type: Boolean, required: false, default: () => false},
      density:          {type: String, required: false, default: () => 'default'},
      flat:             {type: Boolean, required: false, default: () => false},
      hideDetails:      {type: [Boolean, String], required: false, default: () => false},
      hint:             {type: String, required: false, default: () => undefined},
      label:            {type: String, required: false, default: () => undefined},
      persistentHint:   {type: Boolean, required: false, default: () => false},
      placeholder:      {type: String, required: false, default: () => undefined},
      prefix:           {type: String, required: false, default: () => undefined},
      rounded:          {type: Boolean, required: false, default: () => false},
      singleLine:       {type: Boolean, required: false, default: () => false},
      suffix:           {type: String, required: false, default: () => undefined},
      variant:          {type: String, required: false, default: () => 'underlined'},
    },
    emits: ['update:modelValue'],
    setup(props, ctx) {
      // 変数の定義
      const show = ref(false);
      const formattedDate = ref(null);
      const pickerValue = ref(null);
      let prev = {};
      let isCanceling = false;
      const classList = ref([]);
      const menu = ref(null);
      const textRef = ref(null);
      const datePicker = ref(null);
      const pickerYear = ref();
      const pickerMonth = ref();

      // メソッド定義
      const valueToFromattedDate = (value) => {
        if (value == null) {
          return '';
        }
        if (!Array.isArray(value)) {
          return value;
        }
        if (props.range) {
          return value.join('～');
        } else {
          return value.join('、');
        }
      };

      const valueToIsoDate = (value) => {
        if (value == null) {
          return (props.range || props.multiple) ? [] : null;
        }
        if (!Array.isArray(value)) {
          return (props.range || props.multiple) ? [props.toIsoDate(value)] : props.toIsoDate(value);
        }
        return value.map(day => props.toIsoDate(day));
      };

      const isoDateToValue = (isoDate) => {
        if (isoDate == null) {
          if (props.range || props.multiple) {
            return [];
          }
          return null;
        }
        if (!Array.isArray(isoDate)) {
          return (props.range || props.multiple) ? [props.fromIsoDate(isoDate)] : props.fromIsoDate(isoDate);
        }
        return isoDate.map(props.fromIsoDate);
      };

      const isoDateToFormattedDate = (isoDate) => {
        if (isoDate == null) {
          return '';
        }
        if (!Array.isArray(isoDate)) {
          return props.fromIsoDate(isoDate);
        }
        if (props.range) {
          return isoDate.map(props.fromIsoDate).join('～');
        } else {
          return isoDate.map(props.fromIsoDate).join('、');
        }
      };

      const onCancel = () => {
        isCanceling = true;
        ctx.emit('update:modelValue', prev.value);
        pickerValue.value = prev.isoDate;
        formattedDate.value = prev.formattedDate;
        show.value = false;
        nextTick(() => isCanceling = false);
      };

      const onClickClear = () => {
        ctx.emit('update:modelValue', null);
      };

      const convertEvent = (activatorProps) => {
        const on = vueUtils.getHandler(activatorProps);
        const newOn = {};
        Object.entries(on)
              .forEach(([key, func]) => {
                newOn[key] = (...args) => {
                  if (!props.readonly) {
                    func(...args);
                  }
                }
              });
        return newOn;
      };

      watch(() => props.modelValue, (newVal, oldVal) => {
        if (isCanceling) {
          return;
        }
        // 空文字が設定された場合はnullに詰め替える
        if (newVal !== null && newVal.length === 0) {
          ctx.emit('update:modelValue', null);
          return;
        }
        formattedDate.value = valueToFromattedDate(newVal);
        pickerValue.value = valueToIsoDate(newVal);
      });

      watch(pickerValue, (newVal, oldVal) => {
        if (isCanceling) {
          return;
        }
        const newFormattedDate = isoDateToFormattedDate(newVal);
        if (newFormattedDate !== formattedDate.value) {
          formattedDate.value = newFormattedDate;
          ctx.emit('update:modelValue', isoDateToValue(newVal));
        }
      });

      watch(show, (newVal, oldVal) => {
        if (newVal === true) {
          // 値が設定されておらず当月が範囲外の月の場合は初期表示ページを範囲内の月に設定する
          // ※v-date-picker自身にこの動きが実装されたら不要(vuetify3.4.4時点では必要)
          if (pickerValue.value && pickerValue.value.length !== 0) {
            return;
          }
          const nowMonth = dateUtils.formatDate(new Date(), 'uuuu-MM');
          const minMonth = props.min ? props.min.substring(0, 7) : nowMonth;
          const maxMonth = props.max ? props.max.substring(0, 7) : nowMonth;
          const newMonth = (nowMonth < minMonth) ? minMonth : (maxMonth < nowMonth) ? maxMonth : null;
          if (newMonth) {
            pickerYear.value = parseInt(newMonth.substring(0, 4));
            pickerMonth.value = parseInt(newMonth.substring(5, 7)) - 1;
          }
        }
      });

      watchEffect(() => {
        classList.value = menu.value?.class?.split(' ').filter(clazz => clazz !== 'v-menu') || classList.value;
      });

      onMounted(() => {
        isCanceling = false;

        // // vuetify@3.3.11時点で、activatorElが設定されないことによりv-date-pickerの位置が正しく計算されないため、強制的に設定する(暫定)
        // const menuSeq = menu.value.activatorProps['aria-owns'].split('-').slice(-1)[0];
        // menu.value.activatorEl = document.querySelector(`#input-${parseInt(menuSeq, 10) + 1}`);
        // console.log(`★menu`, menu)

        if (props.modelValue) {
          formattedDate.value = valueToFromattedDate(props.modelValue);
          pickerValue.value = valueToIsoDate(props.modelValue);
          return;
        }

        pickerValue.value = (props.range || props.multiple) ? [] : null;
      });

      const textRules = computed(() => {
        return props.rules.map(rule => {
          return (val) => {
            if (props.range) {
              val = val ? val.split('～') : [];
            } else if (props.multiple) {
              val = val ? val.split('、') : [];
            }
            return rule(val);
          };
        });
      });

      ctx.expose({
        validity ()               { return textRef.value.validity; },
        error ()                  { return textRef.value.error; },
        errorMessages ()          { return textRef.value.errorMessages; },
        reset (...args)           { return textRef.value.reset(...args); },
        resetValidation (...args) { return textRef.value.resetValidation(...args); },
        async validate (...args)  { return await textRef.value.validate(...args); },
      });

      return {
        show,
        formattedDate,
        pickerValue,
        classList,
        onCancel,
        onClickClear,
        convertEvent,
        textRules,
        menu,
        textRef,
        pickerYear,
        pickerMonth,
        datePicker,
      };
    },
  }
</script>
<style scoped>
:deep(.v-field__prepend-inner) {
  margin-left: -4px;
  margin-right: -12px;
}
</style>