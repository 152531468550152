
/**
 * 必要に応じて以下のように自動生成ソースを書き換える。
 *
 * import enums from './enumsGenerated.js';
 * const addOrChangeEnum = new enums.EnumHandler([
 *   'ITEM1:項目1:1',
 *   'ITEM2:項目2:2',
 * ])
 * export * from './enumsGenerated.js';
 * export {
 *   addOrChangeEnum,     ← 変更の場合は上書きされる
 * }
 * export default {
 *   ...enums,
 *   addOrChangeEnum,     ← 変更の場合は上書きされる
 * };
 *
 */

import enums from './enumsGenerated.js';
export * from './enumsGenerated.js';
export default {
  ...enums,
}

