// components配下のコンポーネントをグローバル登録する
import {router, store, vuetify} from '@/plugins';
import App from '@/views/App';
import { createApp } from 'vue';
import components from '@/components';
import envUtils from '@/scripts/utils/envUtils.js';

const Vue = createApp(App);
Vue.use(router);
Vue.use(store);
Vue.use(vuetify);

Object.entries(components).forEach(([
                                        name,
                                        component
                                    ]) => Vue.component(name, component));

router.isReady().then(() => Vue.mount('#app'));


// vueの設定
const isDev = envUtils.isDevelopEnv();
if (isDev) {
    Vue.config.errorHandler = (err, vm, info) => {
        console.error(err, info);
        // 必要に応じでコメントを外す
        // debugger;
    };
    Vue.config.warnHandler = (msg, vm, trace) => {
        console.warn(msg, trace);
        // 必要に応じでコメントを外す
        // debugger;
    };
}

