<script>
  /**
   * 各コンポーネントをラップするコンポーネント。
   * 以下のように利用する。
   *
   *   <wrap of="v-text-field" v-model="text1" />                            テキストフィールドが表示される
   *   <wrap of="radio" :items="[{text: "a", value: 0}]" v-model="radio1" /> ラジオボタンが表示される
   *   <wrap of="v-checkbox" v-model="check1" />                             チェックボックスが表示される
   *   <wrap of="v-btn" @click="onClick">ボタン</wrap>                       ボタンが表示される
   *
   * wrapタグはコンポーネントごとにデフォルトの属性を自動設定するため
   * コンポーネントを配置するたびに属性を指定する必要がなくなる。
   * デフォルトの属性は「scripts/common/wrap.js」で設定する。
   * 
   * また、v-bind属性と同じ設定をwith属性で指定できる。
   * witdh属性部分を自動生成することで仕様に沿ったチェックが行える。
   *
   *   <wrap of="v-text-field" maxlength="8" :rules="[isNotEmpty, isLargerLength(3)]" />            // 下と同じ
   *   <wrap :with="{of: 'v-text-field', maxlength: 8, rules: [isNotEmpty, isLargerLength(3)]}" />  // 上と同じ
   *   <wrap :with="autoGenParam" />                                                                // 自動生成を取り込むことで上と同じになる
   *
   * プロパティの優先順位は以下となる
   *   with属性の値 < 直接記載された属性 < wrap.jsの変更内容 < force-with属性の値
   *   (ただし、rulesは直接記載すると上書きではなくwith属性のrulesに追加する動きとなる)
   *
   * 例：
   *   const param = {of: 'v-text-field', maxlength: 8, rules: [isNotEmpty]};
   *   <wrap :with="param" of="v-textarea" />               // 最大桁数8の必須入力テキストエリアが表示される
   *   <wrap :with="param" maxlength=10 />                  // 最大桁数10の必須入力テキストフィールドが表示される
   *   <wrap :with="param" :rules="[isLargerLength(3)]" />  // 3桁から8桁まで入力可能な必須入力テキストフィールドが表示される
   *   <wrap :with="param" :force-with="{rules:[]}" />      // 最大桁数8のチェックなしテキストフィールドが表示される
   * 
   */
  /*
   * 関数コンポーネントの作り方は以下を参照
   * https://v3-migration.vuejs.org/breaking-changes/functional-components.html
   */
  import {h, mergeProps} from 'vue';
  import wrap from '@/scripts/common/wrap.js';
  import {
    VCheckbox,
    VFileInput,
    VSelect,
    VTextarea,
    VTextField,
    VBtn,
  } from 'vuetify/components';
  import {
    DatePicker,
    FileDrop,
    Radio,
    TimePicker,
  } from '@/components/form';
  
  const tagMap = {
    'v-checkbox':   {wrap: wrap.wrapCheckbox,   component: VCheckbox},
    'v-file-input': {wrap: wrap.wrapFileInput,  component: VFileInput},
    'v-select':     {wrap: wrap.wrapSelect,     component: VSelect},
    'v-textarea':   {wrap: wrap.wrapTextArea,   component: VTextarea},
    'v-text-field': {wrap: wrap.wrapTextField,  component: VTextField},
    'v-btn':        {wrap: wrap.wrapButton,     component: VBtn},
    'date-picker':  {wrap: wrap.wrapDatePicker, component: DatePicker},
    'file-drop':    {wrap: wrap.wrapFileDrop,   component: FileDrop},
    'radio':        {wrap: wrap.wrapRadio,      component: Radio},
    'time-picker':  {wrap: wrap.wrapTimePicker, component: TimePicker},
  };
  
  const propsDefinition = {
      of:        {type: String, required: false, default: () => undefined},
      with:      {type: Object, required: false, default: () => ({of: 'v-text-field'})},
      forceWith: {type: Object, required: false, default: () => ({})},
  };

  const Wrap = (props, ctx) => {
      const tagName = props.of || props.with.of;
      if (!Object.keys(tagMap).includes(tagName)) {
        throw new Error(`タグの指定が不正です。${tagName}`);
      }

      // 複数のルールをまとめる関数
      const joinRules = (...rulesArr) => {
        const validRulesArr = rulesArr.filter(Array.isArray);
        return [].concat(...validRulesArr);
      }

      // withをコピー
      let wrapProps = {...props.with};

      // withの値に対してof属性を削除し、直接定義されているclass、style、rules、その他属性をマージ
      const {of, rules, ...attrProps} = ctx.attrs;
      wrapProps.rules = joinRules(props.with.rules, rules);
      wrapProps = mergeProps(wrapProps, attrProps);

      // マージされた場合、なぜかctxに反映しないとwith側のrulesが動かないための措置 (vue 3.3.8, vuetify 3.4.2時点)
      if (props.with?.rules?.length > 0 && ctx.attrs.rules?.length > 0) {
        ctx.attrs.rules = wrapProps.rules;
      }

      // wrap処理を適用
      tagMap[tagName].wrap.call(ctx, wrapProps);

      // 強制プロパティを適用(rulesは上書き)
      wrapProps = {...wrapProps, ...props.forceWith, ref: 'fieldRef'};

      // 要素を描画
      const wrapedElement = h(tagMap[tagName].component, wrapProps, ctx.slots);
      return wrapedElement;
  };
  Wrap.props = propsDefinition;

  // Vue3の関数コンポーネント形式だと$refsが取れなくなるので、Vue2形式に変換する(Composition APIに移行完了まで暫定)
  const V2Wrap = {
    functional: true,
    props: Wrap.props,
    render: (ctx) => Wrap(ctx.$.props, ctx.$),
  };
  export default V2Wrap;
</script>
