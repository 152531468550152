import { createRouter, createWebHistory } from 'vue-router';
import {envUtils} from '@/scripts/utils';
import {utils} from "@/scripts";

/**
 * ルーティング定義：
 *   <router-view> の下に画面を表示させる場合は子画面定義の parent に <router-view> を設置しているパスを指定してください。
 *   webpackChunkNameのコメントは該当画面にアクセスする際にその名前の単位で view 情報がダウンロードされます。
 *   ほとんど使われない画面は名前を変えておくことで初回の画面描画が軽くなります。
 *   name属性は記載していませんが、追加すれば名前で参照できるようになります。
 */
const routingList = [
    {
        "path": '/',
        "parent": null,
        // "meta": {"isPublic": true},
        // "component": () => import('@/views/Root.vue'),
    },
    {
        "path": '/login',
        "parent": null,
        "name": 'login',
        "meta": {"isPublic": true},
        "component": () => import('@/views/users/Login.vue'),
    },
    {
        "path": '/login/otp',
        "parent": null,
        "meta": {"isPublic": true},
        "component": () => import('@/views/users/OneTimePassword.vue'),
    },
    {
        "path": '/users/addAccountMail',
        "parent": null,
        "component": () => import('@/views/users/AddAccountMail.vue'),
        "name": 'addAccountMail',
        "meta": {"isPublic": true}
    },
    {
        "path": '/users/selectAccountType',
        "parent": null,
        "component": () => import('@/views/users/SelectAccountType.vue'),
        "name": 'selectAccountType',
        "meta": {"isPublic": true}
    },
    {
        "path": '/users/addAccount',
        "parent": null,
        "component": () => import('@/views/users/AddAccount.vue'),
        "meta": {"isPublic": true}
    },
    {
        "path": '/personalInfoAgree',
        "parent": null,
        "component": () => import('@/views/PersonalInfoAgree.vue'),
        "meta": {"isPublic": true}
    },
    {
        "path": '/faq',
        "parent": null,
        "component": () => import('@/views/Faq.vue'),
        "name": 'faq',
        "meta": {"isPublic": true}
    },
    {
        "path": '/main',
        "parent": '/',
        "component": () => import('@/views/Menu.vue'),
        "meta": {"isPublic": true}
    },
    {
        "path": '/top',
        "parent": '/',
        "component": () => import('@/views/Top.vue'),
        "name": 'top',
        "meta": {"isPublic": true}
    },
    {
        "path": '/main/orders/list',
        "parent": '/main',
        "component": () => import('@/views/orders/list/OrderList.vue')
    },
    {
        "path": '/main/orders/list/detail',
        "parent": '/main',
        "component": () => import('@/views/orders/list/OrderDetail.vue')
    },
    {
        "path": '/main/orders/list/detail/map',
        "parent": '/main',
        "component": () => import('@/views/orders/list/Map.vue')
    },
    {
        "path": '/main/orders/regist/selectFacility',
        "parent": '/main',
        "component": () => import('@/views/orders/regist/SelectFacility.vue')
    },
    {
        "path": '/main/orders/regist/form',
        "parent": '/main',
        "component": () => import('@/views/orders/regist/OrderForm.vue')
    },
    {
        "path": '/main/orders/regist/map',
        "parent": '/main',
        "component": () => import('@/views/orders/regist/Map.vue')
    },
    {
        "path": '/main/orders/regist/confirm',
        "parent": '/main',
        "component": () => import('@/views/orders/regist/OrderConfirm.vue')
    },
    {
        "path": '/main/orders/regist/complete',
        "parent": '/main',
        "component": () => import('@/views/orders/regist/OrderComplete.vue')
    },
    {
        "path": '/main/users/updateAccount',
        "parent": '/main',
        "component": () => import('@/views/users/UpdateAccount.vue')
    },
    {
        "path": '/users/password/reset/token',
        "parent": null,
        "component": () => import('@/views/users/ResetPassMail.vue'),
        "meta": {"isPublic": true}
    },
    {
        "path": '/users/password/reset',
        "name": 'resetPassword',
        "parent": null,
        "component": () => import('@/views/users/ResetPassword.vue'),
        "meta": {"isPublic": true}
    },
];

/**
 * ルーティング後の処理：
 *   画面遷移に関連する共通処理はここに記載してください。
 *
 */
const afterEach = (to, from) => {
    // 遷移時の離脱ログ用に、遷移後のrouteオブジェクトに遷移前routeを保存する
    to.meta.previousRoute = from;

    const toUrl = to.fullPath;
    if (toUrl === '/' || toUrl === '/login') {
        utils.clearToken();
    }
    // ログインページ以外でトークンを持っていない場合はトップページに強制移動
    // console.log(utils.getToken())
    // if (to.matched.some(record => !record.meta.isPublic) && to.path !== '/top' && utils.getToken() == null) {
    //     vuexUtils.moveTo('/top');
    // }
};


/** ********* 以下は基本的に編集不要 **************/

// ViewRouterのルート設定
const routes = [];
const pathRouteMap = {};
// パスの文字列の長さが小さいものを先に処理する
routingList.sort((item1, item2) => item1.path.length - item2.path.length)
    .forEach(item => {

        // 子の要素を作成
        let routerItem = {
            "path": item.path,
            "name": item.name,
            "component": item.component,
            "meta": item.meta,
        };

        // 親の指定がない場合はroutesにそのまま追加
        if (item.parent == null) {
            routes.push(routerItem);
            pathRouteMap[item.path] = routerItem;
            return;
        }

        // 親の指定があるのに親が定義されていない場合はエラー
        const parentItem = pathRouteMap[item.parent];
        if (parentItem == null) {
            throw new Error(`ルーティング定義失敗。${item.path} の親要素として指定されている ${item.parent} が定義されていません。`);
        }

        // パスの指定が親のパスの下にない場合はエラー
        const matchParentPath = item.parent + (item.parent === '/' ? '' : '/');
        if (!item.path.startsWith(matchParentPath)) {
            throw new Error(`ルーティング定義失敗。パス ${item.path} が親要素として指定されている ${matchParentPath} で始まっていません。`);
        }

        // 親の指定がある場合は親の子要素として追加するのでパスから親のパス部分は除外する
        routerItem.path = item.path.slice(matchParentPath.length);

        // 指定要素を親の子要素として追加
        if (parentItem.children == null) {
            parentItem.children = [];
        }
        pathRouteMap[item.path] = routerItem;
        parentItem.children.push(routerItem);
    });

// routerを作成
const router = createRouter({
    "mode": 'history',
    "history": createWebHistory(envUtils.getBasePath()),
    routes
});

// 画面遷移後の処理を設定
router.afterEach(afterEach);

// 画面遷移前の処理を設定
router.beforeEach(async (to, from) => {
    // リダイレクトされてきた場合、リダイレクト先指定をクリアする
    if (to.query && to.query.redirect && to.path !== '/') {
        const dist = to.query.redirect;
        delete to.query.redirect
        return {
            "name": `${dist}`,
            "query": {
                ...to.query,
                ...from.query
            }
        };
    }
    // ログインが必要なページに、ログインせずアクセスした場合はtopにリダイレクトする
    if (to.matched.every(record => !record.meta.isPublic) && to.path !== '/top') {
        return {
            "path": "/top",
            "query": {
                ...to.query,
                ...from.query
            }
        };
    }
});
export default router;
