<script>
/**
 * ラジオボタン。
 *   v-radio-groupのプロパティに加え、次のプロパティを使用可能
 *   [プロパティ]
 *     items:          配下に作成するv-radioのプロパティ。
 *     item-title:     items内のラベルにアクセスするキー。
 * 
 */
import {h} from 'vue';
import {VRadioGroup, VRadio} from 'vuetify/components';

export default {
  functional: true,
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemTitle: {
      type: String,
      required: false,
      default: () => 'label',
    },
  },
  render(ctx) {
    const context = ctx.$;

    // 子要素を生成
    const children = () => context.props.items.map(item => {
      const props = {
        label: item[context.props.itemTitle],
        value: item.value,
      };
      return h(VRadio, props);
    });

    return h(VRadioGroup, context.attrs, children);
  }
}
</script>
