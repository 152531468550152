/**
 * date-fnsのラップ関数。
 * 関数の呼び方やエラーハンドリングを共通化できるので
 * date-fnsを直接使わずにこちらに定義した関数を使うようにしてください。
 */

import fnsFormat from 'date-fns/format';
import fnsParse from 'date-fns/parse';

/**
 * Dateオブジェクトを指定書式の文字列に変換する。
 * 書式の指定がない場合は 'uuuu/MM/dd HH:mm:ss' になる
 *
 *  uuuu : 年
 *  MM   : 月
 *  dd   : 日
 *  HH   : 時(0-23)
 *  hh   : 時(0-12)
 *  a    : AM/PM
 *  mm   : 分
 *  ss   : 秒
 *  SSS  : ミリ秒
 *
 */
const formatDate = (date, format = 'uuuu/MM/dd HH:mm:ss') => fnsFormat(date, format);

/**
 * 文字列を指定書式でDateオブジェクトに変換する。
 * 以下の書式であれば未指定でもパースできる。
 *   uuuu/MM/dd
 *   uuuu/MM/dd HH:mm:ss
 */
const parseDate = (dateStr, format = null) => {
    if (format) {
        return fnsParse(dateStr, format, new Date());
    }
    if ((/^\d{4}\/\d{2}\/\d{2}$/).test(dateStr)) {
        return fnsParse(dateStr, 'uuuu/MM/dd', new Date());
    }
    if ((/^\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}$/).test(dateStr)) {
        return fnsParse(dateStr, 'uuuu/MM/dd HH:mm:ss', new Date());
    }
    throw new Error('parseDateの書式が指定されていません。');
};

/**
 * Dateオブジェクトに指定年を追加した日付を返す
 * date-fnsは引数のオブジェクト自体を更新するので自前で作成。
 */
const addYears = (date, amount) => {
    let day = new Date(date.getTime());
    day.setFullYear(day.getFullYear() + amount);
    return day;
};

/**
 * Dateオブジェクトに指定月を追加した日付を返す
 * date-fnsは引数のオブジェクト自体を更新するので自前で作成。
 */
const addMonths = (date, amount) => {
    let day = new Date(date.getTime());
    day.setMonth(day.getMonth() + amount);
    return day;
};

/**
 * Dateオブジェクトに指定月を削減した日付を返す
 * date-fnsは引数のオブジェクト自体を更新するので自前で作成。
 */
const subMonths = (date, amount) => {
    let day = new Date(date.getTime());
    day.setMonth(day.getMonth() - amount);
    return day;
};

/**
 * Dateオブジェクトに指定日を追加した日付を返す
 * date-fnsは引数のオブジェクト自体を更新するので自前で作成。
 */
const addDays = (date, amount) => new Date(date.getTime() + 1000 * 60 * 60 * 24 * amount);

/**
 * Dateオブジェクトに指定時間を追加した日付を返す
 * date-fnsは引数のオブジェクト自体を更新するので自前で作成。
 */
const addHours = (date, amount) => new Date(date.getTime() + 1000 * 60 * 60 * amount);

/**
 * Dateオブジェクトに指定分を追加した日付を返す
 * date-fnsは引数のオブジェクト自体を更新するので自前で作成。
 */
const addMinutes = (date, amount) => new Date(date.getTime() + 1000 * 60 * amount);

/**
 * Dateオブジェクトに指定秒を追加した日付を返す
 * date-fnsは引数のオブジェクト自体を更新するので自前で作成。
 */
const addSeconds = (date, amount) => new Date(date.getTime() + 1000 * amount);

/**
 * 引数に渡されたDateオブジェクトが日付順に並んでいる場合にtrueを返す。
 * isBefore()、isAfter()だとどちらの引数に対してbefore/afterなのかわかりにくいのでこちらを使う。
 * 末尾の引数がtrueの場合は同じ時刻を許容する(=trueとする)
 */
const isInOrder = (...dateList) => {

    let acceptSame = false;
    if (typeof dateList[dateList.length - 1] === 'boolean') {
        acceptSame = dateList.pop();
    }

    let prevDate = dateList.shift();
    for (const date of dateList) {
        const isOk = acceptSame ? prevDate.getTime() <= date.getTime() : prevDate.getTime() < date.getTime();
        if (!isOk) {
            return false;
        }
        prevDate = date;
    }
    return true;
};

/**
 * 指定された日付が平日か判定する
 * @param date Dateオブジェクト
 */
const isHoliDay = date => {
    let dayNum = date.getDay();
    if (dayNum === 0 || dayNum === 6) {
        return true;
    }
    return false;
};

/**
 * 営業日(土日祝日以外)を判定する
 * @param date Dateオブジェクト
 * @returns true: 営業日  false:土日祝日
 */
const isWorkDay = holidayList => date => {
    // 祝日リスト内に存在するかのチェック
    const dateString = formatDate(date, 'uuuu/MM/dd');
    if (holidayList.find(elm => elm === dateString)) {
        return false;
    }
    // 土日の判定
    if (isHoliDay(date)) {
        return false;
    }

    return true;
}

/**
 * 祝日API取得エラー時のデフォルト祝日リストを返す
 * @returns 祝日リスト
 */
const getDefaultHolidays = () => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDay());
    const holidayList = [];
    const addToHolidayList = (dateObj, days) => {
        for (let i = 0; i < days; i++) {
            if (today <= dateObj) {
                holidayList.push(formatDate(dateObj, 'uuuu/MM/dd'));
            }
            dateObj = addDays(dateObj, 1);
        }
    }
    addToHolidayList(new Date(now.getFullYear(), 0, 1), 3); // 今年の1/1～1/3
    addToHolidayList(new Date(now.getFullYear(), 11, 29), 6); // 今年の12/29～来年の1/3
    return holidayList;
}

export {
    formatDate,
    parseDate,
    addYears,
    addMonths,
    addDays,
    addHours,
    addMinutes,
    addSeconds,
    subMonths,
    isInOrder,
    isHoliDay,
    isWorkDay,
    getDefaultHolidays,
}

export default {
    formatDate,
    parseDate,
    addYears,
    addMonths,
    addDays,
    addHours,
    addMinutes,
    addSeconds,
    subMonths,
    isInOrder,
    isHoliDay,
    isWorkDay,
    getDefaultHolidays,
}
