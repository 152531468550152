<template>
  <v-dialog ref="alertDialog" v-model="show" persistent :max-width="maxWidth">
    <v-card>
      <v-card-title class="pt-2 pb-2 bg-blue-darken-3"><span class="white--text">{{ dialogTitle }}</span></v-card-title>
      <v-card-text class="text-black">
        <v-row class="pt-3">
          <!-- iconの指定があれば3:9に分割、指定がなければメッセージのみ表示 -->
          <v-col :cols="icon == null ? false : 3" v-show="icon">
            <v-avatar color="yellow-darken-3" size="50">
              <v-icon size="40" color="white" icon="fa:fas fa-question"></v-icon>
            </v-avatar>
          </v-col>
          <v-col :cols="icon == null ? 12 : 9">
            <span v-html="dialogMessage" style="font-size: 13.5px;"></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <wrap of="v-btn" height="36" @click="close(true)">OK</wrap>
        <wrap of="v-btn" height="36" secondary @click="close(false)" ref="cancel">キャンセル</wrap>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

  /**
   * 確認ダイアログ。
   *   [プロパティ]
   *     value:         trueの場合はダイアログ表示、falseの場合は非表示。
   *     title:         確認ダイアログのタイトル文字列。
   *     message:       確認ダイアログのメッセージ文字列。
   *     max-width:     ダイアログの横幅。
   *     icon:          trueの場合はアイコンを表示する。
   *     dialog-extend: dialogインスタンスにconfirmメソッドを追加する。利用方法の説明を参照。
   *
   *   [イベント]
   *     close(isOk): ダイアログがクローズされた場合に発火。
   *       isOk:  true(OKボタン)またはfalse(キャンセルボタン)。
   *
   *   [メソッド]
   *     showDialog(title, message)
   *       title:   確認ダイアログのタイトル文字
   *       message: 確認ダイアログのメッセージ文字列。
   *
   *     showDialog(messageObj, params)
   *       messageObj.title: 確認ダイアログのタイトル文字。
   *       messageObj.msg:   確認ダイアログのメッセージ文字列。
   *       params:           メッセージ文字の置換パラメータ配列。
   *
   *   [利用方法]
   *      以下の2つの利用方法をサポートしています。
   *         1. 普通のコンポーネントとして
   *            <template>
   *              <confirm-dialog ref="refConfirm" v-model="isShow"></confirm-dialog>
   *              <button @click="onclick">ダイアログ表示<button>
   *            </template>
   *            <script>
   *              export default {
   *                data () {
   *                  return {isShow: false};
   *                },
   *                methods: {
   *                  onclick () {
   *                    this.isShow = true;
   *                    // this.$refs.refAlert.showDialog("タイトル", "メッセージ").then(() => console.log('ダイアログクローズ'))
   *                  }
   *                }
   *              }
   *            ＜/script>
   *         2. Dialog拡張用として
   *           [親画面]
   *             <template>
   *               <confirm-dialog dialog-extend></confirm-dialog>
   *               <router-view></router-view>
   *             </template>
   *           [子画面]
   *             <script>
   *               import dialog from '@/scripts/dialog.js'
   *               export default {
   *                 methods: {
   *                   onclick () {
   *                     // 任意のソースからダイアログを呼び出せる
   *                     dialog.confirm({title: "タイトル", msg: "{}が{}です"}, 'ユーザ名', '桁数不正').then(() => console.log('ダイアログクローズ'))
   *                   }
   *                 }
   *               }
   *             ＜/script>
   *  [その他]
   *    アイコンの変更や色の変更などの細かい修正は外部から変更できるように作っていないので
   *    各プロジェクトごとにこのコンポーネントを直接編集してください。
   *
   */
  import {dialog, stringUtils} from '@/scripts/utils';
  export default {
    data () {
      return {
        show: false,
        dialogTitle: '',
        dialogMessage: '',
        result: false,
      }
    },
    props: {
      value:           {type: Boolean, required: false, default: () => false},
      title:           {type: String,  required: false, defalut: () => 'エラー'},
      message:         {type: String,  required: false,  default: () => ''},
      'max-width':     {type: String,  required: false, default: () => "440"},
      icon:            {type: Boolean, required: false, default: () => true},
    },
    watch: {
      value: function(newVal, oldVal) {
        this.show = newVal;
      },
      title: function(newVal, oldVal) {
        this.dialogTitle = newVal;
      },
      message: function(newVal, oldVal) {
        this.dialogMessage = newVal;
      },
      show: function(newVal, oldVal) {
        if (newVal) {
          // 画面が表示されたらキャンセルボタンにフォーカスを当てる
          const that = this;
          setTimeout(() => that.$refs.cancel.$el.focus(), 50);
        }
      },
    },
    methods: {
      // utilsから呼び出すときの関数名
      getFuncName () {
        return 'confirm';
      },
      close (isOk) {
        this.show = false;
        this.$emit('input', false);
        this.$emit('close', isOk);
        this.result = isOk;
      },
      open (arg1, arg2) {
        // 引数が文字列でもオブジェクトでも処理できるようにする
        if (typeof arg1 === 'string') {
          this.dialogTitle = arg1;
          this.dialogMessage = arg2;
        } else {
          this.dialogTitle = arg1.title;
          this.dialogMessage = (arg2 == null) ? arg1.msg :
                               Array.isArray(arg2) ? stringUtils.createMessage(arg1.msg, ...arg2)
                                                   : stringUtils.createMessage(arg1.msg, arg2);
        }
        this.show = true;
        const that = this;
        return new Promise(resolve => {
          const unwatch = that.$watch(
            () => that.show,
            () => {
              unwatch();
              resolve(that.result);
            }
          );
        })
      }
    }
  }
</script>
