import BasicFrame from './BasicFrame';
import NaviList from './NaviList';

export {
  BasicFrame,
  NaviList,
}

export default {
  BasicFrame,
  NaviList,
}
