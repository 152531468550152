const config = {
    "MAX_FILE_SIZE": 5,
    "MAX_FILE_COUNT": 30,
    "MAX_FILE_NAME_SIZE": 64,
    "MAX_RANGE_SIZE": 3000,
    "FILE_EXTENSIONS": [
        '.jpg',
        '.jpeg',
        '.pdf'
    ],
    "MAP_INI_RANGE": {
        "northEast": [
            45.522778,
            145.816278
        ],
        "southWest": [
            24.049806,
            122.933611
        ]
    }
};

const leafletMsg = {
    "DRAW_ACTIONS_TITLE": '編集中の工事範囲を取消します',
    "DRAW_ACTIONS_TEXT": '取消',
    "DRAW_FINISH_TITLE": '編集中の工事範囲を適用します',
    "DRAW_FINISH_TEXT": '適用',
    "DRAW_UNDO_TITLE": '1つ前に追加した工事範囲の中間地点を削除します',
    "DRAW_UNDO_TEXT": '元に戻す',
    "DRAW_BUTTONS_POLYGON": '工事範囲を追加します',
    "DRAW_POLYGON_START": '工事範囲の起点をクリックしてください',
    "DRAW_POLYGON_CONT": '工事範囲の中間地点をクリックしてください',
    "DRAW_POLYGON_END": '工事範囲の起点をクリックすると範囲指定を終了します',
    "EDIT_ACTIONS_SAVE_TITLE": '変更を適用します',
    "EDIT_ACTIONS_SAVE_TEXT": '適用',
    "EDIT_ACTIONS_CANCEL_TITLE": '変更を取消します',
    "EDIT_ACTIONS_CANCEL_TEXT": '取消',
    "EDIT_ACTIONS_CLEARALL_TITLE": 'すべての工事範囲を削除します',
    "EDIT_ACTIONS_CLEARALL_TEXT": '全削除',
    "EDIT_BUTTONS_EDIT": '工事範囲を編集します',
    "EDIT_BUTTONS_EDITDISABLED": '編集対象の工事範囲が存在しません',
    "EDIT_BUTTONS_REMOVE": '工事範囲を削除します',
    "EDIT_BUTTONS_REMOVEDISABLED": '削除対象の工事範囲が存在しません',
    "EDIT_EDIT_TEXT": '四角を移動して工事範囲を変更し、適用ボタンを押下してください',
    "EDIT_EDIT_SUBTEXT": '編集前に戻すには取消ボタンを押下してください',
    "EDIT_REMOVE_TEXT": '削除対象の工事範囲をクリックしてください',
    "DRAW_ERROR": '<strong>エラー:</strong> 3点以上の頂点を指定してください',
};

// const resultCode = {
//     SUCCESS: '0000',
//     VALIDATION_NG: '1001',
// };

const errorCode = {
    "REQUIRED": '1000',
    "COUNT_NG": '1001',
    "WIDTH": '1002',
    "HALF_KANA": '1003',
    "HALF_NUM": '1004',
    "DATE_FORMAT": '1005',
    "SCHEDULE": '1006',
    "FILE_COUNT": '1007',
    "FILE_SIZE": '1008',
};

const role = {
    "CUSTOMER": 0,
    "OPERATOR": 1,
};


export {config};
export {leafletMsg};
// export {resultCode};
export {errorCode};
export {role};

export default {
    config,
    leafletMsg,
    // resultCode,
    errorCode,
    role,
}

