<template>
  <div ref="simpleSvg" :style="svgStyle">
    <slot/>
  </div>
</template>

/**
* 線で表現できる図形をSVGで描画する。
* 使用例
*   ＜div class="d-flex"＞
*     ＜simple-svg class="flex-grow-1" type="homeBase" /＞  指定要素に幅が存在すること
*     ＜simple-svg class="flex-grow-1" type="mountain" /＞
*   ＜/div＞
*
* typeで指定できる図形の種類は以下が存在する(Excelのオートシェイプの名前で登録している)。
*
*   homeBase : ホームベース型
*   mountain : 山型
*
* 頂点の位置を[x, y]の配列で指定することで描画しているので、
* 異なる図形を作る場合や既存の図形を変更する場合はdataの定義を変更すること。
*/
<script>
import vueUtils from '@/scripts/utils/vueUtils';

// 頂点の配列 [[x1, y1], [x2, y2], ...]
const types = {
  "homeBase": [
    [
      0,
      0
    ],
    [
      120,
      0
    ],
    [
      150,
      25
    ],
    [
      120,
      50
    ],
    [
      0,
      50
    ],
    [
      0,
      0
    ]
  ],
  "mountain": [
    [
      0,
      0
    ],
    [
      30,
      25
    ],
    [
      0,
      50
    ],
    [
      120,
      50
    ],
    [
      150,
      25
    ],
    [
      120,
      0
    ],
    [
      0,
      0
    ]
  ],
};

export default {
  "props": {
    // 図形の種類
    "type": {
      "type": String,
      "required": true
    },
    // 線の色 色なしはnone
    "stroke": {
      "type": String,
      "required": false,
      "default": () => 'black'
    },
    // 線の幅
    "strokeWidth": {
      "type": [String, Number],
      "required": false,
      "default": () => 2
    },
    // 塗りつぶしの色 色なしはnone
    "fill": {
      "type": String,
      "required": false,
      "default": () => 'none'
    },
    // 塗りつぶしの透過度(0～1)
    "fillOpacity": {
      "type": [String, Number],
      "required": false,
      "default": () => 1.0
    },
    // 幅
    "width": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    // 最小幅
    "minWidth": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    // 最大幅
    "maxWidth": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    // 高さ
    "height": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    // 最小の高さ
    "minHeight": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    // 最大の高さ
    "maxHeight": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    // 横方向に空きスペースがあれば拡大する(途中で変更不可)(flex-growでの使用を想定)
    "fitWidth": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
  },
  data() {
    return {
      "watchEndFn": null,
    }
  },
  "computed": {
    points() {
      return this.type ? types[this.type] : [];
    },
    scaledPoints() {
      const xMin = Math.min(...this.points.map(([first]) => first));
      const yMin = Math.min(...this.points.map(([, second]) => second));
      return this.points.map(([
                                first,
                                second
                              ]) => [
        first - xMin,
        second - yMin
      ]);
    },
    shapeWidth() {
      return Math.max(...this.scaledPoints.map(([first]) => first));
    },
    shapeHeight() {
      return Math.max(...this.scaledPoints.map(([, second]) => second));
    },
    svgStyle() {
      const width = `${this.shapeWidth}px`;
      const height = `${this.shapeHeight}px`;
      const version = '1.1';
      const xmlns = 'http://www.w3.org/2000/svg';
      const elemD = this.points.map(([
                                       elemX,
                                       elemY
                                     ], index) => `${index === 0 ? 'M' : 'L'}${elemX} ${elemY}`).join(' ');
      const tagInfo = `<svg ` +
          `width='${width}' ` +
          `height='${height}' ` +
          `version='${version}' ` +
          `xmlns='${xmlns}' ` +
          `>` +
          `<path ` +
          `d='${elemD}' ` +
          `stroke='${this.stroke}' ` +
          `stroke-width='${this.strokeWidth}' ` +
          `fill='${this.fill}' ` +
          `fill-opacity='${this.fillOpacity}' ` +
          `/>` +
          `</svg>`;

      return {
        'background-image': `url("data:image/svg+xml;utf8,${tagInfo}")`,
        'background-size': 'contain',
        ...this.toPixelObj('width', this.width),
        ...this.toPixelObj('min-width', this.minWidth),
        ...this.toPixelObj('max-width', this.maxWidth),
        ...this.toPixelObj('height', this.height),
        ...this.toPixelObj('min-height', this.minHeight),
        ...this.toPixelObj('max-height', this.maxHeight),
      };
    }
  },
  "methods": {
    toPixelObj(name, val) {
      if (!val) {
        return {};
      }
      if (typeof val !== 'string') {
        return {[name]: val + 'px'};
      }
      const isNumVal = Array.from(val).every(char => '0123456789.'.includes(char));
      if (isNumVal) {
        return {[name]: val + 'px'};
      }
      return {[name]: val};
    },
    watchResize(elm) {
      const divStyle = window.getComputedStyle(elm, null);
      const divWidth = parseInt(divStyle.width.replace(/px/, ''), 10);
      const divHeight = parseInt(divStyle.height.replace(/px/, ''), 10);
      const shapeRatio = this.shapeWidth / this.shapeHeight;
      const divRatio = divWidth / divHeight;
      if (shapeRatio + 0.1 < divRatio) {
        elm.style.height = parseInt(divHeight * divRatio / shapeRatio, 10) + 'px';
      }
    }
  },
  mounted() {
    // 高さが0の場合は高さを設定
    const divStyle = window.getComputedStyle(this.$refs.simpleSvg, null);
    const divWidth = parseInt(divStyle.width.replace(/px/, ''), 10);
    const divHeight = parseInt(divStyle.height.replace(/px/, ''), 10);
    if (divWidth !== 0 && divHeight === 0) {
      this.$refs.simpleSvg.style.height = parseInt(this.shapeHeight / this.shapeWidth * divWidth, 10) + 'px';
    }

    if (this.fitWidth) {
      this.watchEndFn = vueUtils.watchDomResize(this.$refs.simpleSvg, this.watchResize);
    }
  },
  beforeUnmount() {
    if (this.watchEndFn) {
      this.watchEndFn();
    }
  }
}
</script>
