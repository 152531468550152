/**
 * 共通処理用のVuex定義。
 * 以下の情報は共通処理で利用するためのstoreを用意する。
 * ※アプリケーションで使用するデータはこのファイルではなくstore.jsで管理すること
 *
 *   1. トークン保持用store
 *   2. 一時的なページ遷移のデータ連携用store
 *
 *  以下の関数を提供している。
 *
 *  [トークン保持用]
 *   setToken()     : トークンをstoreに格納する
 *   getToken()     : トークンをstoreから取得する
 *   clearToken()   : トークンをクリアする
 *
 *  [ページ遷移のデータ連携用]
 *   passToNextPage()  : 引数の情報をVuexに格納する。ページ遷移前に呼び出す。
 *   getPrevPageData() : Vuexに格納されたデータを取得する。ページ遷移後に呼び出す。呼ばれた後はデータは削除される。
 */


import envUtils from '@/scripts/utils/envUtils.js';
import router from '@/plugins/router.js';
import store from '@/plugins/store.js'

// 専用のVuexモジュールを作成
store.registerModule('framework', {
    "namespaced": true,
    "state": {
        "token": null,
        "tempDataOnPageMove": null,
    },
    "mutations": {
        setToken(state, data) {
            state.token = data;
        },
        passToNextPage(state, data) {
            state.tempDataOnPageMove = data;
        },
        clearPageMoveData(state) {
            state.tempDataOnPageMove = null;
        },
    },
    "actions": {}
});

// トークンを設定
const setToken = token => {
    store.commit('framework/setToken', token);
}

// トークンを取得
const getToken = () => store.state.framework.token

// トークンをクリア
const clearToken = () => {
    store.commit('framework/setToken', null);
}

// 次のページにデータを連携
const passToNextPage = data => {
    store.commit('framework/passToNextPage', data);
}

// 前のページからのデータを取得
const getPrevPageData = () => {
    const tmp = store.state.framework.tempDataOnPageMove;
    store.commit('framework/clearPageMoveData');
    return tmp;
}

// ページ遷移とデータ連携をまとめて実施
const moveTo = async (url, data) => {
    if (data) {
        passToNextPage(data);
    }

    if (url.startsWith('#')) {
        await router.push(`${router.currentRoute.path}${url}`);
    } else if (url.includes('/')) {
        if (url === '/') {
            window.location.href = `${envUtils.getPublicPath()}index.html`;
        } else {
            await router.push(url);
        }
    } else {
        await router.push({"name": url});
    }
}

export {
    setToken,
    getToken,
    clearToken,
    passToNextPage,
    getPrevPageData,
    moveTo,
};

export default {
    setToken,
    getToken,
    clearToken,
    passToNextPage,
    getPrevPageData,
    moveTo,
};
