
// パラメータを用いたメッセージの作成
const createMessage = (msg, ...params) => String.raw({"raw": msg.split('{}')}, ...params)


// 全角文字を半角文字に変換
const convertHalf = value => {

  const fromChars = [...'１２３４５６７８９０！”＃＄％＆’（）－＝＾～￥｜＠‘「｛；＋：＊」｝、＜。＞・？＿ａｂｃｄｅｆｇｈｉｊｋｌｍｎｏｐｑｒｓｔｕｖｗｘｙｚＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ'];
  const toChars = [...'1234567890!"#$%&\'()-=^~\|@`[{;+:*]},<.>/?\\_abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'];
  
  return [...value].map(char => {
    const idx = fromChars.findIndex(c => c === char);
    return idx === -1 ? char : toChars[idx];
  })
  .join('');
};

/**
 * camelCaseをkebab-caseに変換する。
 * 例
 *   camelToKebab('aaaBbbCcc'); // aaa-bbb-ccc
 */
const camelToKebab = camel => {
  // https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707
  const kebab = camel.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
  return kebab.startsWith('-') ? kebab.slice(1) : kebab;
}

export {
  createMessage,
  convertHalf,
  camelToKebab,
}

export default {
  createMessage,
  convertHalf,
  camelToKebab,
}


