import router from './router.js';
import store from './store.js';
import vuetify from './vuetify.js';

export {
  router,
  store,
  vuetify,
}

export default {
  router,
  store,
  vuetify,
}
