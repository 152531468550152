/**
 * バリデーションチェック用モジュール。
 *
 * [利用方法]
 * バリデーションチェックで定義されている関数は以下の3種類がある。
 *   1. 単項目チェックを行う関数
 *   2. 複数パラメータを用いる単項目チェック
 *   3. 相関チェックを行う関数を返す関数
 *
 *   1.は普通の関数。
 *   2.は関数を返す関数を定義。
 *   3.は自フィールドのチェック結果を返す＋相手フィールドのチェックの再評価を行う。
 *
 * 例:
 *   <v-text-field v-model="text1" :rules="[isNotEmpty]"></v-text-field>                               // isNotEmptyは入力値が空か否かを返す関数
 *   <v-text-field v-model="text2" :rules="[isLessThan(5), isSameVal(text1, 'xxx')]"></v-text-field>   // isLessThan(5)は入力桁数が5未満の場合にエラーを返す関数を返す
 *                                                                                                     // isSameVal(text1, 'xxx')は入力値がtext1と違う場合は"値がxxxと一致しません"というエラーを返す関数を返す
 *   <v-text-field v-model="text3" ref="t3" :rules="[isSameInput('t4')]></v-text-field>                // isSameInput('t4')は入力値と this.$refs.t4.value の入力値の一致確認を行い、this.$refs.t4 のバリデーションの再評価を行う
 *   <v-text-field v-model="text4" ref="t4" :rules="[isSameInput('t3')]></v-text-field>                // isSameInput('t3')は入力値と this.$refs.t3.value の入力値の一致確認を行い、this.$refs.t3 のバリデーションの再評価を行う
 *
 * [チェックの追加]
 * 共通的なチェックであればこのモジュールにチェック処理を追加する。
 * 特定の画面でしか使われないことが明らかなチェック処理は各画面でチェック処理を作成すること。
 *
 * チェック処理は画面の$refsをバリデーションチェック側から使えるようにするため、アロー関数ではなくfunctionで作成すること。
 * アロー関数はthisを作成した時点で固定化するため画面のインスタンスを取得できない。
 * myCheck = value => { console.log(this.$refs.xxField); }          // 画面から呼ばれてもthisを参照できない
 * myCheck = function(value) { console.log(this.$refs.xxField); }   // 画面から呼ばれればthisを参照できる
 *
 * [インポート方法]
 * バリデーションチェックは基本的に以下のように必要なメソッドを名前を指定してインポートする処理方式を推奨する。
 *
 * import utils from '@/scripts/utils/utils.js'
 * import valid from '@/scripts/validation.js';
 * export default {
 *   methods () {
 *     ...(utils.getSubObject(valid, 'isNotEmpty', 'isZenkaku')),    // スプリット構文で必要な関数をコピー。全部コピーする場合は「...valid」。
 *     myMethod () {
 *       // 各画面用メソッド
 *     }
 *   }
 * }
 *
 * ※ スプリット構文を使用しない場合(名前を変えたい場合など)
 * import valid from '@/scripts/validation.js';
 * export default {
 *   methods () {
 *     valueIsNotEmpty: valid.isNotEmpty,    // 利用する関数をすべて列挙する
 *     valueIsZenkaku: valid.isZenkaku,
 *     myMethod () {
 *       // 各画面用メソッド
 *     }
 *   }
 * }
 *
 */


import dateUtils from '@/scripts/utils/dateUtils.js';
import stringUtils from '@/scripts/utils/stringUtils.js';

// --------- バリデーションNG時のメッセージ ---------
const msg = {
  "REQUIRED": '入力必須項目です',
  "WIDTH": '全角で入力してください',
  "HALF_KANA": '半角カナで入力してください',
  "WIDE_KANA": '全角カナで入力してください',
  "HALF_NUM": '半角数字で入力してください',
  "HALF_ALPHA": '半角英字で入力してください',
  "HALF_ALPHA_NUM": '半角英数字で入力してください',
  "HALF_ALPHA_NUM_SYMBOL": '半角英数字記号で入力してください',
  "MINLENGTH": '{}文字以上入力してください',
  "EQUALLENGTH": '{}文字で入力してください',
  "BEFORE_DATE": '{}以前の日付を入力してください',
  "AFTER_DATE": '{}以降の日付を入力してください',
  "BEFORE_TIME": '{}以前の時刻を入力してください',
  "AFTER_TIME": '{}以降の時刻を入力してください',
  "PASSWORD": '半角英字（大文字）、半角英字（小文字）、半角数字、半角記号のうち３種類以上使用してください',
  "PASSWORD_WEST": '半角英字＋数字もしくは記号を組み合わせた8文字以上で入力してください',
  "SAME_REF": '{}と一致していません',
  "DIFFERENT_REF": '{}と異なる値を入力してください',
  "DATE_RANGE": '日付の前後関係が不正です',
  "TIME_RANGE": '時間の前後関係が不正です',
  "MAIL_ADDRESS": 'メールアドレスの形式が不正です',
  "EMPTY_ARRAY": '{}が設定されていません',
  "OVER_FILE_SIZE": '{}MB以下のファイルを設定してください{}',
  "OVER_FILE_COUNT": '設定可能なファイル数は{}個までです',
};

// --------- バリデーションチェック ---------

// 必須チェック
const isNotEmpty = new Proxy(function(value) {
    if (value == null || value === '') {
        return msg.REQUIRED;
      }
      return true;
}, {
  get(target, prop, receiver) {
    if (prop === 'name') {
      return 'isNotEmpty';
    }
    return Reflect.get(...arguments);
  },
});

// 全角チェック
const isZenkaku = function(value) {
    if (value == null || value === '') {
        return true;
    }
    if (value.match(/^[^ -~｡-ﾟ]+$/) == null) {
        return msg.WIDTH;
    }
    return true;
};

// 半角カナチェック
const isHalfKana = function(value) {
    if (value == null || value === '') {
        return true;
    }
    if (value.match(/^[｡-ﾟ+]+$/) == null) {
        return msg.HALF_KANA;
    }
    return true;
};

// 半角数値チェック
const isHalfNum = function(value) {
    if (value == null || value === '') {
        return true;
    }
    if (value.match(/^[0-9]+$/) == null) {
        return msg.HALF_NUM;
    }
    return true;
}

// 半角英字チェック
const isHalfAlpha = function(value) {
    if (value == null || value === '') {
        return true;
    }
    if (value.match(/^[a-zA-Z]+$/) == null) {
        return msg.HALF_ALPHA;
    }
    return true;
}

// 半角英数字チェック
const isHalfAlphaNum = function(value) {
    if (value == null || value === '') {
        return true;
    }
    if (value.match(/^[a-zA-Z0-9]+$/) == null) {
        return msg.HALF_ALPHA;
    }
    return true;
}

// 半角英数字記号チェック
const isHalfAlphaNumSymbol = function(value) {
    if (value == null || value === '') {
        return true;
    }

    if (value.match(/^[a-zA-Z0-9!-\/:-@[-`{-~]+$/) == null) {
        return msg.HALF_ALPHA_NUM_SYMBOL;
    }
    return true;
}

// 最小文字数チェック
const isLargerLength = function(length) {
    return function(value) {
        if (value == null || value === '') {
            return true;
        }
        if (value.length < length) {
            return stringUtils.createMessage(msg.MINLENGTH, length);
        }
        return true;
    }
}

// 桁数一致のチェック
const isLengthEqualsTo = function(length) {
    return function(value) {
        if (value == null || value === '') {
            return true;
        }
        if (value.length !== length) {
            return stringUtils.createMessage(msg.EQUALLENGTH, length);
        }
        return true;
    }
}

// 入力値(yyyy/MM/dd)が指定refの値(yyyy/MM/dd)よりも前であることをチェック
const isBeforeDate = function(ref, name) {
    const that = this;
    return function(value) {

      const anotherRef = getComponentRef(that.$refs[ref]);
      if (value == null || value === '' || anotherRef == null || anotherRef.modelValue == null || anotherRef.modelValue === '') {
        return true;
      }
      const date1 = dateUtils.parseDate(value);
      const date2 = dateUtils.parseDate(anotherRef.modelValue);
      const isCheckOk = date1.getTime() <= date2.getTime();
      expectError(anotherRef, !isCheckOk);
      return isCheckOk ? true : stringUtils.createMessage(msg.BEFORE_DATE, name);
    }
}

// 入力値(yyyy/MM/dd)が指定refの値(yyyy/MM/dd)よりも後であることをチェック
const isAfterDate = function(ref, name) {
    const that = this;
    return function(value) {

      const anotherRef = getComponentRef(that.$refs[ref]);
      if (value == null || value === '' || anotherRef == null || anotherRef.modelValue == null || anotherRef.modelValue === '') {
        return true;
      }
      const date1 = dateUtils.parseDate(value);
      const date2 = dateUtils.parseDate(anotherRef.modelValue);
      const isCheckOk = date2.getTime() <= date1.getTime();
      expectError(anotherRef, !isCheckOk);
      return isCheckOk ? true : stringUtils.createMessage(msg.AFTER_DATE, name);
    }
}

// 入力値(HH:mm)が指定refの値(HH:mm)よりも前であることをチェック
const isBeforeTime = function(ref, name) {
    const that = this;
    return function(value) {

      const anotherRef = getComponentRef(that.$refs[ref]);
      if (value == null || value === '' || anotherRef == null || anotherRef.modelValue == null || anotherRef.modelValue === '') {
        return true;
      }
      const format = value.length === 5 ? 'HH:mm' : 'HH:mm:ss';
      const date1 = dateUtils.parseDate(value, format);
      const date2 = dateUtils.parseDate(anotherRef.modelValue, format);
      const isCheckOk = date1.getTime() <= date2.getTime();
      expectError(anotherRef, !isCheckOk);
      return isCheckOk ? true : stringUtils.createMessage(msg.BEFORE_TIME, name);
    }
}

// 入力値(HH:mm)が指定refの値(HH:mm)よりも前であることをチェック
const isAfterTime = function(ref, name) {
    const that = this;
    return function(value) {

      const anotherRef = getComponentRef(that.$refs[ref]);
      if (value == null || value === '' || anotherRef == null || anotherRef.modelValue == null || anotherRef.modelValue === '') {
        return true;
      }
      const format = value.length === 5 ? 'HH:mm' : 'HH:mm:ss';
      const date1 = dateUtils.parseDate(value, format);
      const date2 = dateUtils.parseDate(anotherRef.modelValue, format);
      const isCheckOk = date2.getTime() <= date1.getTime();
      expectError(anotherRef, !isCheckOk);
      return isCheckOk ? true : stringUtils.createMessage(msg.AFTER_TIME, name);
    }
}

// 入力値が指定refの値と一致することをチェック
const isSameRef = function(ref, name) {
  const that = this;
  return function(value) {

    const anotherRef = getComponentRef(that.$refs[ref]);
    if (value == null || value === '' || anotherRef == null || anotherRef.modelValue == null || anotherRef.modelValue === '') {
      return true;
    }
    const isCheckOk = value === anotherRef.modelValue;
    expectError(anotherRef, !isCheckOk);
    return isCheckOk ? true : stringUtils.createMessage(msg.SAME_REF, name);
  }
}


// 入力値が指定refの値と異なることをチェック
const isDifferentRef = function(ref, name) {
  const that = this;
  return function(value) {

    const anotherRef = getComponentRef(that.$refs[ref]);
    if (value == null || value === '' || anotherRef == null || anotherRef.modelValue == null || anotherRef.modelValue === '') {
      return true;
    }

    const isCheckOk = value !== anotherRef.modelValue;
    expectError(anotherRef, !isCheckOk);
    return isCheckOk ? true : stringUtils.createMessage(msg.DIFFERENT_REF, name);
  }
}

// パスワード入力チェック
// 半角英字（大文字）、半角英字（小文字）、半角数字、半角記号（!、@等）のうち３種類以上使用していること
const isValidPassword = function(value) {
    if (value == null || value === '') {
        return true;
    }

    if (value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])[!-~]+$/) != null) {
        return true;
    } else if (value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[!-\/:-@[-`{-~])[!-~]+$/) != null) {
        return true;
    } else if (value.match(/^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[!-\/:-@[-`{-~])[!-~]+$/) != null) {
        return true;
    } else if (value.match(/^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!-\/:-@[-`{-~])[!-~]+$/) != null) {
        return true;
    }

    return msg.PASSWORD;
}

// パスワード入力チェック(西)
// 半角英字（大文字）、半角英字（小文字）、半角数字、半角記号（!、@等）のうち２種類以上使用していること
const isValidPasswordWest = function(value) {
  if (value == null || value === '') {
      return true;
  }

  if (value.match(/^(?=.*?[A-Za-z])(?=.*?[!-@[-`{-~])[!-~]+$/) != null) {
      return true;
  }

  return msg.PASSWORD_WEST;
}

// 全角カナチェック
const isWideKana = function(value) {
  if (value == null || value === '') {
    return true;
  }

  const validChars = Array.from('ァアィイゥウェエォオカガキギクグケゲコゴサザシジスズセゼソゾタダチヂッツヅテデトドナニヌネノハバパヒビピフブプヘベペホボポマミムメモャヤュユョヨラリルレロヮワヰヱヲンヴヵヶヷヸヹヺ・ーヽヾヿㇰㇱㇲㇳㇴㇵㇶㇷㇸㇹㇺㇻㇼㇽㇾㇿ');
  const isOnlyWideKana = Array.from(value).every(char => validChars.includes(char));
  if (isOnlyWideKana) {
    return true;
  }
  return stringUtils.createMessage(msg.WIDE_KANA);
}

// メールアドレスチェック
const isMailAddress = function(value) {
  if (value == null || value === '') {
    return true;
  }

  // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    // eslint-disable-next-line prefer-named-capture-group
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase()) || stringUtils.createMessage(msg.MAIL_ADDRESS);
}

// 配列が空ではないことをチェック
const isNotEmptyArray = function(label, value) {
  return function(value) {
    if (value == null) {
      return true;
    }
    return 0 < value.length || stringUtils.createMessage(msg.EMPTY_ARRAY, label);
  }
}

// ファイルサイズが指定サイズ(MB)を超えないことをチェック
const isUnderLimitFileSize = function(size, value) {
  return function(value) {
    const files = Array.isArray(value) ? value : [value];
    const errFiles = files.filter(file => size * 1024 * 1024 < file.size)
                          .map(file => file.name);
    if (errFiles.length == 0) {
      return true;
    }
    return stringUtils.createMessage(msg.OVER_FILE_SIZE, size, `(${errFiles.join(', ')})`);
  }
}

// ファイル数が指定個数以下であることをチェック
const isSmallerFileCount = function(count, value) {
  return function(value) {
    const files = Array.isArray(value) ? value : [value];
    if (files.length <= count) {
      return true;
    }
    return stringUtils.createMessage(msg.OVER_FILE_COUNT, count);
  }
}

// 参照先のエラー状態が想定と異なる場合は相手側コンポーネントでバリデーションチェックを実行する
const expectError = (ref, needError) => {
  if (ref.$el?.classList?.contains('v-input--error') !== needError) {
    setTimeout(() => {
      ref.validate();
    }, 10);
  }
}

// ラップコンポーネントを利用している場合はコンポーネントのrefを取得
const getComponentRef = ref => {
  if (ref == null) {
    return null;
  }
  if (ref.$refs?.fieldRef) {
    return ref.$refs.fieldRef;
  }
  return ref;
}


// エクスポートする関数一覧

export {
  isNotEmpty,
  isZenkaku,
  isHalfKana,
  isHalfNum,
  isHalfAlpha,
  isHalfAlphaNum,
  isHalfAlphaNumSymbol,
  isLargerLength,
  isLengthEqualsTo,
  isBeforeDate,
  isAfterDate,
  isBeforeTime,
  isAfterTime,
  isSameRef,
  isDifferentRef,
  isValidPassword,
  isValidPasswordWest,
  isWideKana,
  isMailAddress,
  isNotEmptyArray,
  isUnderLimitFileSize,
  isSmallerFileCount,
}

export default {
  isNotEmpty,
  isZenkaku,
  isHalfKana,
  isHalfNum,
  isHalfAlpha,
  isHalfAlphaNum,
  isHalfAlphaNumSymbol,
  isLargerLength,
  isLengthEqualsTo,
  isBeforeDate,
  isAfterDate,
  isBeforeTime,
  isAfterTime,
  isSameRef,
  isDifferentRef,
  isValidPassword,
  isValidPasswordWest,
  isWideKana,
  isMailAddress,
  isNotEmptyArray,
  isUnderLimitFileSize,
  isSmallerFileCount,
}
