// できるだけ一意のIDを払い出す
const genId = () => {
    const timeId = (new Date().getTime() - new Date(2020, 0, 1).getTime()).toString(36);
    const randId = parseInt(Math.random() * 36 ** 5, 10).toString(36);
    return `${randId}${timeId}`;
};

export {
    genId,
}

export default {
    genId,
}

