// ***************************************************************
// 以下、ArrayをArray#reduce()のreducerを使って拡張する。
// 次のように使用する。
// array.reduce(reducer, <配列の要素数が0の場合の戻り値>)
// ***************************************************************


/**
 * 配列をグループ化するreducer
 * @param {(item: any) => string} keyManager   - キー情報を生成する関数。
 * @param {(item: any) => any}    valueManager - バリュー情報を生成する関数。省略した場合はitemを格納する
 * @return {[key: string]: [any]} 同一のキーを持つバリューを配列で格納したオブジェクトを返す
 * @example
 *   [1,2,3,4,5,6,7].reduce(groupingBy(i => i % 3), {});
 *   // {0: [3, 6], 1: [1, 4, 7], 2: [2, 5]}
 */
const groupingBy = (keyMaker, valueMaker) => {
    if (valueMaker == null) {
        valueMaker = val => val;
    }
    return (acc, cur, index, arr) => {
        const key = keyMaker(cur);
        const val = valueMaker(cur);
        if (index === 0) {
            return {[key]: [val]};
        }
        if (index === 1) {
            const prevKey = keyMaker(arr[0]);
            const prevVal = valueMaker(arr[0]);
            acc = {[prevKey]: [prevVal]};
        }
        if (acc[key] == null) {
            return {...acc,
[key]: [val]};
        }
            return {...acc,
[key]: [
...acc[key],
val
]};

    };
};

/**
 * [[key1, valu1], [key2, value2], ...]形式の配列をオブジェクトに変換するreducer
 * @return {any} オブジェクト情報
 * @example
 *   [['key1', 'value1'], ['key2', 123], ['key3', {a: 1, b: 2}]].reduce(objectFromEntries, {});
 *   // {key1: 'value1, key2: 123, key3: {a: 1, b: 2}}
 */
const objectFromEntries = (acc, cur) => {
    const [
key,
value
] = cur;
    acc[key] = value;
    return acc;
};


/**
 * filterとmapを同時に行うreducer
 * @param {item: any => any} converter - 変換関数。
 * @param {any} filterVal              - フィルタ対象の値。省略時はundefinedとなる。
 * @return {[any]} 変換およびフィルタリングされた配列。
 * @example
 *  [1, 2, 3, 4, 5].reduce(filterMap(item => (item % 2 === 0) ? undefined : `奇数：${item}`));
 *  // ['奇数:1', '奇数:3', '奇数:5']
 */
const filterMap = (converter, filterVal = null) => (acc, cur, index, arr) => {
  const val = converter(cur);
  if (index === 0) {
    return val === filterVal ? [] : [val];
  }
  if (index === 1) {
    const prevVal = converter(arr[0]);
    acc = prevVal === filterVal ? [] : [prevVal];
  }
  return val === filterVal ? acc : [
    ...acc,
    val
  ];
};

/**
 * ソートを行うreducer
 * Array#sort()は元の配列を変更するが、こちらは新しい配列を生成して返す。
 *
 * @param {(item1: any, item2: any) => number} comparator - 比較関数。
 * @return {[any]} ソートされた配列。
 * @example
 *  [5, 4, 3, 1, 2].reduce(sort(), []);
 *  // [1, 2, 3, 4, 5]
 */
const sort = comparator => (acc, cur, index, arr) => {
  if (index === arr.length - 1) {
    let copyArr = [...arr];
    copyArr.sort(comparator);
    return copyArr;
  }
  return null;

};

export {
    groupingBy,
    objectFromEntries,
    filterMap,
    sort,
};

export default {
    groupingBy,
    objectFromEntries,
    filterMap,
    sort,
}
