/**
 * ウィンドウ間のメッセージ連携を行うユーティリティ。
 * ウィンドウ間のメッセージは window#postMessage() を利用して連携する。
 * MessageDispatcherがpostMessage()の処理ラップしており、以下のような流れとなる。
 *
 *    +-------------------+         +-------------------+
 *    | MessageDispatcher |         | MessageDispatcher |
 *    |                   |         |                   |
 *    |                   |↖        |                   |
 *    +-------------------+ ＼      +-------------------+
 *             ｜             ＼
 *   3.dispatch｜  2.msgHelper.sendMessage()
 *             ↓                  ＼
 *         +--------+                ＼  +--------+
 *         | window |   1.openWindow() ＼| window |
 *         |        |------------------->|        |
 *         +--------+                    +--------+
 *  4.msgHelper.receiveMessage()
 *
 * このモジュールを使って以下のように送信処理を記載できる。
 * [端末A]
 *   const helper = postMessageUtils.openWindow('/path/to/other/page');
 *   const req = await helper.receiveMessage('request');
 *   helper.seneMessage({type: 'response', data: `response of ${req}`});
 *
 * [端末B]
 *  const helper = postMessageUtils.getPostMessageHelper(window.opener);
 *  helper.sendMessage({type: 'request', data: 'hello});
 *  const res = await helper.receiveMessage('response');
 *
 *
 * メッセージ送受信を行うクラスを取得する関数は以下となる。
 *
 *   openWindow(url)
 *     URLを別タブで開く。URLが'/'で始まる場合は同じドメインと判断する。
 *
 *   getPostMessageHelper(window, url)
 *     iframeやframeの場合はwindowオブジェクトとURLを指定してクラスを生成する。
 *
 * 取得したクラスでは以下のメソッドを利用してメッセージの送受信ができる。
 *
 *   sendMessage(obj)
 *     メッセージを送信する。
 *     objはメッセージ種別としてtypeプロパティを持っていることを想定している。(例：obj = {type: 'iiipShowMAp', geojson: {xxx}})
 *     戻り値は無し。
 *
 *   receiveMessage(msgType)
 *     メッセージを受信する。
 *     メッセージ種別の文字列をmsgTypeとして指定する。(上の例では'iiipShowMap')
 *     メッセージ種別の指定がない場合はすべてのメッセージの受信を通知する。
 *     Promiseを戻り値として返す。
 *
 * クラスは以下のプロパティを持っている。
 *
 *   targetWindow
 *     windowオブジェクト。window.focus()やwindow.close()を行う際に参照する。
 *
 * 注意：
 *   openWindow()直後は相手側のウィンドウの初期化が終わっていないのですぐにsendMessage()を行っても処理が失敗する。
 *   このため、相手側の初期化処理が終わった後に相手側から最初のメッセージを受け取るリクエストを出すような方式にする必要がある。
 */

// postMessageを受け取って各MessageHelperにディスパッチするクラス。
// addEventListenerを何度も実行しないようにメッセージ受信部分だけ別クラスで管理。
class MessageDispatcher {

  // コンストラクタ
  // postMessageの受信処理を開始
  constructor() {
    this._messageHelpers = [];
    window.addEventListener('message', this._onReceiveMsg.bind(this));
  }

  // ディスパッチ対象を登録
  addListener(msgHelper) {
    this._messageHelpers.push(msgHelper);
  }

  // メッセージ受信時の処理
  _onReceiveMsg(event) {

    // 送信先が自分ではない場合は処理対象外
    if (!window.location.href.startsWith(event.origin)) {
      return;
    }

    // webpackからのイベントは除外
    if (event.data.type == null || event.data.type.toLowerCase().startsWith("webpack")) {
      return;
    }

    // 登録されたウィンドウが閉じられている場合はメッセージヘルパーを削除
    this._messageHelpers = this._messageHelpers.filter(helper => helper.targetWindow && !helper.targetWindow.closed);

    // 送信元が登録されたウィンドウの場合はメッセージヘルパーにディスパッチ
    this._messageHelpers
        .filter(helper => helper.targetWindow === event.source)
        .forEach(helper => helper._onReceiveMsg(event));
  }
}

// 1つのウィンドウとの間のメッセージの送受信を行う
class MessageHelper {

  constructor(wnd, origine) {
    this.targetWindow = wnd;
    this._targetOrigine = origine;
    this._receiveMessages = {};
    this._resolvers = {};
  }

  // メッセージ受信時の処理
  _onReceiveMsg(event) {

    // メッセージ種別が受信待ち状態だったらresolveする
    const type = event.data.type;
    if (type && this._resolvers[type] != null) {
      this._resolvers[type].call(null, event.data);
      this._resolvers[type] == null;
      return;
    }

    // 待ち受けメッセージ種別が '*' の場合はどのメッセージでも通知
    if ('*' in this._resolvers) {
      this._resolvers['*'].call(null, event.data);
      this._resolvers['*'] == null;
      return;
    }

    // 受信メッセージを保持しておく
    if (this._receiveMessages[type] == null) {
      this._receiveMessages[type] = [event.data];
    } else {
      this._receiveMessages[type].push(event.data);
    }
  }

  // メッセージを送信
  sendMessage(message) {
    this.targetWindow.postMessage(message, this._targetOrigine);
  }

  // メッセージ受信
  receiveMessage(messageType = '*') {

    // 指定のメッセージ種別のメッセージが受信済みだったらそれを返す
    const msgList = this._receiveMessages.messageType;
    if (msgList != null && msgList.length !== 0) {
      return Promise.resolve(msgList.shift());
    }

    // メッセージ種別が '*' の場合は任意の受信済みのメッセージを返す
    if (messageType === '*') {
      for (let list of Object.values(this._receiveMessages)) {
        if (list && 0 < list.length) {
          return Promise.resolve(list.shift());
        }
      }
    }

    // メッセージを受信していない場合は受信後にresolveする
    return new Promise(resolve => this._resolvers[messageType] = resolve);
  }
}

const messageDispatcher = new MessageDispatcher();

// URLからオリジン文字列を取得
const getOrigine = url => {

  // /で始まる場合は同一ドメインと判断
  if (url.startsWith('/')) {
    return `${location.protocol}//${location.hostname}${location.port && ':' + location.port}`;
  }

  // それ以外の場合は http[s]://xxxx:portnum をオリジンとする
  const matchArr = url.match(/https?:\/\/[^/]*/);
  if (matchArr == null) {
    throw new Error(`指定されたurl [${url}] が適切ではありません`)
  }
  return matchArr[0];
}

// 指定URLのウィンドウを開く
const openWindow = url => {

  // 同じドメインの場合はクエリパラメータでパスを指定してトップページを表示
  const newUrl = url.startsWith('/') ? `/?redirect=${encodeURIComponent(url)}` : url;
  const origine = getOrigine(url);
  const wnd = window.open(newUrl);
  if (wnd == null) {
    throw new Error(`${url}のウィンドウを表示できませんでした`);
  }

  // メッセージ送受信クラスを返す
  const helper = new MessageHelper(wnd, origine);
  messageDispatcher.addListener(helper);
  return helper;
}

// リダイレクト情報を取得(Top.vueで利用)
const getPostMessageRedirectPath = () => {
  const params = new URLSearchParams(location.search);
  return params.get('redirect');
}

// ウィンドウオブジェクトを指定してMessageHelperを取得
const getPostMessageHelper = (wnd, url = '/') => {
  const helper = new MessageHelper(wnd, getOrigine(url));
  messageDispatcher.addListener(helper);
  return helper;
}

export {
  openWindow,
  getPostMessageHelper,
  getPostMessageRedirectPath,
};

export default {
  openWindow,
  getPostMessageHelper,
  getPostMessageRedirectPath,
}
