import { nextTick } from 'vue';
import { getType } from '@/scripts/utils/objectUtils';

/**
 * 文字列形式、配列形式のstyleをオブジェクト形式に変換する。
 * 例：
 *   toObjStyle('padding-left: 10px; border: solid 1px black;');          // {'padding-left': '10px', border: 'solid 1px black'}
 *   toObjStyle([{'padding-left': '10px'}, {border: 'solid 1px black'}]); // {'padding-left': '10px', border: 'solid 1px black'}
 */
const toObjStyle = style => {
    if (style == null) {
        return {};
    }
    const isString = typeof style === 'string';
    const isArray = Array.isArray(style);
    const isObject = !isString && !isArray;

    if (isObject) {
        return style;
    }

    if (isString) {
        if (style.length === 0) {
            return {};
        }
        const entries = style.split(';')
            .filter(item => Boolean(item))
            .map(item => {
                const idx = item.indexOf(':');
                if (!idx) {
                    throw new Error(`styleの記載が不正です。${item}`);
                }
                return [
                    item.slice(0, idx),
                    item.slice(idx + 1).trim()
                ];
            });
        return Object.fromEntries(entries);
    }

    if (isArray) {
        let objStyle = {};
        style.forEach(item => Object.assign(objStyle, item));
        return objStyle;
    }

    return {};
};

/**
 * クラスの設定情報をマージする
 * vueではclassをString、Array、Objectで記載できるのでObject形式でマージしたものを返す。
 * 同じクラスが指定されていれば後ろの設定で上書きされる。
 * 例
 *   extendClass('px-2', ['pt-1', 'mb-3'], {'px-2': false}) // {'px-2': false, 'pt-1': true, 'mb-3': true}
 */
const extendClass = (...classes) => classes.map(toObjClass)
    .reduce((acc, cur) => Object.assign(acc, cur), {});

const extendStyle = (...styles) => styles.map(toObjStyle)
    .reduce((acc, cur) => Object.assign(acc, cur), {});


// Vueがbindした配列に値を追加する場合はpushを使う
// Vueがbindした配列の末尾の値を削除する場合はpopを使う
// Vueがbindした配列の中身ではなくすべてを変更する場合は直接変更可能
/**
 * Vueがbindした配列の指定要素を設定する
 * @param {[any]} arr - Vueがbindしている配列オブジェクト
 * @param {number} index - インデックス
 * @param {any} val - 値
 * @example
 *   data () {
 *     return {
 *       item: [1, 2, 3],
 *     }
 *   },
 *   mounted () {
 *     setBindArrayItem(this.item, 2, 'xxx');
 *   }
 */
const setBindArrayItem = (arr, index, val) => {
    arr.splice(index, 1, val);
};

/**
 * Vueがbindした配列の全要素をクリアする
 * @param {[any]} arr - Vueがbindしている配列オブジェクト
 * @example
 *   data () {
 *     return {
 *       item: [1, 2, 3],
 *     }
 *   },
 *   mounted () {
 *     clearBindArray(this.item);
 *   }
 */
const clearBindArray = arr => {
    arr.splice(0, arr.length);
};


/**
 * Vuetifyの背景色のクラス名をテキスト色のクラス名に変換する。
 * 例
 *  toTextColor('light-blue darken-3') // light-blue--text text--darken-3
 */
const toTextColor = colorClass => {

    const colorList = [
        'amber',
        'blue',
        'blue-grey',
        'brown',
        'cyan',
        'deep-orange',
        'deep-purple',
        'green',
        'grey',
        'indigo',
        'light-blue',
        'light-green',
        'lime',
        'orange',
        'pink',
        'purple',
        'red',
        'teal',
        'yellow',
        'primary',
        'secondary',
        'success',
        'error',
        'warning',
        'info',
        'accent'
    ];
    const depthList = [
        'lighten-4',
        'lighten-3',
        'lighten-2',
        'lighten-1',
        'darken-1',
        'darken-2',
        'darken-3',
        'darken-4'
    ];

    const classes = Object.keys(extendClass(colorClass));

    return classes.map(cls => {
        if (colorList.includes(cls)) {
            return `${cls}--text`;
        }
        if (depthList.includes(cls)) {
            return `text--${cls}`;
        }
        return cls;
    })
        .join(' ');
};

/**
 *  指定要素の指定属性を監視する。
 *  要素の監視を終了する際は戻り値の関数をコールする。
 *  開始の状態を通知しない場合は initial = false とする。
 *
 *  Vueは直接属性で指定していないDOMの変更を検知できないので MutationObserver を使って監視する
 *  https://developer.mozilla.org/ja/docs/Web/API/MutationObserver
 *
 *  ただし要素のリサイズは検知できない。
 *  要素のリサイズはwatchDomResize()を使用すること。
 *
 *  例：
 *    const watchEnd = watchDomAttribute(this.$refs.xxx.$el, 'class', (newVal, oldVal) => {...});
 *    watchEnd();
 */
// eslint-disable-next-line max-params
const watchDomAttribute = (element, attribute, callback, initial = true) => {

    const observer = new MutationObserver(mutations => {
        for (const mutation of mutations) {
            const newValue = mutation.target.getAttribute(mutation.attributeName);
            nextTick(() => {
                const retVal = callback(newValue, mutation.oldValue);
                if (retVal === false) {
                    observer.disconnect();
                }
            });
        }
    });

    if (initial) {
        callback(element.getAttribute(attribute), null);
    }

    const cfg = {
        "attributes": true,
        "attributeOldValue": true,
        "attributeFilter": [attribute]
    };
    observer.observe(element, cfg);
    return observer.disconnect.bind(observer);
};


/**
 * 文字列形式、配列形式のclassをオブジェクト形式に変換する。
 * 例：
 *   toObjClass('py-2 px-5');       // {'py-2': true, 'px-5': true}
 *   toObjClass(['py-2', 'px-5']);  // {'py-2': true, 'px-5': true}
 */
const toObjClass = cls => {
    if (cls == null) {
        return {};
    }
    const isString = typeof cls === 'string';
    const isArray = Array.isArray(cls);
    const isObject = !isString && !isArray;

    if (isObject) {
        return cls;
    }

    if (isString) {
        if (cls.length === 0) {
            return {};
        }
        const entries = cls.replace(/ +/g, ' ').trim()
            .split(' ')
            .map(item => [
                item,
                true
            ]);
        return Object.fromEntries(entries);
    }

    if (isArray) {
        const entries = cls.map(item => [
            item,
            true
        ]);
        return Object.fromEntries(entries);
    }

    return {};
};

/**
 *  指定要素のリサイズを監視する。
 *  要素の監視を終了する際は戻り値の関数をコールする。
 *  開始の状態を通知しない場合は initial = false とする。
 *
 *  参照：
 *  http://www.backalleycoder.com/2013/03/18/cross-browser-event-based-element-resize-detection/
 *
 *  例：
 *    const watchEnd = watchDomResize(this.$refs.xxx.$el, event => {...});
 *    watchEnd();
 */
const watchDomResize = (elm, callback, initial = true) => {

    let attachEvent = document.attachEvent;
    let isIE = navigator.userAgent.match(/Trident/);

    let requestFrame = (function () {
        let raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame ||
            function (fn) {
                return window.setTimeout(fn, 20);
            };
        return function (fn) {
            return raf(fn);
        };
    }());

    let cancelFrame = (() => {
        let cancel = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame ||
            window.clearTimeout;
        return id => cancel(id);
    })();

    const resizeListener = elem => {
        let win = elem.target || elem.srcElement;
        if (win.__resizeRAF__) {
            cancelFrame(win.__resizeRAF__);
        }
        win.__resizeRAF__ = requestFrame(() => {
            let trigger = win.__resizeTrigger__;
            trigger.__resizeListeners__.forEach(fn => {
                fn(trigger, elem);
            });
        });
    };

    const objectLoad = () => {
        this.contentDocument.defaultView.__resizeTrigger__ = this.__resizeElement__;
        this.contentDocument.defaultView.addEventListener('resize', resizeListener);
    };

    const addResizeListener = (element, fn) => {
        if (!element.__resizeListeners__) {
            element.__resizeListeners__ = [];
            if (attachEvent) {
                element.__resizeTrigger__ = element;
                element.attachEvent('onresize', resizeListener);
            } else {
                if (getComputedStyle(element).position == 'static') {
                    element.style.position = 'relative';
                }
                // eslint-disable-next-line no-multi-assign
                let obj = element.__resizeTrigger__ = document.createElement('object');
                obj.setAttribute('style', 'display: block; position: absolute; top: 0; left: 0; height: 100%; width: 100%; overflow: hidden; pointer-events: none; z-index: -1;');
                obj.__resizeElement__ = element;
                obj.onload = objectLoad;
                obj.type = 'text/html';
                if (isIE) {
                    element.appendChild(obj);
                }
                obj.data = 'about:blank';
                if (!isIE) {
                    element.appendChild(obj);
                }
            }
        }
        element.__resizeListeners__.push(fn);
    };

    const removeResizeListener = (element, fn) => {
        element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1);
        if (!element.__resizeListeners__.length) {
            if (attachEvent) {
                element.detachEvent('onresize', resizeListener);
            } else if (element.__resizeTrigger__.contentDocument && element.__resizeTrigger__.contentDocument.defaultView) {
                element.__resizeTrigger__.contentDocument.defaultView.removeEventListener('resize', resizeListener);
                element.__resizeTrigger__ = !element.removeChild(element.__resizeTrigger__);
            }
        }
    };

    addResizeListener(elm, callback);
    if (initial) {
        callback(elm, null);
    }
    return removeResizeListener.bind(null, elm, callback);
};

/**
 * オブジェクトからイベントハンドラのみ抽出する
 * 例
 * <template>
 *   <template v-slot:activator="{props}">
 *     <v-icon v-bind="getHandler(props)" icon="fa:fa:fas fa-circle"></v-icon>
 *   </template>
 * </template>
 *
 * <script>
 * import {utils} from '@/scripts';
 * export default {
 *   methods: {
 *     getHandler: utils.getHandler,
 *   }
 * };
 * </script>
 */
const getHandler = (obj) => {
    if (!obj) {
        return obj;
    }
    const keys = Object.keys(obj).filter(key => getType(obj[key]) === 'Function' && key.startsWith('on'));
    const entries = keys.map(key => [key, obj[key]]);
    return Object.fromEntries(entries);
}

export {
    extendClass,
    extendStyle,
    toObjClass,
    toObjStyle,
    setBindArrayItem,
    clearBindArray,
    toTextColor,
    watchDomAttribute,
    watchDomResize,
    getHandler,
}

export default {
    extendClass,
    extendStyle,
    toObjClass,
    toObjStyle,
    setBindArrayItem,
    clearBindArray,
    toTextColor,
    watchDomAttribute,
    watchDomResize,
    getHandler,
}
