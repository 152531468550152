const componentExtendGenerator = (component, refName, events) => {

    // コンポーネントのプロパティ定義を取得する関数
    const props = () => (component.options ? component.options.props : component.props) || {}

    // $refsのメソッドに処理を委譲するメソッドを作成する関数
    const methods = () => {
        const componentMethods = (component.options ? component.options.methods : component.methods) || {};
        const entries = Object.keys(componentMethods)
            .map(key => [
                key,
                (...args) => this.$refs[refName][key](...args)
            ]);
        return Object.fromEntries(entries);
    }

    // $refsの算出プロパティを参照する算出プロパティを作成する関数
    const computed = () => {
        const data = (component.options ? component.options.data() : component.data()) || {};
        const entries = Object.keys(data)
            .map(key => [
                key,
                () => this.$refs[refName][key]
            ]);
        return Object.fromEntries(entries);
    }

    // イベントをそのまま上位にイベントを投げるメソッドを作成する関数
    const emitMethods = () => {
        const entries = events.map(eventName => [
            `_bindOn${eventName}`,
            (...args) => {
                this.$emit(eventName, ...args);
            }
        ]);
        return Object.fromEntries(entries);
    }

    // イベントを受け付けるバインド設定を作成する関数
    const bindEventData = self => {
        const entries = events.map(eventName => [
            eventName,
            self[`_bindOn${eventName}`]
        ]);
        return Object.fromEntries(entries);
    }

    return {
        props,
        methods,
        computed,
        emitMethods,
        bindEventData,
    }
};

export {
    componentExtendGenerator,
}

export default {
    componentExtendGenerator,
}
