<template>
  <v-dialog ref="alertDialog" v-model="show" persistent :max-width="maxWidth">
    <v-card>
      <v-card-title class="pt-2 pb-2 bg-blue-darken-3"><span class="white--text">{{ dialogTitle }}</span></v-card-title>
      <v-card-text class="text-black">
        <v-row class="pt-3">
          <!-- iconの指定があれば3:9に分割、指定がなければメッセージのみ表示 -->
          <v-col :cols="icon == null ? false : 3" v-show="icon">
            <v-avatar color="yellow-darken-3" size="50">
              <v-icon size="40" color="white" icon="fa:fas fa-exclamation"></v-icon>
            </v-avatar>
          </v-col>
          <v-col :cols="icon == null ? 12 : 9">
            <span v-html="dialogMessage" style="font-size: 13.5px;"></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <wrap of="v-btn" height="36" @click="close" ref="ok">OK</wrap>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

/**
 * アラートダイアログ。
 *   [プロパティ]
 *     value:         trueの場合はダイアログ表示、falseの場合は非表示。
 *     title:         アラートダイアログのタイトル文字列。
 *     message:       アラートダイアログのメッセージ文字列。
 *     max-width:     ダイアログの横幅。
 *     icon:          trueの場合はアイコンを表示する。
 *     dialog-extend: utilsインスタンスにalertメソッドを追加する。利用方法の説明を参照。
 *
 *   [イベント]
 *     close():     ダイアログがクローズされた場合に発火。
 *
 *   [メソッド]
 *     showDialog(title, message)
 *       title:   アラートダイアログのタイトル文字
 *       message: アラートダイアログのメッセージ文字列。
 *
 *     showDialog(messageObj, params)
 *       messageObj.title: アラートダイアログのタイトル文字。
 *       messageObj.msg:   アラートダイアログのメッセージ文字列。
 *       params:           メッセージ文字の置換パラメータ配列。
 *
 *   [利用方法]
 *      以下の2つの利用方法をサポートしています。
 *         1. 普通のコンポーネントとして
 *            <template>
 *              <alert-dialog ref="refAlert" v-model="isShow" @close="console.log('close')"></alert-dialog>
 *              <button @click="onclick">ダイアログ表示<button>
 *            </template>
 *            <script>
 *              export default {
 *                data () {
 *                  return {isShow: false};
 *                },
 *                methods: {
 *                  onclick () {
 *                    this.isShow = true;
 *                    // this.$refs.refAlert.showDialog("タイトル", "メッセージ").then(() => console.log('ダイアログクローズ'))
 *                  }
 *                }
 *              }
 *            ＜/script>
 *         2. utils拡張用として
 *           [親画面]
 *             <template>
 *               <alert-dialog dialog-extend></alert-dialog>
 *               <router-view></router-view>
 *             </template>
 *           [子画面]
 *             <script>
 *               import utils from '@/scripts/utils'
 *               export default {
 *                 methods: {
 *                   onclick () {
 *                     // 任意のソースからダイアログを呼び出せる
 *                     utils.alert({title: "タイトル", msg: "{}が{}です"}, ['ユーザ名', '桁数不正']).then(() => console.log('ダイアログクローズ'))
 *                   }
 *                 }
 *               }
 *             ＜/script>
 *  [その他]
 *    アイコンの変更や色の変更などの細かい修正は外部から変更できるように作っていないので
 *    各プロジェクトごとにこのコンポーネントを直接編集してください。
 *
 */
import {dialog, stringUtils} from '@/scripts/utils';

export default {
  data() {
    return {
      "show": false,
      "dialogTitle": '',
      "dialogMessage": '',
    }
  },
  "props": {
    "value": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "title": {
      "type": String,
      "required": false,
      "defalut": () => 'エラー'
    },
    "message": {
      "type": String,
      "required": false,
      "default": () => ''
    },
    'max-width': {
      "type": String,
      "required": false,
      "default": () => "440"
    },
    "icon": {
      "type": Boolean,
      "required": false,
      "default": () => true
    },
  },
  "watch": {
    "value"(newVal) {
      this.show = newVal;
    },
    "title"(newVal) {
      this.dialogTitle = newVal;
    },
    "message"(newVal) {
      this.dialogMessage = newVal;
    },
    "show"(newVal) {
      if (newVal) {
        // 画面が表示されたらOKボタンにフォーカスを当てる
        const that = this;
        setTimeout(() => that.$refs.ok.$el.focus(), 50);
      }
    }
  },
  "methods": {
    // utilsから呼び出すときの関数名
    getFuncName () {
      return 'alert';
    },
    close() {
      this.show = false;
      this.$emit('input', false);
      this.$emit('close');
    },
    open(arg1, arg2) {

      // 引数が文字列でもオブジェクトでも処理できるようにする
      if (typeof arg1 === 'string') {
        this.dialogTitle = arg1;
        this.dialogMessage = arg2;
      } else {
        this.dialogTitle = arg1.title;
        this.dialogMessage = arg2 == null ? arg1.msg
            : Array.isArray(arg2) ? stringUtils.createMessage(arg1.msg, ...arg2)
                : stringUtils.createMessage(arg1.msg, arg2);
      }
      this.show = true;
      const that = this;

      // Promiseを返す
      return new Promise(resolve => {
        const unwatch = that.$watch(
            () => that.show,
            () => {
              unwatch();
              resolve();
            }
        );
      })
    }
  }
}
</script>
