export * from './arrayUtils.js';
export * from './dateUtils.js';
export * from './envUtils.js';
export * from './htmlUtils.js';
export * from './objectUtils.js';
export * from './postMessageUtils.js';
export * from './stringUtils.js';
export * from './validation.js';
export * from './vuexUtils.js';
export * from './vueUtils.js';
export * from './idUtils.js';

import arrayUtils from './arrayUtils.js';
import dateUtils from './dateUtils.js';
import dialog from './dialog.js';
import envUtils from './envUtils.js';
import htmlUtils from './htmlUtils.js';
import idUtils from './idUtils.js';
import objectUtils from './objectUtils.js';
import postMessageUtils from './postMessageUtils.js';
import stringUtils from './stringUtils.js';
import validation from './validation.js';
import vueUtils from './vueUtils.js';
import vuexUtils from './vuexUtils.js';

// ダイアログは後で関数が登録されるので別途処理を定義
const alert   = function(...params) {return dialog.dialogs.alert(...params)};
const confirm = function(...params) {return dialog.dialogs.confirm(...params)};
const loading = function(...params) {return dialog.dialogs.loading(...params)};

export {
  arrayUtils,
  dateUtils,
  dialog,
  envUtils,
  htmlUtils,
  objectUtils,
  postMessageUtils,
  stringUtils,
  validation,
  vuexUtils,
  vueUtils,
  alert,
  confirm,
  loading,
  idUtils,
}

// よく使うのでformsもutilsから参照できるようにする
// validationはまとめて使うので展開しない
export default {
  ...arrayUtils,
  ...dateUtils,
  ...envUtils,
  ...htmlUtils,
  ...objectUtils,
  ...postMessageUtils,
  ...stringUtils,
  ...vuexUtils,
  ...vueUtils,
  ...idUtils,
  alert,
  confirm,
  loading,
  validation,
  "validations": validation,
}
