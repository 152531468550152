<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-chip class="ml-5 mt-n1 bg-red" dark v-on="on">
        <div class="text-center" style="width: 4em;">必須</div>
      </v-chip>
    </template>
    必須入力です。
  </v-tooltip>
</template>
<script>
/**
 * 入力フォームの必須入力表示用タグ
 * 
 */
export default {
  }
</script>
<style scoped>
.v-chip {
  border-radius: 12px;
}
</style>