import consts from './consts.js';
import enums from './enums.js';
import msg from './msg.js';

export * from './consts.js';
export * from './enums.js';

export {enums};
export {msg};

export default {
  ...consts,
  enums,
  msg,
}
