import * as utils from '@/scripts/utils';

const BASE_URL = utils.getApiBaseUrl();

const settings = {
    'アカウントトークンIF': {
        "methodName": 'accountTokenValidate',
        "type": 'post',
        "url": `${BASE_URL}/users/token`
    },
    'ユーザ登録IF': {
        "methodName": 'addUser',
        "type": 'post',
        "url": `${BASE_URL}/users`
    },
    'アカウント作成メール送信IF': {
        "methodName": 'accountAddressConfirm',
        "type": 'post',
        "url": `${BASE_URL}/users/mailaddress`
    },
    'ユーザログインIF': {
        "methodName": 'userLogin',
        "type": 'post',
        "url": `${BASE_URL}/users/login`
    },
    'ワンタイムパスワード認証IF': {
        "methodName": 'userLoginOtp',
        "type": 'post',
        "url": `${BASE_URL}/users/login/otp`
    },
    'ログアウトIF': {
        "methodName": 'userLogout',
        "type": 'post',
        "url": `${BASE_URL}/users/logout`
    },
    'WEB受付番号採番IF': {
        "methodName": 'getOrderNo',
        "type": 'get',
        "url": `${BASE_URL}/orders/no`
    },
    '申請詳細通知IF': {
        "methodName": 'notifyOrderDetail',
        "type": 'post',
        "url": `${BASE_URL}/orders/details`
    },
    '祝日一覧取得IF': {
        "methodName": 'getHolidays',
        "type": 'get',
        "url": `${BASE_URL}/holidays`
    },
    '住所検索IF': {
        "methodName": 'getAddresses',
        "type": 'get',
        "url": `${BASE_URL}/addresses`
    },
    '解析情報通知IF': {
        "methodName": 'postAnalyticsData',
        "type": 'beacon',
        "url": `${BASE_URL}/analytics`
    },
    '申請情報一覧取得': {
        "methodName": 'getOrderList',
        "type": 'get',
        "url": `${BASE_URL}/orders`
    },
    '申請情報取得 ': {
        "methodName": 'getOrderDetail',
        "type": 'get',
        "url": `${BASE_URL}/orders/details`
    },
    '申請取り消し依頼': {
        "methodName": 'postOrderCancel',
        "type": 'post',
        "url": `${BASE_URL}/orders/cancel`
    },
    'ファイルアップロード': {
        "methodName": 'uploadFiles',
        "type": 'postForm',
        "url": `${BASE_URL}/files/upload`
    },
    'ファイルダウンロード（ファイル名）': {
        "methodName": 'downloadFilesName',
        "type": 'get',
        "url": `${BASE_URL}/files/downloadName`
    },
    'ファイルダウンロード': {
        "methodName": 'downloadFile',
        "type": 'getFileMemory',
        "url": `${BASE_URL}/files/download`
    },
    'アカウント情報変更IF': {
        "methodName": 'updateUser',
        "type": 'post',
        "url": `${BASE_URL}/users/update`
    },
    'パスワードリセットトークン取得IF': {
        "methodName": 'getResetToken',
        "type": 'post',
        "url": `${BASE_URL}/users/password/reset/token`
    },
    'パスワード変更IF': {
        "methodName": 'resetPassword',
        "type": 'post',
        "url": `${BASE_URL}/users/password/reset`
    },
    'コード定義取得IF': {
        "methodName": 'getCodeDefineList',
        "type": 'get',
        "url": `${BASE_URL}/construction/list/code`
    },
    'アカウント情報取得IF': {
        "methodName": 'resetTokenValidate',
        "type": 'post',
        "url": `${BASE_URL}/users/password/token`
    },
    '受付周知事項取得': {
        "methodName": 'getInformation',
        "type": 'get',
        "url": `${BASE_URL}/information`
    },
    '周知事項ファイルダウンロード': {
        "methodName": 'downloadInformation',
        "type": 'getFileStream',
        "url": `${BASE_URL}/information/download`
    },
    '帳票ダウンロード': {
        "methodName": 'downloadReport',
        "type": 'getFileMemory',
        "url": `${BASE_URL}/orders/reports`
    },
    '帳票ステータス更新': {
        "methodName": 'confirmReport',
        "type": 'post',
        "url": `${BASE_URL}/orders/reports/confirm`
    },

};
export default {
    ...settings,
};
