import {analyticsNotifyCategory} from '@/scripts/consts/enums.js';
import restClient from '@/scripts/restClient';
import store from '@/plugins/store.js';
import utils from '@/scripts/utils';
import md5 from 'crypto-js/md5';

// グループ外に遷移する時に解析情報送送信するフォーム(トップ)
const publicGroupForm = ['/top', '/users/addAccountMail', '/login', '/login/otp', '/users/password/reset/token'];

// グループ外に遷移する時に解析情報送送信するフォーム(アカウント作成)
const accountGroupForm = ['/users/selectAccountType', '/users/addAccount', '/personalInfoAgree'];

// グループ外に遷移する時に解析情報送送信するフォーム(申請)
const applyGroupForm = ['/main/orders/regist/form', '/main/orders/regist/map', '/main/orders/regist/confirm'];

// トップにリダイレクトする時に解析情報送送信するフォーム
const publicEdgeForm = ['/users/addAccountMail', '/users/password/reset/token', '/users/password/reset'];

// 画面名変換マップ
const pageNameMap = {
    null: 'なし',
    '/top': 'トップ画面',
    '/users/addAccountMail': '新規アカウント作成画面',
    '/login': 'ログイン画面',
    '/login/otp': 'ワンタイムパスワード入力画面',
    '/users/password/reset/token': 'パスワード変更受付画面',
    '/users/password/reset': 'パスワード変更画面',
    '/users/selectAccountType': 'アカウント種別選択画面',
    '/users/addAccount': 'アカウント登録画面',
    '/personalInfoAgree': '個人情報同意画面',
    '/main/orders/regist/form': '申請入力画面',
    '/main/orders/regist/map': '地図画面（申請情報登録）',
    '/main/orders/regist/confirm': '申請確認画面',
    '/main/orders/list': '申請一覧画面',
    '/main/orders/list/detail': '申請詳細画面',
    '/main/orders/regist/complete': '申請完了画面',
    '/main/orders/regist/selectFacility': '申請設備選択画面',
    '/main/users/updateAccount': 'アカウント変更画面',
};

// パスを画面名に変換する。変換マップにない場合はパスをそのまま返却する。
const getPageName = path => pageNameMap.hasOwnProperty(path) ? pageNameMap[path] : path;

// 表示中の主コンポーネントに対する参照を返却する。
const getRef = (cp) => {
    return cp.$data;
};

// ログに出力する項目の値を正規化して返却する。
const normalize = value => [null, undefined].includes(value) ? '' : value;

// ログに出力するマスク値を返却する。
const mask = value => value !== null ? '*' : '';

// オブジェクト内の全項目を正規化して返却する。
const normalizeObject = src => {
    const dest = {};
    for (let key in src) {
        dest[key] = normalize(src[key]);
    }
    return dest;
};

// ハッシュ値の文字列を返却する。
const hash = text => text ? md5(text).toString() : '';

// 解析情報送信(トップ)
const notifyPublicData = (app, fromPath, toPath) => {
    const view = getRef(app);
    restClient.postAnalyticsData({
        category: analyticsNotifyCategory.TOP,
        lastPage: getPageName(fromPath),
        destination: getPageName(toPath),
        via: normalize(view.via),
        referrer: store.state.referrer,
        currentTime: utils.formatDate(new Date())
    });
    // リファラをクリア
    store.commit('setReferrer', '');
};

// 解析情報送信(アカウント作成)
const notifyAccountData = (app, fromPath, toPath) => {
    const view = getRef(app);
    const accountReq = view.accountReq;
    if (!accountReq) {
        return;
    }
    const isGov = store.state.isAdministrative;
    const inputs = {
        mailAddress: hash(accountReq.mailAddress),
        role: isGov !== null ? (isGov ? 0 : 1) : ''
    };
    for (let key in accountReq) {
        if (!['userId', 'mailAddress', 'role'].includes(key)) {
            // 除外しない項目についてマスク値を設定
            inputs[key] = mask(accountReq[key]);
        }
    }
    restClient.postAnalyticsData({
        category: analyticsNotifyCategory.ACCOUNT,
        lastPage: getPageName(fromPath),
        destination: getPageName(toPath),
        via: normalize(view.via),
        ...inputs,
        focusItem: normalize(view.focusItem),
        currentTime: utils.formatDate(new Date())
    });
};

// 解析情報送信(申請)
const notifyApplyData = (app, fromPath, toPath) => {
    const view = getRef(app);
    const notifyReq = view.notifyReq;
    if (!notifyReq) {
        return;
    }
    const inputs = {
        mailAddress: hash(store.state.userId),
        role: notifyReq.user.role,
        orderNo: normalize(notifyReq.orderNo),
        ...normalizeObject(notifyReq.equipment),
    };
    const user = notifyReq.user;
    for (let key in user) {
        if (['dayToContact', 'contactType', 'role'].includes(key)) {
            // マスクしない項目について入力値を設定
            inputs[key] = normalize(user[key]);
        } else if (!['mailAddress', 'role'].includes(key)) {
            // 除外しない項目についてマスク値を設定
            inputs[key] = mask(user[key]);
        }
    }
    const reasonOfMove = notifyReq.reasonOfMove;
    for (let key in reasonOfMove) {
        if (['reasonType', 'targetDayToComplete', 'documentDate', 'constructionStart', 'constructionEnd'].includes(key)) {
            // マスクしない項目について入力値を設定
            inputs[key] = normalize(reasonOfMove[key]);
        } else if (key === 'attachmentFiles') {
            // 添付ファイルについて有無を設定
            const attachmentFiles = reasonOfMove[key];
            inputs[key] = (attachmentFiles !== null && attachmentFiles.length > 0) ? '*' : 0;
        } else {
            // マスクする項目についてマスク値を設定
            inputs[key] = mask(reasonOfMove[key]);
        }
    }
    restClient.postAnalyticsData({
        category: analyticsNotifyCategory.APPLY,
        lastPage: getPageName(fromPath),
        destination: getPageName(toPath),
        ...inputs,
        focusItem: normalize(view.focusItem),
        ...normalizeObject(notifyReq.timestamps),
        currentTime: utils.formatDate(new Date())
    });
};

// ページを閉じるときに解析情報を送信する
const addOnCloseAnalyticsSend = (app, route) => {
    window.addEventListener("beforeunload", (event) => {
        const fromPath = route.path;
        if (publicGroupForm.includes(fromPath)) {
            notifyPublicData(app.value, fromPath, null);
        } else if (accountGroupForm.includes(fromPath)) {
            notifyAccountData(app.value, fromPath, null);
        } else if (applyGroupForm.includes(fromPath)) {
            notifyApplyData(app.value, fromPath, null);
        }
    });
}

// ページ遷移時に解析情報を送信する
const onPageMoveAnalyticsSend = (app, to, from) => {
    const toPath = to.path;
    const fromPath = from.path;

    if (publicGroupForm.includes(fromPath) && !publicGroupForm.includes(toPath)) {
        notifyPublicData(app, fromPath, toPath);
    } else if (applyGroupForm.includes(fromPath) && !applyGroupForm.includes(toPath)) {
        notifyApplyData(app, fromPath, toPath);
    } else if (accountGroupForm.includes(fromPath) && !accountGroupForm.includes(toPath)) {
        notifyAccountData(app, fromPath, toPath);
        if (toPath === '/top') {
            store.commit('setReferrer', getPageName(fromPath));
        }
    }
    if (publicEdgeForm.includes(fromPath) && toPath === '/top' && to.query.redirect === 'top') {
        notifyPublicData(app, fromPath, toPath);
        store.commit('setReferrer', getPageName(fromPath));
    }
};

export {
    addOnCloseAnalyticsSend,
    onPageMoveAnalyticsSend,
}

export default {
    addOnCloseAnalyticsSend,
    onPageMoveAnalyticsSend,
}
