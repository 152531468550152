import enums from '@/scripts/consts/enums.js';
import valid from '@/scripts/utils/validation.js';
import {isWest} from '@/scripts/utils/envUtils.js';

// 自動生成のイメージ
const orderNo = {
    "of": 'v-text-field',
    "label": 'WEB受付番号',
    "maxlength": 27,
    "rules": [
        valid.isNotEmpty,
        valid.isLengthEqualsTo(27)
    ]
};
const orderSubNo = {
    "of": 'v-text-field',
    "label": '申請番号枝番',
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum
    ]
};
const equipNo = {
    "of": 'v-text-field',
    "label": '設備通番',
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum
    ]
};
const imageNo = {
    "of": 'v-text-field',
    "label": '設備内の画像通番',
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum
    ]
};
const mid = {
    "of": 'v-text-field',
    "label": '管理ID',
    "maxlength": 10,
    "rules": [valid.isHalfNum]
};
const relocationAddress = {
    "of": 'v-text-field',
    "label": '移転設備住所',
    "maxlength": 64,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const approximateCost = {
    "of": 'v-text-field',
    "label": '概算費用',
    "maxlength": 10,
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum
    ]
};
const buildingCd = {
    "of": 'v-text-field',
    "label": '収容局コード',
    "maxlength": 10,
    "rules": [valid.isNotEmpty]
};
const nttEquipmentNm = {
    "of": 'v-text-field',
    "label": 'NTT設備名',
    "maxlength": 64,
    "rules": [valid.isNotEmpty]
};
const nttEquipmentNo = {
    "of": 'v-text-field',
    "label": 'NTT設備番号',
    "maxlength": 10,
    "rules": [valid.isNotEmpty]
};
const powerEquipmentNm = {
    "of": 'v-text-field',
    "label": '電力設備名',
    "maxlength": 64,
    "rules": [valid.isNotEmpty]
};
const powerEquipmentNo = {
    "of": 'v-text-field',
    "label": '電力設備番号',
    "maxlength": 10,
    "rules": [valid.isNotEmpty]
};
const postalCode = {
    "of": 'v-text-field',
    "label": '郵便番号',
    "maxlength": 7,
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum,
        valid.isLengthEqualsTo(7)
    ]
};
const equipImage = {
    "of": 'v-textarea',
    "label": '設備写真',
    "rules": [
        valid.isNotEmpty,
        valid.isUnderLimitFileSize(4)
    ]
};
const lastName = {
    "of": 'v-text-field',
    "label": '姓',
    "maxlength": 20,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const firstName = {
    "of": 'v-text-field',
    "label": '名',
    "maxlength": 20,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const lastNameKana = {
    "of": 'v-text-field',
    "label": '姓カナ',
    "maxlength": 125,
    "rules": [
        valid.isNotEmpty,
        valid.isWideKana
    ]
};
const firstNameKana = {
    "of": 'v-text-field',
    "label": '名カナ',
    "maxlength": 125,
    "rules": [
        valid.isNotEmpty,
        valid.isWideKana
    ]
};
const companyName = {
    "of": 'v-text-field',
    "label": '会社名',
    "maxlength": 30,
    "rules": [valid.isZenkaku]
};
const governmentAgencyName = {
    "of": 'v-text-field',
    "label": '行政機関名',
    "maxlength": 30,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const companyNameKana = {
    "of": 'v-text-field',
    "label": '会社名カナ',
    "maxlength": 125,
    "rules": [valid.isWideKana]
};
const govermentAgencyNameKana = {
    "of": 'v-text-field',
    "label": '行政機関名カナ',
    "maxlength": 125,
    "rules": [
        valid.isNotEmpty,
        valid.isWideKana
    ]
};
const userAddress1 = {
    "of": 'v-text-field',
    "label": '申請者住所1',
    "maxlength": 45,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const userAddress2 = {
    "of": 'v-text-field',
    "label": '申請者住所2',
    "maxlength": 45,
    "rules": [
        valid.isZenkaku
    ]
};
const phoneNumber1 = {
    "of": 'v-text-field',
    "label": '電話番号１',
    "maxlength": 15,
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum
    ]
};
const phoneNumber2 = {
    "of": 'v-text-field',
    "label": '電話番号２',
    "maxlength": 15,
    "rules": [valid.isHalfNum]
};
const mailAddress = {
    "of": 'v-text-field',
    "label": 'メールアドレス',
    "maxlength": 60,
    "rules": [
        valid.isNotEmpty,
        valid.isMailAddress
    ]
}
const dayToContact = {
    "of": 'date-picker',
    "label": '連絡希望日',
    "maxlength": 10,
    "rules": [
        valid.isNotEmpty,
        valid.isLengthEqualsTo(10)
    ]
};
const ampm = {
    "of": 'v-select',
    "label": 'AM/PM',
    "item-title": "text",
    "items": enums.ampm.items
};
const contactType = {
    "of": 'v-select',
    "label": 'メール/電話',
    "item-title": "text",
    "items": enums.contactType.items,
    "rules": [valid.isNotEmpty]
};
const reasonType = {
    "of": 'v-select',
    "label": '申請理由',
    "item-title": "text",
    "items": [],
    "rules": [valid.isNotEmpty]
};
const targetDayToComplete = {
    "of": 'date-picker',
    "label": '移転完了希望時期',
    "maxlength": 10,
    "rules": [
        valid.isNotEmpty,
        valid.isLengthEqualsTo(10)
    ]
};
const status = {
    "of": 'v-textarea',
    "label": 'ステータス',
    "maxlength": 1024,
    "rows": '3'
};
const reasonDescription = {
    "of": 'v-textarea',
    "label": '申請理由詳細',
    "maxlength": 1024
};
const reasonDescriptionRequired = {
    "of": 'v-textarea',
    "label": '申請理由詳細',
    "maxlength": 1024,
    "rules": [valid.isNotEmpty]
};
const imageCount = {
    "of": 'v-text-field',
    "label": '設備画像数',
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum
    ]
};
const userId = {
    "of": 'v-text-field',
    "label": 'ユーザID',
    "maxlength": 60,
    "rules": [
        valid.isNotEmpty,
        valid.isMailAddress
    ]
};
const password = {
    "of": 'v-text-field',
    "label": 'パスワード',
    "maxlength": 32,
    "rules": isWest() ? [
        valid.isNotEmpty,
        valid.isValidPasswordWest,
        valid.isLargerLength(8)
    ] : [
        valid.isNotEmpty,
        valid.isValidPassword,
        valid.isLargerLength(6)
    ]
};
const updatePassword = {
    "of": 'v-text-field',
    "label": 'パスワード',
    "maxlength": 32,
    "rules": isWest() ? [
        valid.isValidPasswordWest,
        valid.isLargerLength(8)
    ] : [
        valid.isValidPassword,
        valid.isLargerLength(6)
    ]
};
const resultCode = {
    "of": 'v-select',
    "item-title": "text",
    "label": '処理結果コード',
    "items": enums.resultCode.items
};
const documentNumber = {
    "of": 'v-text-field',
    "label": '依頼文書番号',
    "maxlength": 30,
    "rules": [
        valid.isNotEmpty
    ]
};
const documentDate = {
    "of": 'date-picker',
    "label": '依頼文書の日付',
    "maxlength": 10,
    "rules": [
        valid.isNotEmpty,
        valid.isLengthEqualsTo(10)
    ]
};
const projectName = {
    "of": 'v-text-field',
    "label": '工事名/事業名',
    "maxlength": 64,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const constructionStart = {
    "of": 'date-picker',
    "label": '工事実施時期（開始）',
    "maxlength": 10,
    "rules": [valid.isLengthEqualsTo(10)]
};
const constructionEnd = {
    "of": 'date-picker',
    "label": '工事実施時期（終了）',
    "maxlength": 10,
    "rules": [valid.isLengthEqualsTo(10)]
};
const dutyStationName = {
    "of": 'v-text-field',
    "label": '工事担当部署名',
    "maxlength": 30,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const personInCharge = {
    "of": 'v-text-field',
    "label": '工事担当者名',
    "maxlength": 20,
    "rules": [
        valid.isNotEmpty,
        valid.isZenkaku
    ]
};
const phoneNumberInCharge = {
    "of": 'v-text-field',
    "label": '工事担当者の電話番号',
    "maxlength": 15,
    "rules": [
        valid.isNotEmpty,
        valid.isHalfNum
    ]
};
const selectedFacility = {
    "of": 'v-text-field',
    "label": '移転希望設備名',
    "maxlength": 125,
    "rules": [valid.isNotEmpty]
};
const attachmentFiles = {"of": 'file-drop'};
const resetToken = {
    "of": 'v-text-field',
    "label": 'リセットトークン',
};
const loginAuthMethod = {
    "of": 'v-select',
    "label": 'ログイン認証方式',
    "item-title": "text",
    "items": enums.loginAuthMethod.items,
    "rules": [valid.isNotEmpty]
};
const orderCancelReason = {
    "of": 'v-select',
    "label": '取り消し理由選択',
    "item-title": "text",
    "items": [],
    "rules": [valid.isNotEmpty]
};

const role = {};

const getAddressByPostalCode = {
    postalCode,
};

const addOrderImageRequest = {
    orderNo,
    orderSubNo,
    equipImage,
};

const notifyOrderDetailRequest = {
    orderNo,
    mid,
    relocationAddress,
    approximateCost,
    buildingCd,
    nttEquipmentNm,
    nttEquipmentNo,
    powerEquipmentNm,
    powerEquipmentNo,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    companyName,
    companyNameKana,
    postalCode,
    userAddress1,
    userAddress2,
    phoneNumber1,
    phoneNumber2,
    mailAddress,
    dayToContact,
    ampm,
    contactType,
    reasonType,
    status,
    reasonDescription,
    imageCount,
    targetDayToComplete,
    documentDate,
    projectName,
    constructionStart,
    constructionEnd,
    dutyStationName,
    personInCharge,
    phoneNumberInCharge,
    documentNumber,
    selectedFacility,
    attachmentFiles
};


const notifyOrderDetailRequestAdmin = {
    orderNo,
    mid,
    relocationAddress,
    approximateCost,
    buildingCd,
    nttEquipmentNm,
    nttEquipmentNo,
    powerEquipmentNm,
    powerEquipmentNo,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    "companyName": governmentAgencyName,
    "companyNameKana": govermentAgencyNameKana,
    postalCode,
    userAddress1,
    userAddress2,
    phoneNumber1,
    phoneNumber2,
    mailAddress,
    dayToContact,
    ampm,
    contactType,
    reasonType,
    status,
    "reasonDescription": reasonDescriptionRequired,
    imageCount,
    targetDayToComplete,
    documentDate,
    projectName,
    constructionStart,
    constructionEnd,
    dutyStationName,
    personInCharge,
    phoneNumberInCharge,
    documentNumber,
    selectedFacility,
    attachmentFiles
};

const addUserRequest = {
    userId,
    password,
    role,
    loginAuthMethod,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    companyName,
    companyNameKana,
    postalCode,
    userAddress1,
    userAddress2,
    phoneNumber1,
    phoneNumber2,
    mailAddress,
};

const addUserRequestAdmin = {
    userId,
    password,
    role,
    loginAuthMethod,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    "companyName": governmentAgencyName,
    "companyNameKana": govermentAgencyNameKana,
    postalCode,
    userAddress1,
    userAddress2,
    phoneNumber1,
    phoneNumber2,
    mailAddress,
};

const updateUserRequest = {
    loginAuthMethod,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    companyName,
    companyNameKana,
    mailAddress,
    postalCode,
    userAddress1,
    userAddress2,
    phoneNumber1,
    phoneNumber2,
    "password": updatePassword,
};

const updateUserRequestAdmin = {
    loginAuthMethod,
    lastName,
    firstName,
    lastNameKana,
    firstNameKana,
    "companyName": governmentAgencyName,
    "companyNameKana": govermentAgencyNameKana,
    mailAddress,
    postalCode,
    userAddress1,
    userAddress2,
    phoneNumber1,
    phoneNumber2,
    "password": updatePassword,
};

const loginRequest = {
    userId,
    password,
};

const ordersGetRequest = {
    userId,
};

const ordersDetailsGetRequest = {
    userId,
    orderNo,
};

const sendMailRequest = {
    mailAddress
};

const resetPasswordRequest = {
    password,
    resetToken,
};

const orderCancelRequest = {
    orderNo,
    orderCancelReason,
}

const createSkeltonReqDataAccountAddresConfirm = () => ({
    "mailAddress": null,
})

const createSkeltonReqDataSendMailRequest = () => ({
        "mailAddress": null,
    })

const createSkeltonReqDataResetPasswordRequest = () => ({
        "password": null,
        "resetToken": null,
    })

const createSkeltonReqDataGetAddressByPostalCode = () => ({
        "postalCode": null,
    })

const createSkeltonReqDataAddOrderImageRequest = () => ({
        "orderNo": null,
        "orderSubNo": null,
        "equipNo": null,
        "imageNo": null,
        "image": null,
    })

const createSkeltonReqDataNotifyOrderDetailRequest = () => ({
        "orderNo": null,
        "equipment": {
            "mid": "",
            "relocationAddress": null,
            "approximateCost": null,
            "selectedFacility": null,
            "buildingCd": "",
            "nttEquipmentNm": "",
            "nttEquipmentNo": "",
            "powerEquipmentNm": "",
            "powerEquipmentNo": "",
        },
        "user": {
            "lastName": null,
            "firstName": null,
            "lastNameKana": null,
            "firstNameKana": null,
            "companyName": null,
            "companyNameKana": null,
            "postalCode": null,
            "userAddress1": null,
            "userAddress2": null,
            "phoneNumber1": null,
            "phoneNumber2": null,
            "mailAddress": null,
            "dayToContact": null,
            "ampm": null,
            "contactType": null,
            "role": null,
        },
        "reasonOfMove": {
            "reasonType": null,
            "reasonDescription": null,
            "targetDayToComplete": null,
            "documentDate": null,
            "projectName": null,
            "constructionStart": null,
            "constructionEnd": null,
            "dutyStationName": null,
            "personInCharge": null,
            "phoneNumberInCharge": null,
            "documentNumber": null,
            "attachmentFiles": null,
        },
        "timestamps": {
            "orderStartTime": null,
            "mapDisplayTime": null,
            "mapSelectTime": null,
            "orderInputTime": null,
        }
    })

const createSkeltonReqDataFileUploadRequest = () => ({
        "orderNo": null,
        "fileInfo": null,
        "attachmentFiles": null
    })

const createSkeltonReqDataAddUserRequest = () => ({
        "userId": null,
        "password": null,
        "password2": null,
        "role": null,
        "loginAuthMethod": null,
        "lastName": null,
        "firstName": null,
        "lastNameKana": null,
        "firstNameKana": null,
        "companyName": null,
        "companyNameKana": null,
        "postalCode": null,
        "userAddress1": null,
        "userAddress2": null,
        "phoneNumber1": null,
        "phoneNumber2": null,
        "mailAddress": null,
    })

const createSkeltonReqDataUpdateUserRequest = () => ({
        "loginAuthMethod": null,
        "lastName": null,
        "firstName": null,
        "lastNameKana": null,
        "firstNameKana": null,
        "mailAddress": null,
        "companyName": null,
        "companyNameKana": null,
        "postalCode": null,
        "userAddress1": null,
        "userAddress2": null,
        "phoneNumber1": null,
        "phoneNumber2": null,
        "password": null,
        "password2": null,
    })

const createSkeltonReqDataLoginRequest = () => ({
        "mailAddress": null,
        "password": null,
    })

const createSkeltonReqDataLoginOtpRequest = () => ({
        "mailAddress": null,
        "otp": null,
    })

const createSkeltonReqDataOrdersGetRequest = () => ({
        "userId": null,
    })

const createSkeltonReqDataOrdersDetailsGetRequest = () => ({
        "orderNo": null,
    })
    
const createSkeltonReqDataDownloadGetRequest = () => ({
    "fileId": null,
})

const createSkeltonReqDataOrderCancelRequest = () => ({
    "orderNo": null,
    "orderCancelReason": null,
});

// コード定義マスタを使用する変換リストを初期化
const initialize = store => {
    reasonType.items = store.state.codes?.C095?.map(v => {
        return {text: v.viewName, value: parseInt(v.cd, 10)};
    }) ?? [];
    orderCancelReason.items = store.state.codes?.C096?.map(v => {
        return {text: v.viewName, value: parseInt(v.cd, 10)};
    }) ?? [];
};

export {
    getAddressByPostalCode,
    addOrderImageRequest,
    notifyOrderDetailRequest,
    notifyOrderDetailRequestAdmin,
    addUserRequest,
    addUserRequestAdmin,
    updateUserRequest,
    updateUserRequestAdmin,
    loginRequest,
    ordersGetRequest,
    ordersDetailsGetRequest,
    sendMailRequest,
    resetPasswordRequest,
    orderCancelRequest,
    createSkeltonReqDataGetAddressByPostalCode,
    createSkeltonReqDataAddOrderImageRequest,
    createSkeltonReqDataNotifyOrderDetailRequest,
    createSkeltonReqDataFileUploadRequest,
    createSkeltonReqDataAddUserRequest,
    createSkeltonReqDataAccountAddresConfirm,
    createSkeltonReqDataUpdateUserRequest,
    createSkeltonReqDataLoginRequest,
    createSkeltonReqDataLoginOtpRequest,
    createSkeltonReqDataOrdersGetRequest,
    createSkeltonReqDataOrdersDetailsGetRequest,
    createSkeltonReqDataDownloadGetRequest,
    createSkeltonReqDataSendMailRequest,
    createSkeltonReqDataResetPasswordRequest,
    createSkeltonReqDataOrderCancelRequest,
    initialize,
};

export default {
    getAddressByPostalCode,
    addOrderImageRequest,
    notifyOrderDetailRequest,
    notifyOrderDetailRequestAdmin,
    addUserRequest,
    addUserRequestAdmin,
    updateUserRequest,
    updateUserRequestAdmin,
    loginRequest,
    ordersGetRequest,
    ordersDetailsGetRequest,
    sendMailRequest,
    resetPasswordRequest,
    orderCancelRequest,
    createSkeltonReqDataAccountAddresConfirm,
    createSkeltonReqDataGetAddressByPostalCode,
    createSkeltonReqDataAddOrderImageRequest,
    createSkeltonReqDataNotifyOrderDetailRequest,
    createSkeltonReqDataFileUploadRequest,
    createSkeltonReqDataAddUserRequest,
    createSkeltonReqDataUpdateUserRequest,
    createSkeltonReqDataLoginRequest,
    createSkeltonReqDataLoginOtpRequest,
    createSkeltonReqDataOrdersGetRequest,
    createSkeltonReqDataOrdersDetailsGetRequest,
    createSkeltonReqDataDownloadGetRequest,
    createSkeltonReqDataSendMailRequest,
    createSkeltonReqDataResetPasswordRequest,
    createSkeltonReqDataOrderCancelRequest,
    initialize,
}
