<template>
  <v-input ref="vInput" :dark="dark" :error="hasError" :error-messages="errorMessages">
    <div>
      <v-card
          ref="dragArea"
          class="d-flex justify-center align-center text-center pa-1 pa-md-5"
          elevation="0"
          v-if="!disabled && !readonly"
          :style="isDragging ? {border: 'solid 3px blue !important'} : {border: 'dashed 1px black !important'}"
          :color="color"
          :width="width"
          :max-width:="maxWidth"
          :height="height"
          v-text="label"
          @click="addFile"
          @dragenter.prevent="onDragenter"
          @drop.prevent="onDrop"
          @dragleave="onDragleave"
          @dragover.prevent="()=>{}"
          @blur="onChange"
          v-html="htmlLabel">
      </v-card>
      <v-card tile elevation="0" class="d-flex flex-wrap" :width="width" style="background-color: inherit;">
        <template v-for="(file, key) in files">
          <template v-if="viewer && canView(file)">
            <v-chip
                :class="`mt-1 ml-1 bg-${chipColor}`"
                :closable="!disabled && !readonly"
                :prepend-icon="icon"
                v-bind:key="createKey(file, key)"
                color="white"
                @click:close="deleteFile(file)"
                @click="showViewer(file)">
              <span>{{ file.name }}</span>
            </v-chip>
          </template>
          <template v-else>
            <v-chip
                :class="`mt-1 ml-1 bg-${chipColor}`"
                :closable="!disabled && !readonly"
                :prepend-icon="icon"
                v-bind:key="createKey(file, key)"
                color="white"
                @click:close="deleteFile(file)">
              <span>{{ file.name }}</span>
            </v-chip>
          </template>
        </template>
      </v-card>
    </div>
    <v-dialog ref="imageDialog" v-model="show" :max-width="imageWidth">
      <v-card color="white">
        <v-img contain :src="imageSrc" :aspect-ratio="imageAspectRatio" :max-height="imageHeight">
          <div class="d-flex justify-end">
            <div style="cursor: pointer;" @click="show = false">
              <v-icon size="large" class="ma-1" icon="mdi-close-circle"></v-icon>
            </div>
          </div>
        </v-img>
      </v-card>
    </v-dialog>
  </v-input>
</template>

<script>
import dialog from '@/scripts/utils/dialog.js';
import htmlUtils from '@/scripts/utils/htmlUtils.js';

export default {
  // https://github.com/vuetifyjs/vuetify/issues/3464
  "inject": {
    "form": {"default": null}
  },
  created() {
    this.form && this.form.register(this)
  },
  beforeUnmount() {
    this.form && this.form.unregister(this)
  },
  data() {
    return {
      "name": 'FileDrop',
      "files": [],
      "errorMessages": null,
      "isDragging": false,
      "show": false,
      "imageSrc": null,
      "imageAspectRatio": 1.7778,
      "imageWidth": null,
      "imageHeight": null,
    }
  },
  "props": {
    "modelValue": {
      "type": Array,
      "required": false,
      "default": () => []
    },
    "label": {
      "type": String,
      "required": false,
      "default": () => '詳細内容確認のため、平面図、縦断図、写真等の資料があれば添付をお願いいたします。'
    },
    "readonly": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "dark": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "disabled": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
    "color": {
      "type": String,
      "required": false,
      "default": () => 'grey-lighten-3'
    },
    "width": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    "maxWidth": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    "height": {
      "type": [String, Number],
      "required": false,
      "default": () => null
    },
    "icon": {
      "type": String,
      "required": false,
      "default": () => 'mdi-file-outline'
    },
    "chipColor": {
      "type": String,
      "required": false,
      "default": () => 'blue-darken-3'
    },
    "extensions": {
      "type": Array,
      "required": false,
      "default": () => ['.*']
    },
    "rules": {
      "type": Array,
      "required": false,
      "default": () => []
    },
    "viewer": {
      "type": Boolean,
      "required": false,
      "default": () => false
    },
  },
  emits: ['update:modelValue'],
  "computed": {
    htmlLabel() {
      return this.label.split('\n').join('<br/>');
    },
    hasError() {

      if (this.files.length === 0) {
        return true;
      }
      if (this.errorMessages == null) {
        return false;
      }
      return !(Array.isArray(this.errorMessages) && this.errorMessages.length === 0);

    }
  },
  "methods": {
    onDragenter(error) {
      error.dataTransfer.dropEffect = 'copy';
      this.isDragging = true
    },
    onDrop(error) {
      this.isDragging = false
      let dropFiles = Array.from(error.dataTransfer.files);
      const needExtCheck = !this.extensions.includes('.*');
      if (needExtCheck) {
        const errorFile = dropFiles.find(file => !this.extensions.some(ext => file.name.toLowerCase().endsWith(ext.toLowerCase())));
        if (errorFile) {
          this.errorMessages = `${this.extensions.join(', ')}以外の拡張子のファイルは登録できません`;
          return;
        }
      }
      this.files = this.files.concat(dropFiles);
      this.onChange();
    },
    onDragleave() {
      this.isDragging = false;
    },
    addFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = this.extensions.join(',');
      input.multiple = true;
      input.onchange = elem => {
        Array.from(elem.target.files).forEach(file => {
          this.files.push(file);
        });
        this.onChange();
      };
      input.click();
    },
    deleteFile(file) {
      // eslint-disable-next-line id-length
      this.files = this.files.filter(f => f !== file);
      this.onChange();
    },
    onChange() {
      const that = this;
      this.errorMessages = this.rules
          .map(rule => rule(that.files))
          .filter(result => result !== true);
      this.$emit('update:modelValue', this.files);
    },
    // v-forのキーを生成する(indexだけだと誤動作するため)
    createKey(file, index) {
      // "index-ファイル名"
      return `${index}-${file.name}`
    },
    canView(file) {
      if (!file.type) {
        return false;
      }
      return [
        'image/png',
        'image/gif',
        'image/jpeg'
      ].includes(file.type.toLowerCase());
    },
    async showViewer(file) {
      this.imageSrc = window.URL.createObjectURL(file);
      const {width, height} = await htmlUtils.getImageRect(this.imageSrc);
      this.imageAspectRatio = width / height;
      const windowRatio = window.innerWidth / window.innerHeight;
      if (this.imageAspectRatio < windowRatio) {
        this.imageHeight = parseInt(window.innerHeight * 0.8, 10);
        this.imageWidth = parseInt(this.imageHeight * this.imageAspectRatio, 10);
      } else {
        this.imageWidth = parseInt(window.innerWidth * 0.8, 10);
        this.imageHeight = parseInt(this.imageWidth / this.imageAspectRatio, 10);
      }
      this.show = true;
    }
  },
  "watch": {
    modelValue(newVal) {
      this.files = newVal;
    },
  },
  mounted() {
    this.files = this.modelValue || [];
    if (!this.readonly && !this.disabled && 0 < this.files.length) {
      this.onChange();
    }
  }
}
</script>
