import PopupImage from './PopupImage';
import SimpleSvg from './SimpleSvg';

export {
  PopupImage,
  SimpleSvg,
}

export default {
  PopupImage,
  SimpleSvg,
}
