const msg = {
    "ORDER_CONFIRM": {
        "title": '申請確認',
        "msg": '支障移転の申請を行います。<br/>よろしいですか？'
    },
    "DELETE_CONFIRM": {
        "title": '削除確認',
        "msg": '支障ＮＴＴ設備{}を削除します。<br/>よろしいですか？'
    },
    "DATA_NOT_FOUND": {
        "title": 'エラー',
        "msg": 'サーバとの通信に失敗しました。<br/>トップページに戻ります。'
    },
    "FAIL_ADD_IMAGE": {
        "title": 'エラー',
        "msg": '画像登録処理に失敗しました。<br/>最初から登録処理を実施しなおしてください。'
    },
    "INVALID_TOKEN": {
        "title": 'エラー',
        "msg": 'セッションがタイムアウトしました。<br/>再度ログインしてください。'
    },
    "FAIL_LOGIN": {
        "title": 'ログイン失敗',
        "msg": '入力されたユーザID、パスワードでログインできませんでした。'
    },
    "ACCOUNT_LOCK": {
        "title": 'ログイン失敗',
        "msg": '入力されたユーザIDはアカウントロック中です。<br/>一定時間経過後ログインをお試し下さい。'
    },
    "INVALID_OTP": {
        "title": 'ログイン失敗',
        "msg": 'ワンタイムパスワードに誤りがあります。'
    },
    "OTP_EXPIRE": {
        "title": 'ログイン失敗',
        "msg": 'ワンタイムパスワードの有効期限が切れています。<br/>ログイン画面に戻り、再度ログインしてください。'
    },
    "TOKEN_NOT_FOUND": {
        "title": 'エラー',
        "msg": '認証情報が存在しないため画面表示に失敗しました。<br/>ログイン画面に遷移します。'
    },
    "INVALID_REQUEST": {
        "title": 'エラー',
        "msg": '不正なリクエストを検出したため処理を中断しました。<br/>最初から操作を実施しなおしてください。'
    },
    "INVALID_FILE": {
        "title": 'エラー',
        "msg": '選択したファイルが不正です。<br/>申請情報を一覧から検索しなおして再度実行してください。'
    },
    "FAIL_CONFLICT": {
        "title": '排他エラー',
        "msg": '他のユーザーにより、情報が変更されています。<br/>申請情報を一覧から検索しなおして再度実行してください。'
    },
    "FILE_NOT_FOUND": {
        "title": 'エラー',
        "msg": '選択したファイルは他の利用者によって既に削除されています。<br/>申請情報を一覧から検索しなおして再度実行してください。'
    },
    "SYSTEM": {
        "title": 'エラー',
        "msg": 'システムエラーが発生しました。<br/>しばらく待ってから再実行してください。'
    },
    "SERVER_COMMUNICATION": {
        "title": 'エラー',
        "msg": 'サーバとの通信に失敗したため処理が実行できませんでした。'
    },
    "WAIT_RECOVER": {
        "title": 'エラー',
        "msg": 'サーバとの通信に失敗しました。<br/>しばらく待ってから再実行してください。'
    },
    "CONFLICT_USER_ID": {
        "title": 'エラー',
        "msg": '指定のユーザIDは既に使用されています。<br/>異なるユーザIDを入力して再度実行してください。'
    },
    "CONFIRM_ADD_ACCOUNT": {
        "title": '作成確認',
        "msg": 'アカウントを作成します。<br/>よろしいですか？'
    },
    "SUCCESS_ADD_ACCOUNT": {
        "title": '登録完了',
        "msg": 'アカウントの登録が完了しました。'
    },
    "CONFIRM_UPDATE_ACCOUNT": {
        "title": 'アカウント情報変更確認',
        "msg": 'アカウント情報を変更します。<br/>よろしいですか？'
    },
    "CONFIRM_UPDATE_ACCOUNT_CHANGE_MAILADDRESS": {
        "title": 'アカウント情報変更確認',
        "msg": 'アカウント情報を変更します。<br/><br/>メールアドレスを変更した場合、<br/>次回ログイン時は変更後のメールアドレスを入力してください。<br/><br/>よろしいですか？'
    },
    "SUCCESS_UPDATE_ACCOUNT": {
        "title": 'アカウント情報変更完了',
        "msg": 'アカウント情報の変更が完了しました。'
    },

    "RANGE_SIZE_COMPLEX": {
        "title": 'エラー',
        "msg": '工事範囲の指定が複雑なため申請情報に設定できません。<br/>工事範囲で指定する頂点の数を減らしてください。'
    },
    "INVALID_POSTALCODE": {
        "title": '検索失敗',
        "msg": '検索条件の指定が不正です。<br/>郵便番号7桁の数値または住所情報を入力してください。'
    },
    "ADDRESS_NOT_FOUND": {
        "title": '検索失敗',
        "msg": '郵便番号{}に対応する住所が見つかりませんでした。'
    },
    "LOCATION_NOT_FOUND": {
        "title": '検索失敗',
        "msg": '次の住所に対応する地図の位置が見つかりませんでした。<br/>{}'
    },
    "LOCATION_COMPLEX": {
        "title": '検索失敗',
        "msg": '検索条件の指定が複雑なため処理が失敗しました。<br/>検索条件の文字数を減らして再度検索を実施してください。'
    },
    "SUCCESS_REG_ORDER": {
        "title": '登録完了',
        "msg": '申請情報の{}が完了しました'
    },
    "SUCCESS_MOD_ORDER": {
        "title": '変更完了',
        "msg": '申請情報の{}が完了しました'
    },
    "SUCCESS_DEL_ORDER": {
        "title": '削除完了',
        "msg": '申請情報の{}が完了しました'
    },
    "SUCCESS_REG_CUSTOMER": {
        "title": '登録完了',
        "msg": 'お客様情報の{}が完了しました'
    },
    "SUCCESS_MOD_CUSTOMER": {
        "title": '変更完了',
        "msg": 'お客様情報の{}が完了しました'
    },
    "SUCCESS_MOD_PASSWORD": {
        "title": '変更完了',
        "msg": 'パスワードの{}が完了しました'
    },
    "LOGOUT": {
        "title": 'ログアウト',
        "msg": 'ログアウトします。<br/>よろしいですか？'
    },
    "CONFIRM_DEL_ORDER": {
        "title": '削除確認',
        "msg": '申請情報を削除します。<br/>よろしいですか？'
    },
    "CONFIRM_SEND_MAILADDRESS": {
        "title": "メール送信確認",
        "msg": "メールを送信します。<br/>よろしいですか？"
    },
    "CONFIRM_RESET_PASSWORD": {
        "title": "パスワード変更確認",
        "msg": "パスワードを変更します。<br/>よろしいですか？"
    },
    "CANNOT_VALIDATE_ACCOUNT_TOKEN": {
        "title": "メールアドレス認証エラー",
        "msg": "有効期限が切れています。<br/>「戻る」ボタンを押して再登録してください。"
    },
    "CONFLICT_MAILADDRESS": {
        "title": 'エラー',
        "msg": '指定のメールアドレスは既に使用されています。'
    },
    "INVALID_DAYTOCONTRACT": {
        "title": 'エラー',
        "msg": '連絡希望日は翌々営業日を指定してください。'
    },
    "INVALID_CONSTRUCTION": {
        "title": 'エラー',
        "msg": '工事実施時期（終了）には工事実施時期（開始）以降の日付を指定してください。'
    },
    "ATTACHMENTFILE_EXTENSION": {
        "title": 'エラー',
        "msg": '許容されていない拡張子のファイルが添付されました。<br/>pdf,docx,xlsx,pptx,jpg,jpeg,pngの拡張子のファイルが添付可能です。'
    },
    "ATTACHMENTFILE_MAX_SIZE_BYTE": {
        "title": 'エラー',
        "msg": 'ファイルの登録上限サイズを超過しています。<br/>ファイルの合計サイズは{}MB以下にしてください。'
    },
    "ATTACHMENTFILES_MAX_COUNT": {
        "title": 'エラー',
        "msg": 'ファイルの登録数が上限に達しています。<br/>登録可能なファイル数は{}件です。'
    },
    "CREATE_ACCOUNT_CONFLICT_MAILADDRESS": {
        "title": 'エラー',
        "msg": '入力されたメールアドレスは既に登録されています。<br>一度トップ画面に戻り、「ログイン」ボタンからログインをお試しください。<br>パスワードをお忘れの場合は、「ログイン」ボタン＞「パスワードを忘れた場合」からパスワードの変更が可能です。'
    },
    "ALREADY_CANCELLED": {
        "title": 'エラー',
        "msg": '申請は既に取り消されています。'
    },
    "CONFIRM_REPORT": {
        "title": '見積書了承確認',
        "msg": '見積書を了承します。<br>よろしいですか？'
    },
}

export default {
    ...msg,
}
