/**
 * ダイアログ表示処理はコンポーネント側からメソッドを追加する
 * 追加すると以下の関数が利用可能になる
 *   alert('title', 'message')                                    : タイトル、メッセージを指定してアラートダイアログを表示しpromiseを返す。
 *   alert({title: 'title', message: 'message'})                  : タイトル、メッセージのオブジェクトを指定してアラートダイアログを表示しpromiseを返す。
 *   alert({title: 'title', message: 'msg{}-{}'}, ['aa', 'bb'])   : メッセージの置換文字{}とパラメータを指定してアラートダイアログを表示しpromiseを返す。
 *   confirm('title', 'message')                                  : タイトル、メッセージを指定して確認ダイアログを表示しpromiseを返す。
 *   confirm({title: 'title', message: 'message'})                : タイトル、メッセージのオブジェクトを指定して確認ダイアログを表示しpromiseを返す。
 *   confirm({title: 'title', message: 'msg{}-{}'}, ['aa', 'bb']) : メッセージの置換文字{}とパラメータを指定して確認ダイアログを表示しpromiseを返す。
 *   loading(true);                                               : ローディングダイアログの表示/非表示を設定。
 */
const dialogs = {};

export {
  dialogs,
}

export default {
  dialogs,
}
