import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as labs from 'vuetify/labs/components';
import * as directives from 'vuetify/directives';

import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import ja from 'vuetify/lib/locale/ja'
import colors from 'vuetify/lib/util/colors';

export default createVuetify({
  components: { ...components, ...labs },
  directives,
  icons: {
    aliases,
    sets: {
      fa,
      mdi,
    },
  },
  locale: {
    locale: 'ja',
    messages: {ja},
  },
  display : {
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  defaults: {
    VForm: {
      validateOn: 'input blur',
    },
    VTextField: {
      color: colors.blue.darken2, // フォーカス時の枠と文字
    },
    VSelect: {
      color: colors.blue.darken2, // フォーカス時の枠と文字
    },
    VStepperItem: {
      color: colors.blue.darken2, // フォーカス時の枠と文字
    },
    VTextarea: {
      color: colors.blue.darken2, // フォーカス時の枠と文字
    },
    VCheckboxBtn: {
      color: colors.blue.darken2, // 選択したチェックマーク
    },
    VRadioGroup: {
      color: colors.blue.darken2, // 選択した〇マーク
    },
    VBtn: {
      //height: 50
      //'bg-color': colors.grey.lighten4, // #f5f5f5
    },
    VCard: {
      elevation: '2',
    },
    VStepper: {
      elevation: '2',
    },
    VDatePicker: {
      header: '', // 未入力時の表示文言
      inputPlaceholder: 'yyyy/mm/dd',
      color: colors.blue.darken2, // ヘッダの色
      elevation: 3, // 浮き上がる高さ
    },
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          background: colors.shades.white, // #ffffff
          //surface: colors.blue.lighten5, // #e3f2fd
          primary: colors.blue.darken2, // #1976d2 ボタン表面など
          //secondary: colors.blue.darken2,
          error: colors.red.accent2, // #ff5252
          //info: colors.blue.darken2,
          //success: colors.blue.darken2,
          //warning: colors.blue.darken2,
        },
      },
    },
  },
});
